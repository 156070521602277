// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.camera-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.camera-buttons {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin-bottom: 5px;
}

p { 
  font-size: 1.2rem;
  font-weight: 600;;
  margin: 0;
  color: #393939;
}

.camera-feed {
  border: 3px solid #d3d3d3;
  border-radius: 5px;
  height: 204px;
  width: 204px;
  padding: 0;
  margin: 0;
;}`, "",{"version":3,"sources":["webpack://./src/Components/Styles/camera.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,SAAS;EACT,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,UAAU;EACV,SAAS;CACV","sourcesContent":[".camera-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 10px;\n}\n\n.camera-buttons {\n  height: 60px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 40px;\n  margin-bottom: 5px;\n}\n\np { \n  font-size: 1.2rem;\n  font-weight: 600;;\n  margin: 0;\n  color: #393939;\n}\n\n.camera-feed {\n  border: 3px solid #d3d3d3;\n  border-radius: 5px;\n  height: 204px;\n  width: 204px;\n  padding: 0;\n  margin: 0;\n;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
