import React from 'react';


const updateScript = () => {
    async function updateScript() {
        const endpoint = 'https://api.trainingprofessionals.com.au/student/updateScript';

        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
            });

            if (response.ok) {
                const data = await response.json();
                // console.log('Update successful:', data);

                // Display an alert with the number of entries updated
                alert(`Update run successfully: ${data.message}`);
            } else {
                console.error('Failed to update entries:', response.statusText);
                alert('Failed to update entries. Please try again.');
            }

        } catch (err) {
            console.error('Error updating table:', err);
            alert('An error occurred while updating the entries. Please check the console for more details.');
        }
    }

//     Functions here

    return (
        <div>
            <button onClick={updateScript}>
                Update Entries
            </button>
        </div>
    );
};

export default updateScript;

// =============================== To Do =================================== //


// =============================== Bugs =================================== //