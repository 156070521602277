import React, { ReactNode } from "react";

import "./Styles/mobileModal.scss";

interface MobileModalProps {
  isOpen: boolean;
  children?: ReactNode | string;
  heading?: ReactNode | string;
  onClose?: () => void;
  withButton?: boolean;
  className?: string;
}

const MobileModal: React.FC<MobileModalProps> = ({
  isOpen = false,
  children,
  heading,
  onClose,
  withButton = true,
  className,
}) => {
  return (
    <div
      className="mobile-modal-overlay"
      style={{
        opacity: isOpen ? 1 : 0,
        visibility: isOpen ? "visible" : "hidden",
      }}
    >
      <div className={`mobile-modal-content ${className}`}>
        <div className="mobile-modal-header">
          {heading && <h2>{heading}</h2>}
          {withButton && (
            <button
              className="mobile-modal-close"
              type="button"
              onClick={onClose}
            >
              &times;
            </button>
          )}
        </div>
        {children}
      </div>
    </div>
  );
};

export default MobileModal;
