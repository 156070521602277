import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUserActive, setUserInactive } from '../State/Reducers/activitySlice';
import { ACTIVITY_TIMEOUT as DEFAULT_TIMEOUT } from 'Constants/constants';


interface UseActivityLoggerOptions {
  timeout?: number;
  videoRef?: React.RefObject<HTMLVideoElement>;
  audioRef?: React.RefObject<HTMLAudioElement>;
}

export const useActivityLogger = ({
  timeout = DEFAULT_TIMEOUT,
  videoRef,
  audioRef,
}: UseActivityLoggerOptions = {}) => {
  const [isUserActive, setIsUserActive] = useState(true);
  const dispatch = useDispatch();
  const activityTimeout = useRef<NodeJS.Timeout | null>(null);

  const resetTimeout = () => {
    if (activityTimeout.current) {
      clearTimeout(activityTimeout.current);
    }

    // User is asleep on the job
    activityTimeout.current = setTimeout(() => {
      setIsUserActive(false);
      dispatch(setUserInactive());
    }, timeout);

    // User is active, happy days
    setIsUserActive(true);
    dispatch(setUserActive());
  };

  useEffect(() => {
    const activityEvents = [
      'mousemove',
      'keydown',
      'click',
      'scroll',
      'touchstart',
      'touchmove',
      'mousedown',
      'mouseup',
      'resize',
      'focus',
      'wheel',
    ];

    activityEvents.forEach((event) =>
      window.addEventListener(event, resetTimeout)
    );

    const currentVideoElement = videoRef?.current;
    const currentAudioElement = audioRef?.current;

    // Handle media activity for video element
    if (currentVideoElement) {
      currentVideoElement.addEventListener('play', resetTimeout);
      currentVideoElement.addEventListener('pause', resetTimeout);
      currentVideoElement.addEventListener('ended', resetTimeout);
      currentVideoElement.addEventListener('timeupdate', resetTimeout);
    }

    // Handle media activity for audio element
    if (currentAudioElement) {
      currentAudioElement.addEventListener('play', resetTimeout);
      currentAudioElement.addEventListener('pause', resetTimeout);
      currentAudioElement.addEventListener('ended', resetTimeout);
      currentAudioElement.addEventListener('timeupdate', resetTimeout);
    }

    resetTimeout();

    return () => {
      activityEvents.forEach((event) =>
        window.removeEventListener(event, resetTimeout)
      );

      if (activityTimeout.current) {
        clearTimeout(activityTimeout.current);
      }

      // Clean up video event listeners
      if (currentVideoElement) {
        currentVideoElement.removeEventListener('play', resetTimeout);
        currentVideoElement.removeEventListener('pause', resetTimeout);
        currentVideoElement.removeEventListener('ended', resetTimeout);
        currentVideoElement.removeEventListener('timeupdate', resetTimeout);
      }

      // Clean up audio event listeners
      if (currentAudioElement) {
        currentAudioElement.removeEventListener('play', resetTimeout);
        currentAudioElement.removeEventListener('pause', resetTimeout);
        currentAudioElement.removeEventListener('ended', resetTimeout);
        currentAudioElement.removeEventListener('timeupdate', resetTimeout);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeout, videoRef, audioRef]);

  return isUserActive;
};
