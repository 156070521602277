import React from 'react';

interface SetupProps {}

const Setup: React.FC<SetupProps> = () => {
  return (
    <div className='-container'>
     Setup component
    </div>
  );
};

export default Setup;