import React from 'react';
import { Modal } from 'react-bootstrap';
import LoginComponent from '../LoginComponent';
import '../Styles/RefreshLogin.scss';

interface RefreshLoginProps {
    show: boolean;
    handleClose: () => void;
}

const RefreshLogin: React.FC<RefreshLoginProps> = ({ show, handleClose }) => {
    if (!show) return null;

    return (
        <Modal show={show} onHide={handleClose} centered backdrop="static" dialogClassName="login-modal">
            <Modal.Header className="login-modal-header">
                <Modal.Title className="login-modal-title">Session Expired</Modal.Title>
            </Modal.Header>
            <Modal.Body className="login-modal-body">
                <p className="login-modal-text">Please sign in to continue.</p>
                <LoginComponent isModal={true} />
            </Modal.Body>
        </Modal>
    );
};

export default RefreshLogin;