import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFetchApi, useUserDeets } from "CustomHooks";
import { format } from "date-fns";
import { TP_ADDRESS } from "Constants/constants"; 
import {
  faPlus,
  faMinus,
  faSearch,
  faEye,
  faEyeSlash,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import {
  Bluey,
  DatePickerComponent,
  Alert,
  LoginComponent,
  SpacerDiv,
} from "Components";
import InputMask from "react-input-mask";
import { Modal } from "react-bootstrap";

import "./Styles/studentEnrol.scss";

interface StudentEnrolProps {}

const StudentEnrol = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const fetchApi = useFetchApi();
  const userDeets = useUserDeets();
  const roleId = userDeets?.roleId || ""; 
  const [id, setId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [msg, setMsg] = useState<string>("");
  const [msgCode, setMsgCode] = useState<0 | 1 | 2 | 3 | 4>(0);
  const [classData, setClassData] = useState<any>(null);
  const [isReturningStudent, setIsReturningStudent] = useState<boolean>(false);
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [showModules, setShowModules] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [requiredComplete, setRequiredComplete] = useState<boolean>(false);
  const [foundUser, setFoundUser] = useState<boolean>(false);
  const [usiConfirmed, setUsiConfirmed] = useState<boolean>(false);
  const [lastCheck, setLastCheck] = useState<any>({
    email: "",
    firstName: "",
    lastName: "",
    dob: "",
  });

  const [formData, setFormData] = useState<any>({
    email: "",
    firstName: "",
    lastName: "",
    gender: "",
    dob: "",
    phone_number: "",
    password: "",
    USI: "",
    canEmailEmployer:  false,
    employerEmail: "",
    consent: false,
    classId: "",
    isReturning: 0,
    roleId: "",
  });
    
  useEffect(() => {
    if (determineUsiFetchType() === "RETURNING_USER") {
      usiFetch();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReturningStudent, roleId, formData.USI]);

  const fetchClassDetails = (data: any) => {
    fetchApi("classroom/classDetails", { classId: data })
      .then((response) => {
        switch (true) {
          case response.status && response.status >= 200 && response.status < 300:
            setClassData(response.responseData);
            setMsgCode(4);
            break;
          default:
            setMsg(response.msg || "An unexpected error occurred.");
            setMsgCode(1);
            break;
        }
      })
      .catch((error) => {
        console.error("Fetch failed or fetch function is unreachable:", error);
        setMsg(
          "An unexpected error occurred. Contact your administrator" + error
        );
        setMsgCode(1);
      })
      .finally(() => {
        setIsLoading(false); 
      });
  };

  useEffect(() => {
    if (id) {
      fetchClassDetails(id);
      formData.classId = id;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const shouldCheckUser = () => {
    const requiredFields = ['email', 'firstName', 'lastName', 'dob'];
    return requiredFields.every((field) => !!formData[field]);
  };
  
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, type, value } = e.target;

    if (shouldCheckUser()) {
      handleUserCheck();
    }

    if (e.target instanceof HTMLInputElement) {
      const checked = e.target.checked;
      if (type === "checkbox" || type === "radio") {
        setFormData((prevState: any) => ({
          ...prevState,
          [name]: checked,
        }));
      } else {
        setFormData((prevState: any) => ({
          ...prevState,
          [name]: value,
        }));
      }
    } else if (e.target instanceof HTMLSelectElement) {
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const formatDate = (date: string, formatType: "display" | "storage"): string => {
    if (!date) return "";
  
    if (formatType === "display") {
      // Convert from YYYY-MM-DD → DD/MM/YYYY
      const parts = date.split("-");
      return parts.length === 3 ? `${parts[2]}/${parts[1]}/${parts[0]}` : date;
    } else {
      // Convert from DD/MM/YYYY → YYYY-MM-DD
      const parts = date.split("/");
      return parts.length === 3 ? `${parts[2]}-${parts[1]}-${parts[0]}` : date;
    }
  };
  

  
  const handleDateChange = (date: string | Date | null) => {
    if (!date) return;
  
    let formattedDate = "";
  
    if (typeof date === "string") {
      formattedDate = formatDate(date, "storage"); // ✅ Convert to YYYY-MM-DD for storage
    } else if (date instanceof Date && !isNaN(date.getTime())) {
      formattedDate = date.toISOString().split("T")[0]; // ✅ Ensure proper format
    } else {
      console.warn("Invalid date received:", date);
      return;
    }
  
    setFormData((prevState: any) => ({
      ...prevState,
      dob: formattedDate,
    }));
  
    if (shouldCheckUser()) {
      handleUserCheck();
    }
  };
  
  

  const determineUsiFetchType = () => {
    if (isReturningStudent && roleId && formData.USI === "") {
      return "RETURNING_USER";
    }
    if (formData.USI && !isReturningStudent) {
      return "NEW_WITH_USI";
    }
    if (!formData.USI) {
      return "USER_WITHOUT_USI"; // Completed
    }
    return null;
  };

  const usiFetch = () => { 
    const determineUsiFetchType = () => {
      if (formData.USI && !isReturningStudent) {
        console.log("New user with USI");
        return "NEW_WITH_USI";
      }
      if (formData.USI === "" && formData.email && formData.firstName && formData.lastName && formData.dob) {
        console.log("User without USI");
        return "USER_WITHOUT_USI";
      }
      if (isReturningStudent && roleId && !formData.USI) {
        console.log("Returning user");
        return "RETURNING_USER";
      }
      return null;
    };

    
    const fetchUSI = async () => {
      const usiFetchType = determineUsiFetchType();
      const usiFormData = new FormData();
      let endpoint = "";
  
      switch (usiFetchType) {
        case "NEW_WITH_USI":
          console.log("New user with USI");
          endpoint = "https://tp.edu.au/portal/tpro-files/backend/php/people-ajax.php";
          usiFormData.append("command", "CheckUsiNew");
          usiFormData.append("input", formData.USI);
          usiFormData.append("dataFrontend[first]", formData.firstName);
          usiFormData.append("dataFrontend[last]", formData.lastName);
          usiFormData.append("dataFrontend[dob]", formData.dob);
          usiFormData.append("dataFrontend[DOB]", formData.dob);
          usiFormData.append("dataFrontend[avetmiss][sex]", formData.gender);
          usiFormData.append("dataFrontend[addressSuburb]", TP_ADDRESS.addressSuburb);
          usiFormData.append("dataFrontend[addressState]", TP_ADDRESS.addressState);
          usiFormData.append("dataFrontend[addressPostcode]", TP_ADDRESS.addressPostcode);
          usiFormData.append("dataFrontend[addressStreetNumber]", TP_ADDRESS.addressStreetNumber);
          usiFormData.append("dataFrontend[addressStreetName]", TP_ADDRESS.addressStreetName);
          usiFormData.append("dataFrontend[searchUsiPermission]", "1");
          break;
  
        case "USER_WITHOUT_USI":
          console.log("User without USI");
          endpoint = "https://tp.edu.au/portal/tpro-files/backend/php/usi-ajax.php";
          usiFormData.append("command", "LocateUsiv2");
          usiFormData.append("first", formData.firstName);
          usiFormData.append("last", formData.lastName);
          usiFormData.append("email", formData.email);
          usiFormData.append("dob", formData.dob);
          usiFormData.append("gender", formData.gender);
          usiFormData.append("mobile", formData.phone_number);
          break;
  
        case "RETURNING_USER":
          console.log("Returning user");
          endpoint = "https://tp.edu.au/portal/tpro-files/backend/php/usi-ajax.php";
          usiFormData.append("command", "LocateUsi");
          usiFormData.append("studentId", roleId.toString());
          break;
  
        default:
          console.log("No USI fetch type determined");
          return; // Exit early
      }
  
      try {
        const response = await fetch(endpoint, {
          method: "POST",
          body: usiFormData,
        });
  
        if (!response.ok) {
          throw new Error(`Failed to fetch USI: ${response.status}`);
        }

        if (response.status === 200) {
        const data = await response.json();
        setFormData((prevState: any) => ({
          ...prevState,
          USI: data?.usi?.USI,
        }));
        setUsiConfirmed(true);
        } else {
          console.warn("USI not found");
        }
      } catch (error) {
        console.error(`Error: ${error}`);
        setMsg("An unexpected error occurred. Contact your administrator");
        setMsgCode(1);
      }
    };
  
    fetchUSI();
  };

  const handleUsiBlur = () => { 
    if(determineUsiFetchType() === "NEW_WITH_USI") {
      usiFetch();
    }
  }

  const handleUserCheck = async () => {
    // Exit immediately if the user is a returning student
    if (isReturningStudent) return;

    // Exit immediately if the required fields are the same as the previous check
    if (
      lastCheck.email === formData.email &&
      lastCheck.firstName === formData.firstName &&
      lastCheck.lastName === formData.lastName &&
      lastCheck.dob === formData.dob
    ) return;

    // If we get to here we know that fields have changed, update them
    setLastCheck({
      email: formData.email,
      firstName: formData.firstName,
      lastName: formData.lastName,
      dob: formData.dob,
    });

    if (shouldCheckUser()) {
      const cleanFormData = Object.fromEntries(
        Object.entries({
          email: formData.email,
          firstName: formData.firstName,
          lastName: formData.lastName,
          dob: formData.dob,
          usi: formData.USI,
          gender: formData.gender,
          phone_number: formData.phone_number,
        }).map(([key, value]) => [key, value === "" ? null : value])
      );
      
      await fetchApi("user/checkUser", cleanFormData)      
      .then ((response) => {
        switch (true) {
          case response.status && response.status === 201:
            setIsReturningStudent(false)
            break;
          // The API says this user is already in the system
          case response.status && response.status === 202:
            console.log('User already exists', response.responseData);
            setFoundUser(true);
            setShowLoginModal(true);
            break;
          default:
            setMsg(response.msg || "An unexpected error occurred.");
            setMsgCode(1);
            break;
        }
      })
    } else {
      return;
    }
  };

  const modalMessage = () => { 
    if (foundUser) {
      return "We found a user with these details. Please login with your password to continue.";
    } else {
      return "Please login with your username and password below to help use find your details.";
    }
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setFormData((prevState: any) => ({
      ...prevState,
      roleId: roleId,
    }));
    fetchApi("student/enrol", formData)
    console.log("Form submitted", formData);
  };

  useEffect(() => {
    // Extract query parameter
    const queryParams = new URLSearchParams(location.search);
    const enrolId = queryParams.get("id");

    if (enrolId) {
      setId(enrolId);
      // Remove query parameters from the URL
      navigate("/class-enrol-student", { replace: true });
    }
  }, [location, navigate]);

  const formatTime = (time: number) => {
    const hour = Math.floor(time / 2);
    const minutes = time % 2 === 0 ? "00" : "30";
    const timeAnnotation = hour < 12 ? "AM" : "PM";
    const formattedTime = hour.toString().padStart(2, "0") + ":" + minutes + '' + timeAnnotation;
    return formattedTime;
  };

  const toggleShowModules = () => {
    setShowModules(!showModules);
  };

  const requiredFields = useMemo(() => {
    return isReturningStudent
      ? ["email", "firstName", "lastName", "dob", "gender", "phone_number", "consent"]
      : ["email", "firstName", "lastName", "dob", "gender", "phone_number", "password", "consent"];
  }, [isReturningStudent]);

  const isFieldInvalid = (field: string) => requiredFields.includes(field) && !formData[field];

  useEffect(() => {
    const isComplete = requiredFields.every((field) => !isFieldInvalid(field));
    setRequiredComplete(isComplete);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, requiredFields]);
  

  useEffect(() => {
    if (isReturningStudent && userDeets) {
      const [firstName, ...lastNameParts] = userDeets.fullName
        ? userDeets.fullName.split(" ")
        : ["", ""];
      const lastName = lastNameParts.join(" ");
  
      setFormData((prevState: any) => {
        const newFormData = {
          ...prevState,
          email: userDeets.email || "",
          firstName: firstName || "",
          lastName: lastName || "",
          gender: userDeets.gender ||"",
          dob: userDeets.dob || "",
          phone_number: userDeets.phoneNumber || "",
          password: "",
          USI: userDeets.usi || "",
          isReturning: 1,
        };

        if (userDeets.dob) {
          handleDateChange(new Date(userDeets.dob));
        }
  
        const isComplete = requiredFields.every((field) => !!newFormData[field]);
        setRequiredComplete(isComplete);
  
        return newFormData;
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReturningStudent, userDeets]);
  
  const handleReturningStudent = () => {
    setIsReturningStudent(true);
    setShowLoginModal(false);
    setShowForm(true);
  };

  const handleNewStudent = () => {
    setIsReturningStudent(false);
    setShowForm(true);
  };

  const handleModalClose = () => {
    if (foundUser) {
      setFormData(() => ({
        email: "",
        firstName: "",
        lastName: "",
        gender: "",
        dob: "",
        phone_number: "",
        password: "",
        USI: "",
        canEmailEmployer:  false,
        employerEmail: "",
        consent: false,
        classId: "",
        isReturning: 0,
      }));
      setFoundUser(false);
    }
    setShowLoginModal(false);
  };

  useEffect(() => {
    // Lock scrolling when modal is open
    if (showLoginModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [showLoginModal]);

  const closeButtonLabel = foundUser ? "THIS IS NOT ME" : isReturningStudent ? "GO BACK" : "CANCEL";


  return (
    <>
      <Modal 
        show={showLoginModal} 
        onHide={handleModalClose} 
        centered 
        backdrop="static" 
        // style={{ width: "100% !important", height: '85vh', overflow: 'hidden'}}
        dialogClassName="enrol-login-modal">
          {/* <Modal.Body style={{ backgroundColor: '#f0f7fa', color: '#393939', paddingTop: '0', overflow: 'hidden'}}> */}
          <Modal.Body style={{ backgroundColor: '#f0f7fa', color: '#393939', paddingTop: '0', overflow: 'hidden'}}>
            <LoginComponent 
              isModal={true}
              isEnrol 
              onCloseModal={handleModalClose} 
              onLoginSuccess={handleReturningStudent}
              modalMessage={modalMessage()} 
              closeButtonLabel={closeButtonLabel}
              username={formData.email || ""}
            />
          </Modal.Body>
        </Modal>

      <Bluey />
      <div className="student-enrol-container">
        {isLoading ? (
          <div style={{ textAlign: "center",  width: '90%', margin: '4rem auto'}}>
            <h3>Oops something went wrong ... please scan the QR code and try again!</h3>
          </div>
        ): (
        <div>
       
          <SpacerDiv  rem={1} />
          <p>This will enrol you in</p>
          <h3>
            <strong>{classData.className.split(" - ")[0]}</strong>
          </h3>
          <h3>
            <strong>{classData.className.split(" - ")[1]}</strong>
          </h3>
          <p>
            Commencing <strong>{format(new Date(classData.classDetails.startDate), "dd MMMM yyyy")}</strong> at <strong>{formatTime(classData.classDetails.startTime)}</strong>
          </p>
          <p>AT</p>
          <p>{classData.venueDetails.name}, {classData.venueDetails.address}, {classData.venueDetails.suburb}</p>
          {!isReturningStudent ?
            <>
              <SpacerDiv rem={1} />
              <div className="form-btn-container">
                <button className="btn-primary" onClick={() => setShowLoginModal(true)}>I am a returning student</button>
              </div>
              <p>OR</p>
              <div className="form-btn-container">
                <button className="btn-primary" onClick={handleNewStudent}>I am a new student</button>
              </div>
              <SpacerDiv rem={1} />
            </>
            : 
            <>
              <div className='highlighted-text'>Please check your details below</div>
              <SpacerDiv rem={0.5} />     
            </>
          }
        
          <form className="enrol-form" onSubmit={handleFormSubmit} style={{ display: showForm ? "" : "none" }}>  
            <input
              type="text"
              className={`form-input large ${isFieldInvalid("email") ? "required" : ""}`}
              name="email"
              placeholder="Email ..."
              value={formData.email}
              onChange={handleInputChange}
              onBlur={handleUserCheck}
              required
            />
        
            <input
              type="text"
              className={`form-input large ${isFieldInvalid("firstName") ? "required" : ""}`}
              name="firstName"
              placeholder="First Name ..."
              value={formData.firstName}
              onChange={handleInputChange}
              onBlur={handleUserCheck}
              required
            />
            <input
              type="text"
              className={`form-input large ${isFieldInvalid("lastName") ? "required" : ""}`}
              name="lastName"
              placeholder="Last Name ..."
              value={formData.lastName}
              onChange={handleInputChange}
              onBlur={handleUserCheck}
              required
            />

            {/* This needs to be fixed to allow date picking correctly for desktop */}
            {/* <DatePickerComponent
              className="dob-picker"
              selectedDate={formData.dob ? new Date(formData.dob) : null}
              onDateChange={handleDateChange}
              onBlur={handleUserCheck}
              placeholderText="Date of Birth"
              required={isFieldInvalid("dob")}
            /> */}

            <InputMask
              type="text"
              inputMode='numeric'
              className={`form-input large ${isFieldInvalid("dob") ? "required" : ""}`}
              name="dob"
              placeholder="Date of Birth ..."
              value={formatDate(formData.dob, 'display')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {handleDateChange(e.target.value)}}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => {handleDateChange(e.target.value)}}
              mask="99/99/9999"
            />

            <select
              className={`form-input large ${isFieldInvalid("gender") ? "required" : ""}`}
              name="gender"
              value={formData.gender}
              onChange={handleInputChange}
              required
            >
              <option value="" disabled>
                Select Gender
              </option>
              <option value="0">Female</option>
              <option value="1">Male</option>
              <option value="2">Other</option>
            </select>
           
            <input
              type="tel"
              inputMode="numeric"
              className={`form-input large ${isFieldInvalid("phone_number") ? "required" : ""}`}
              name="phone_number"
              placeholder="Mobile"
              value={formData.phone_number}
              onChange={handleInputChange}
              required
            />

            {(!isReturningStudent && shouldCheckUser()) &&
              <div style={{ position: "relative" }}>
                <input
                  type={showPassword ? "text" : "password"}
                  className={`form-input large ${isFieldInvalid("password") ? "required" : ""}`}
                  name="password"
                  placeholder={isReturningStudent ? "Password" : "Create a password"}
                  value={formData.password}
                  onChange={handleInputChange}
                  required
                />
                <FontAwesomeIcon
                  className="showPassword-btn"
                  onClick={() => setShowPassword(!showPassword)}
                  icon={showPassword ? faEyeSlash : faEye}
                />
              </div>
            }

            <div className={`form-checkbox-container ${isFieldInvalid("consent") ? "required" : ""}`}>
              <input
                className="radio-as-checkbox required"
                type="checkbox"
                id="consent"
                name="consent"
                checked={formData.consent}
                onChange={handleInputChange}
                required
              />
            <label htmlFor="consent"  style={{ margin: "0"}}>
                <span className="form-checkbox-label">I have received enough information and want to enrol in this class</span>
            </label>
            </div>

            <div style={{ position: "relative" }}>
              <input
                type="text"
                name="USI"
                // className="form-input large"
                className={`form-input large ${usiConfirmed ? "confirmed" : ""}`}
                placeholder="Unique Student Identifier"
                value={formData.USI}
                onBlur={handleUsiBlur}
                onChange={handleInputChange}
                readOnly={usiConfirmed}
              />
              {!usiConfirmed && <span className='asterisk fixed'>*</span>}
              {usiConfirmed ? 
                <FontAwesomeIcon 
                  className="check" 
                  icon={faCheck} 
                  onClick={() => usiFetch()}
                />
                  :
                <FontAwesomeIcon 
                  className="search-btn" 
                  icon={faSearch} 
                  onClick={() => usiFetch()}
                />
              }

            </div>
          
            <div className="form-btn-container">
              <button className="btn-primary" onClick={handleFormSubmit} disabled={!requiredComplete}>
                ENROL NOW
              </button>
              <p className='important-text' style={{ height: '20px'}}>
                {requiredComplete ? "" : "Fields in this colour are required"}
              </p>
              <div>
                <span className='asterisk'>*</span>
                <span className='subtle-text' style={{ marginLeft: '5px'}}>
                  By interacting with this form you give permission to search for your USI
                </span>
              </div>
            </div>

            <div className='form-checkbox-container'>
              <input
                className="radio-as-checkbox"
                type="checkbox"
                id="copyEmail"
                name="canEmailEmployer"
                checked={formData.canEmailEmployer }
                onChange={handleInputChange}
              />
              <label htmlFor="copyEmail" style={{ marginLeft: "5px" }}>
                  <span className="form-checkbox-label">Email a copy of my certificate to my employer</span>
              </label>
            </div>
            {formData.canEmailEmployer && 
              <input
                type="email"
                className="form-input large"
                name="employerEmail"
                placeholder="Employer Email"
                value={formData.employerEmail}
                onChange={handleInputChange}
              />
            }
            <SpacerDiv rem={1} />
          </form>
          
          {showForm && <SpacerDiv rem={1} />}
          <div className="module-toggle" onClick={toggleShowModules}>
            <div>VIEW MODULES</div>
            <FontAwesomeIcon
              className="show-modules-btn"
              icon={showModules ? faMinus : faPlus}
            />
          </div>

          <div
            className={`modules-container ${showModules ? "expanded" : ""}`}
            style={{
              height: showModules ? `${document.querySelector(".modules-content")?.scrollHeight || 0}px` : "0",
            }}
          >
            <div className="modules-content">
              <div className="module-grid" style={{ fontWeight: "bold" }}>
                <div>Code</div>
                <div>Module Name</div>
              </div>
              <SpacerDiv rem={0.75} />
              <div className="horizontal-divider" />
              <SpacerDiv rem={0.5} />
              {classData.modulesDetails.map((module: any, index: number) => (
                <div key={index} className="module-grid">
                  <div>{module.code}</div>
                  <div>{module.name}</div>
                </div>
              ))}
            </div>
          </div>
          <SpacerDiv rem={1} />

        </div>
        )}
      </div>
    </>
  );
};

export default StudentEnrol;