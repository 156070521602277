import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

interface WorkflowStep {
  id?: number;
  step_id?: number;
  instructions: string;
  link: string;
  priority: number;
  status: 'NOT_STARTED' | 'IN_PROGRESS' | 'COMPLETE' | null,
  complete: 0 | 1
}
interface WorkflowState {
  workflows: WorkflowStep[];
  isLoading: boolean;
}

const initialState: WorkflowState = {
  workflows: [],
  isLoading: true
};

const extractParams = (link: string) => {
  try {
    const url = new URL(link);
    const rawId = url.searchParams.get('id');
    const rawStep = url.searchParams.get('step_id');
    const id = rawId ? parseInt(rawId, 10) : undefined;
    const step_id = rawStep ? parseInt(rawStep, 10) : undefined;

    // Ensure we return a correct relative route for internal navigation
    const isFormLink = url.pathname.includes("/form/");
    const cleanedPath = isFormLink ? `/form/${id}?step_id=${step_id || 1}` : url.pathname;

    return { id, step_id, cleanedPath };
  } catch (error) {
    console.warn(`Invalid link: ${link}`);
    return { id: undefined, step_id: undefined, cleanedPath: link };
  }
};


// Define an async thunk
export const setWorkflowDataAsync = createAsyncThunk(
  'workflow/setWorkflowDataAsync',
  async (workflowData: WorkflowStep[], { dispatch }) => {
    // Simulate async delay
    await new Promise((resolve) => setTimeout(resolve, 0));

    // Sort the workflow steps by priority
    const sortedWorkflowData = workflowData.sort((a, b) => b.priority - a.priority);

    // Take the first 3 steps
    const prioritySteps = sortedWorkflowData.slice(0, 3);

    // Extract the step and id from the link
    const processedSteps = prioritySteps.map(step => ({
      ...step,
      ...extractParams(step.link), // Fix link structure
      link: extractParams(step.link).cleanedPath, // Ensure proper relative path
    }));

    dispatch(setWorkflowData(processedSteps));
    return processedSteps;
  }
);

const workflowSlice = createSlice({
  name: 'workflow',
  initialState,
  reducers: {
    setWorkflowData(state, action: PayloadAction<WorkflowStep[]>) {
      state.workflows = action.payload;
      state.isLoading = false;
    },
    clearWorkflowData(state) {
      state.workflows = [];
      state.isLoading = true;
    }
  }
});

export const { setWorkflowData, clearWorkflowData } = workflowSlice.actions;
export default workflowSlice.reducer;
