import React from 'react';
import { useNavigate } from 'react-router-dom';
import { construction } from 'Images';
import SpacerDiv from 'Components/SpacerDiv';

interface ConstructionProps {
  route: string;
}

const Construction: React.FC<ConstructionProps> = ({ route = '' }) => {

  const navigate = useNavigate();

  return (
    <div className='construction-container' style={{margin: '4rem auto'}}>
      <h1 style={{fontWeight: 'bold', color:'#393939' }}>The page for<span style={{ color: '#747683' }}>{route}</span> is currently</h1>
      <SpacerDiv />
      <img src={construction} alt='construction' style={{width: '400px'}} />
      <SpacerDiv />
      <h1 style={{fontWeight: 'bold', color:'black' }}>UNDER CONSTRUCTION</h1>
      <p style={{color: '#393939', fontSize: '20px'}}>Please check back later.</p>
      <button className="event-btn construction" onClick={() => navigate(-1)}>
          Go Back
        </button>
    </div>
  );
};

export default Construction;