import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../State/Reducers";
import { updateFormData, resetFormData } from '../State/Reducers/formSlice';
import { useCallback } from 'react';

interface UseFormReturn {
  formData: Record<string, any>;
  questionStatus: Record<string, boolean>;
  getGroupData: (groupId: string | number) => Record<string, any> | undefined;
  isQuestionAnswered: (questionId: string) => boolean;
  updateGroupData: (
    groupId: string,
    questionData: { questionId: string; qIndex: number; qType: number; response: string; compulsory?: boolean }
  ) => void;
  resetForm: () => void;
}

export const useForm = (): UseFormReturn => {
  const dispatch = useDispatch();
  const formData = useSelector((state: RootState) => state.form.formData);
  const questionStatus = useSelector((state: RootState) => state.form.questionStatus);

  // Retrieve group data
  const getGroupData = useCallback(
    (groupId: string | number) => formData[groupId] || {},
    [formData]
  );

  // Check if a specific question is answered
  const isQuestionAnswered = useCallback(
    (questionId: string) => questionStatus[questionId] || false,
    [questionStatus]
  );

  // Update group data and question status
  const updateGroupData = useCallback(
    (
      groupId: string,
      questionData: { questionId: string; qIndex: number; qType: number; response: string; compulsory?: boolean }
    ) => {
      const { questionId, qIndex, qType, response, compulsory } = questionData;
  
      // Default value check for date qTypes
      const isDateQuestion = [301, 302, 303].includes(qType);
      const defaultDate = new Date().toISOString().split('T')[0];
      const isDefaultValue = isDateQuestion && response === defaultDate;
  
      // Treat default date as a valid response for compulsory questions
      const formDataUpdate = {
        [questionId]: { qIndex, qType, response },
      };
  
      dispatch(updateFormData({ moduleId: groupId, data: formDataUpdate, compulsory: compulsory || isDefaultValue }));
    },
    [dispatch]
  );
  

  // Reset the form
  const resetForm = useCallback(() => {
    dispatch(resetFormData());
  }, [dispatch]);

  return {
    formData,
    questionStatus,
    getGroupData,
    isQuestionAnswered,
    updateGroupData,
    resetForm,
  };
};

