import React from 'react';

interface FilesProps {}

const Files: React.FC<FilesProps> = () => {
  return (
    <div className='-container'>
     Files component
    </div>
  );
};

export default Files;