import React, { useState, useEffect, useRef, useCallback, ReactNode } from 'react';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import './Styles/Carousel.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface CarouselImage {
  src: string;
  onClick?: () => void;
  navigateTo?: string;
  overlayContent?: ReactNode;
}
interface CarouselProps {
  images: CarouselImage[];
  autoPlay?: boolean;
  interval?: number;
  height?: string;
  width?: string;
  className?: string;
}

const Carousel: React.FC<CarouselProps> = ({ 
  images, 
  autoPlay = true, 
  interval = 8000, 
  height = '', 
  width = '100%', 
  className,
}) => {
  const slides = [images[images.length - 1], ...images, images[0]];
  const totalSlides = slides.length;

  const [autoPlayEnabled, setAutoPlayEnabled] = useState<boolean>(autoPlay);
  const [currentIndex, setCurrentIndex] = useState<number>(1);
  const [isTransitioning, setIsTransitioning] = useState<boolean>(true);
  const carouselRef = useRef<HTMLDivElement>(null);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const nextSlide = useCallback(() => {
    if (currentIndex < totalSlides - 1) {
      setCurrentIndex(prevIndex => prevIndex + 1);
    }
  }, [currentIndex, totalSlides]);
  
  const prevSlide = useCallback(() => {
    if (currentIndex > 0) {
      setCurrentIndex(prevIndex => prevIndex - 1);
    }
  }, [currentIndex]);

  useEffect(() => {
    if (autoPlayEnabled) {
      intervalRef.current = setInterval(() => {
        nextSlide();
      }, interval);

      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      };
    }
  }, [autoPlayEnabled, interval, nextSlide]);

  // Handle the transition to cloned slides
  const handleTransitionEnd = () => {
    if (currentIndex === 0) {
      setIsTransitioning(false);
      setCurrentIndex(images.length);
    } else if (currentIndex === totalSlides - 1) {
      setIsTransitioning(false);
      setCurrentIndex(1);
    }
  };

  // Re-enable transition after jumping
  useEffect(() => {
    if (!isTransitioning) {
      const timeout = setTimeout(() => {
        setIsTransitioning(true);
      }, 20);
  
      return () => clearTimeout(timeout);
    }
  }, [isTransitioning]);
  
  const togglePause = () => {
    setAutoPlayEnabled(prevState => !prevState);
  }

  const handleImageClick = (image: CarouselImage) => {
    if (image.onClick) {
      image.onClick();
    } else if (image.navigateTo) {
      window.location.href = image.navigateTo;
    }
  };

  const currentOverlayIndex = currentIndex === 0 ? images.length - 1 : currentIndex === totalSlides - 1 ? 0 : currentIndex - 1;

  return (
    <>
      <div className={`carousel ${className}`} style={{ height: height, width: width }}>
        <div
          className="carousel-container"
          ref={carouselRef}
          style={{
            transform: `translateX(-${currentIndex * 100}%)`,
            transition: isTransitioning ? `transform 0.7s ease-in-out` : 'none',
          }}
          onTransitionEnd={handleTransitionEnd}
        >
          {slides.map((image, index) => (
            <div
              key={index}
              className="carousel-slide"
              onClick={() => handleImageClick(image)}
            >
              <img src={image.src} alt={`Slide ${index}`} />
              {image.overlayContent &&
              <>
                <div className="slide-overlay" />
                <p className='overlay-content'>{image.overlayContent}</p>
              </>
              }
            </div>
          ))}
        </div>
        <button 
          className={`carousel-button ${autoPlayEnabled ? 'pause' : 'play'}`} 
          onClick={togglePause} 
          aria-label={autoPlayEnabled ? 'Pause' : 'Play'}
        >
          <FontAwesomeIcon className="icon-shadow" icon={autoPlayEnabled ? faPause : faPlay} />
        </button>
        <button className="carousel-button prev" onClick={prevSlide} aria-label="Previous Slide" />
        <button className="carousel-button next" onClick={nextSlide} aria-label="Next Slide" />
      </div>
      {images[currentOverlayIndex].overlayContent && 
        <div className={`overlay-mobile ${className}`} onClick={images[currentOverlayIndex].onClick}> 
          {images[currentOverlayIndex].overlayContent}
        </div>
      }
    </>
  );
};

export default Carousel;
