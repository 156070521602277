// IMPORTANT: Use this component to embed videos in the app.
// It is setup to track user activity for the video playback.
// This allows us to prevent the user from being logged out while watching a video.

import React, { useRef } from 'react';
import { useActivityLogger } from '../CustomHooks';

interface VideoPlayerProps {
  src: string;
  width?: number;
  controls?: boolean;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ src, width = 400, controls = true }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useActivityLogger({videoRef});

  return (
    <div>
      <video ref={videoRef} width={width} controls={controls}>
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoPlayer;
