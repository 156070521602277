import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const Legend = () => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    const buttonStyle = {
        position: 'fixed',
        bottom: '25px',
        left: '25px',
        // padding: '10px 20px',
        border: '1px solid #ccc',
        borderRadius: '10px',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        zIndex: 1001,
        cursor: 'pointer',
        opacity: 0.5,
        height: 40,
        width: 300,
        fontSize: 18,
        marginTop: 10,
        alignContent: 'center',
        justifyContent: 'center',
    };

    const iconStyles = {
        position: 'fixed',
        bottom: '30px',
        left: '10px',
        color: '#393939',
        backgroundColor: '#955cce',
        zIndex: 1001,
        cursor: 'pointer',
        opacity: 0.5,
        height: 40,
        width: 40,
        borderRadius: '50%'
    };
    

    const legendStyles = {
        position: 'fixed',
        bottom: '70px',
        left: '25px',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '10px',
        backgroundColor: '#efcbd7',
        zIndex: 1000,
        width: 300,
        textAlign: 'center',
        maxWidth: 'calc(100% - 40px)',
        fontSize: '12px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        display: isVisible ? 'block' : 'none'
    };

    return (
        <>
            <div className='desktop-only' style={buttonStyle} onClick={toggleVisibility}>
                {isVisible ? 'HIDE LEGEND' : 'SHOW LEGEND'}
            </div>
            
            <FontAwesomeIcon className='mobile-only' style={iconStyles} icon={faInfoCircle} onClick={toggleVisibility}/>

            <div className="legend-container" style={legendStyles}>
                <h3 style={{ marginBottom: '10px' }}>Legend</h3>
                <div style={{ display: 'flex', flexWrap: 'wrap', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                    <div style={{  }}>
                        <ul style={{ listStyleType: 'none', padding: 0, fontSize: '12px', textAlign: 'center' }}>
                            <li><strong>NYE</strong> - Not Yet Enrolled</li>
                            <li><strong>E</strong> - Enrolled</li>
                            <li><strong>C*</strong> - Completed</li>
                            <li><strong>C</strong> - Competent</li>
                            <li><strong>NYC</strong> - Not Yet Competent</li>
                            <li><strong>W</strong> - Withdrawn</li>
                            <li><strong>RPL</strong> - Recognition of Prior Learning</li>
                            <li><strong>NRP</strong> - Not Recognized Prior Learning</li>
                            <li><strong>CT</strong> - Credit Transferred</li>
                            <li><strong>SS</strong> - Superseded</li>
                            <li><strong>CE</strong> - Continuing Education</li>
                            <li><strong>NAC</strong> - Not Achieved Competency</li>
                            <li><strong>NAN</strong> - Not Achieved, Not Competent</li>
                            <li><strong>NYA</strong> - Not Yet Assessed</li>
                            <li><strong>?</strong> - Unknown</li>
                            <li><strong>N/A</strong> - Not Available</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Legend;
