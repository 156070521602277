import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../State/Reducers";
import { saveAnswers as save, clearAnswers as clear } from "../State/Reducers/assessmentSlice";

type AnswersType = Record<string, string>;

export const useAssessmentAnswers = () => {
  const dispatch = useDispatch();

  const answers = useSelector((state: RootState) => state.assessment.answers || {});

  const getAnswersForQuestion = (qNum: string): AnswersType => {
    return answers[qNum] || {}; 
  };

  const saveAnswers = (qNum: string, newAnswers: AnswersType) => {
    dispatch(
      save({
        qNum,
        answers: newAnswers,
      })
    );
  };

  const clearAnswers = () => {
    dispatch(clear());
  };

  return {
    answers,
    getAnswersForQuestion,
    saveAnswers,
    clearAnswers,
  };
};
