import { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './Styles/index.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { ConstProvider } from 'ConstLink';
import { useDispatch, useSelector } from 'react-redux';
import { restoreUser } from './State/Reducers/userSlice';
import { useActivityLogger, useHeartbeat, useUserField } from 'CustomHooks';
import { generateFormRoute } from 'Utils/generateRoute';

// Pages
import * as Pages from './Pages';

// Components
import * as CMP from './Components';


function App() {
  const dispatch = useDispatch();
  const isLoggedIn = useUserField('loggedIn');

  // Used to call the refresh login on a request that returns an expired token
  const showRefreshLogin = useSelector((state) => state.refreshLogin.showRefreshLogin);
  // console.log(location.pathname)
  
  // Restore user session from encrypted data
  useEffect(() => {
    dispatch(restoreUser());
  }, [dispatch]);

  // Logs user activity and logs out the user if they are inactive for 20 minutes
  useActivityLogger();

  // Sends a heartbeat to the server every 15 seconds
  useHeartbeat();

  // Grab the workflow state to generate the url for forms dynamically
  const oldWorkflowState = useSelector((state) => state.oldWorkflow.oldWorkflowData);

  useEffect(() => {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId: process.env.REACT_APP_FB_APP_ID,
        cookie: true,
        xfbml: true,
        version: 'v10.0'
      });

      // Additional setup here if needed
    };

    // Load the SDK script
    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

    // Initiate Google client SDK
    // const initClient = () => {
    //     window.gapi.load('auth2', function() {
    //         window.gapi.auth2.init({
    //             client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    //             scope: 'profile email'
    //         });
    //     });
    // };

  }, []);

  const Footer = () => { 
    const location = useLocation(); 
    const hasFooterMenu = location.pathname === '/'; 
    return hasFooterMenu ? <CMP.FooterMenu /> : null; 
  };


  return (
    <Router>
      <div className="App">

      <CMP.RefreshLogin show={showRefreshLogin} />

      {isLoggedIn && <CMP.InactivityCheck />}

        <ConstProvider>
          <header className="App-header">
            <div className="header-overflow"><CMP.Header /></div>
          </header>

          <Routes>
            {/* Public routes */}
            <Route element={<CMP.ConstructionRoute />}>
              <Route path="/" element={<Pages.Landing />} />
              <Route path="/login" element={<Pages.Login />} />
              <Route path="/register" element={<Pages.Register />} />
              
              {/* Intercepted routes */}
              <Route path='/class-enrol-student' element={<Pages.StudentEnrol />} />
              <Route path='/class-enrol-student/*' element={<CMP.InterceptRoute />} />
              <Route path='/form-landing' element={<Pages.FormLanding />} />
              <Route path="/form/:formCode" element={<Pages.DynamicForm />} />
              <Route path='/form/*' element={<CMP.InterceptRoute />} />

              {/* Under construction routes */}
              <Route path='/password-recovery' element={<Pages.PasswordRecovery />} />
              <Route path='/forms' element={<Pages.FormsLanding />} />
              <Route path='/upload' element={<Pages.UploadLanding />} />
              <Route path="/study/classroom(LIVE)" element={<Pages.ClassroomLive />} />
              <Route path="/employment" element={<Pages.Employment />} />
              <Route path="/portal/administration/setup" element={<Pages.Setup />} />
              <Route path="/portal/history" element={<Pages.History />} />
              <Route path="/files" element={<Pages.Files />} />
              <Route path="/cloud-storage" element={<Pages.Cloud />} />
              <Route path="/chat" element={<Pages.Chat />} />
              <Route path="/calendar" element={<Pages.Calendar />} />
              

              {/* Protected routes */}
                {/* <Route element={<CMP.ProtectedRoute requiredLevel={1} />}> */}
                  <Route path="/template" element={<Pages.Template />} />
                  <Route path="/contact" element={<Pages.Contact />} />
                  <Route path="/study" element={<Pages.Study />} />
                  <Route path="/study/classroom" element={<Pages.Classroom />} />
                  <Route path="/study/my-profile" element={<Pages.PersonalDetails />} />
                  <Route path="/study/diary" element={<Pages.Diary />} />
                  <Route path="/study/classroom/assessment" element={<Pages.Assessment />} />
                  <Route path="/competencies" element={<Pages.Competencies />} />
                  <Route path="/courses-training" element={<Pages.CoursesTraining />} />
                  <Route path="/event-viewer" element={<Pages.EventViewer />} />
                  <Route path="/feedback" element={<Pages.Feedback />} />
                {/* </Route> */}

            </Route>

            {/* Dynamic Routing for Forms */}
            {/* {oldWorkflowState?.currentForm && (
              <Route 
                path={generateFormRoute(oldWorkflowState.workflowName, oldWorkflowState.currentForm)} 
                element={<Pages.DynamicForm 
                  currentForm={oldWorkflowState.currentForm} 
                  formVersion={oldWorkflowState.currentFormVersion} 
                />} 
              />
            )} */}

            {/* Dev routes */}
           
            {/* Catch all routes to the 404 page when a page doesn't exist */}
            <Route path="*" element={<Pages.Error />} />
          </Routes>
          
          <Footer />

        </ConstProvider>
      </div>
    </Router>
  );
}

export default App;