import React from 'react';

interface FormLandingProps {}

const FormLanding: React.FC<FormLandingProps> = () => {
  console.log('We have landed ... boom')
  return (
    <div className='-container'>
     FormLanding component
    </div>
  );
};

export default FormLanding;