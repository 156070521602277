import React, { ReactNode, useEffect, useState } from "react";
import { FormQuestionLink, QuestionData } from "../../Types/FeedbackTypes";
import { TextBox, DatePickerComponent, MobileSignature } from "Components";
import { useUserField } from "CustomHooks";

import "../../Pages/Styles/feedback.scss";

interface QuestionProps extends QuestionData {
  // eslint-disable-next-line no-unused-vars
  handleResponseChange: (questionId: number, value: string) => void;
  showLabels: boolean;
  isFirstInGroup: boolean;
  value?: string;
}

const Question: React.FC<QuestionProps> = ({
  questionId,
  questionText,
  questionType,
  options,
  links,
  handleResponseChange,
  showLabels,
  isFirstInGroup,
  value,
}) => {
  const [dateValue, setDateValue] = useState<Date | null>(null);
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [showSignature, setShowSignature] = useState<boolean>(false);
  const name = useUserField("fullName");
  const trainerName = "Trainer Name"; // Replace with real state when available
  const employerName = "Employer Name"; // Replace with real state when available

  // Initialize local state only once
  const [localValue, setLocalValue] = useState<string>(() => {
    if (value) return value;
    switch (questionType) {
      case 115:
        return name || "";
      case 116:
        return trainerName || "";
      case 117:
        return employerName || "";
      default:
        return "";
    }
  });

  useEffect(() => {
    if (!value) {
      // Set the initial value once based on question type
      let initialValue = "";
      if (questionType === 115) {
        initialValue = name || ""; // Student/Candidate Name
      } else if (questionType === 116) {
        initialValue = trainerName || ""; // Trainer Name
      } else if (questionType === 117) {
        initialValue = employerName || ""; // Employer Name
      }
  
      // Only set the initial value if it hasn't been set yet
      setLocalValue(initialValue);
  
      // Sync with global state
      if (initialValue) {
        handleResponseChange(questionId, initialValue);
      }
    }
    // Run only once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  
  

  // Handle changes locally and sync to global state
  const handleChange = (newValue: string) => {
    setLocalValue(newValue); // Update local state
    handleResponseChange(questionId, newValue); // Sync with global state
  };


  const renderQuestionText = (
    questionText: string,
    links: FormQuestionLink[] | null,
    state: { studentName?: string; candidateName?: string; employerName?: string; trainerName?: string },
  ): ReactNode => {
  
    if (!links || links.length === 0) {
      return <span>{questionText}</span>;
    }
  
    const parts: ReactNode[] = [];
    let currentText = questionText;
  
    links.forEach((link) => {
      const linkText = link.linkText.trim();
      const matchIndex = currentText.indexOf(linkText);
  
      if (matchIndex !== -1) {
        if (matchIndex > 0) {
          parts.push(
            <span key={`${linkText}-before`}>
              {currentText.slice(0, matchIndex)}
            </span>
          );
        }
        parts.push(
          <span key={`${linkText}-match`}>
            {createLink(link.linkURL || "", linkText, link.linkType || 0, state)}
          </span>
        );
        currentText = currentText.slice(matchIndex + linkText.length);
      } else {
        console.warn(`No match found for '${linkText}'.`);
      }
    });
  
    if (currentText) {
      parts.push(
        <span key="remaining-text">
          {currentText}
        </span>
      );
    }

    return <>{parts}</>;
  };

  const getPlaceholderValue = (
    placeholderText: string, 
    state: {studentName?: string, candidateName?: string, employerName?: string, trainerName?: string})
    : string => {
    switch (placeholderText.toLowerCase()) {
      case "student name":
      case "candidate name":
        return state.studentName || "";
      case "employer name":
        return state.employerName || "";
      case "trainer name":
        return state.trainerName || "";
      default:
        return placeholderText;
    };
  };
  
  const createLink = (
    url: string, 
    linkText: string, 
    linkType: number,
    state: {studentName?: string, candidateName?: string, employerName?: string, trainerName?: string}
  ): ReactNode => {
    let link: ReactNode = <></>;

    const placeholderValue = getPlaceholderValue(linkText, state);

    switch (linkType) {
      // Hyperlink
      case 1:
        link = (
          <a 
            href={url} 
            target="_blank" 
            rel="noopener noreferrer" 
            className='link'
            style={{ textDecoration: "underline" }}
          >
              {linkText}
          </a>
        );
        break;

      // Document
      case 2:
        link = (
          <span 
            className='document-link' 
            style={{ textDecoration: url ? "underline" : 'none', textDecorationColor: '#955cce', cursor: url ? "pointer" : "default" }}
            onClick={() => {url && typeof url === "string" ?
                window.open(url, '_blank', 'noopener, norefferer')
                  :
                console.warn('No URL provided for document link: ', linkText);
              }}>
              {linkText}
          </span>
        );
        break;

      // Placeholder 
      case 10: 
        link = (
          <input
            className="placeholder-input"
            type = "text"
            value = {placeholderValue || "" }
            onChange={(e) => handleResponseChange(questionId, e.target.value)}
            placeholder={linkText}
          />
        );
        break;

      default:
        break;
    }
    return link;
  };

  const placeholderValue = value || name;

  const getMaxLength = (type: number) => {
    switch (type) {
      case 100: // Text (100 characters)
        return 100;
      case 101: // Text (250 characters)
        return 250;
      case 102: // Text (500 characters)
        return 500;
      case 150: // Number (Postcode)
        return 4;
      default:
        return 500;
    }
  };

  // Determine scale options based on questionType if applicable
  const scaleOptions =
  questionType >= 200 && questionType < 300 && questionType !== 201 && options && options.length > 0
    ? options
    : null;


  const handleCheckboxChange = (value: string) => {
    if (selectedValue === value) {
      setSelectedValue(null);
      handleResponseChange(questionId, "");
    } else {
      setSelectedValue(value);
      handleResponseChange(questionId, value);
    }
  };

  return (
    <div className={`question ${questionType === 401 || questionType === 410 ? '' : 'horizontal-layout'}`}>
      <div
        className="question-text"
        style={
          isFirstInGroup
            ? {
                alignSelf: "flex-end",
                paddingTop: window.innerWidth > 1000 ? "30px" : "70px",
                marginBottom: window.innerWidth > 1000 ? "50px" : "30px",
              }
            : undefined
        }
      >
        {!(questionType === 114 || questionType === 402) && 
          <p>
            {renderQuestionText(
              questionText, 
              links || null, 
                {
                  studentName: name, 
                  candidateName: name,
                  employerName: 'Employer Name',
                  trainerName: 'Trainer Name'
                })}
          </p>
        }

        {/* Text entry questions */}
        {(questionType === 100 || questionType === 101 || questionType === 102) && (
          <TextBox
            questionLength={getMaxLength(questionType)}
            value={value || ""}
            onChange={(value) => handleResponseChange(questionId, value)}
            style={{ width: "100%", marginTop: "10px" }}
          />
        )}

        {/* Phone number entry */}
        {questionType === 110 && (
          <input
            className="form-input"
            type="tel"
            maxLength={15}
            value={value || ""}
            onInput={(e) => {
              const input = e.target as HTMLInputElement;
              input.value = input.value.replace(/[^0-9+\-\s()]/g, "");
              handleResponseChange(questionId, input.value);
            }}
            style={{ width: "100%", marginTop: "10px" }}
          />
        )}

         {/* Address */}
         {(questionType === 113 || questionType === 114) && (
          <div className="address-input">
            {/* Address Line 1 */}
            {questionType === 113 && (
              <input
                className="form-input"
                type="text"
                value={value || ""}
                placeholder="Unit / Street Number"
                onChange={(e) =>
                  handleResponseChange(questionId, e.target.value)
                }
                style={{ width: "100%", marginBottom: "10px" }}
              />
            )}
            {/* Address Line 2 */}
            {questionType === 114 && (
              <input
                className="form-input"
                type="text"
                value={value || ""}
                placeholder="Street Name, Suburb, State, Postcode"
                onChange={(e) =>
                  handleResponseChange(questionId, e.target.value)
                }
                style={{ width: "100%" }}
              />
            )}
          </div>
        )}

        {/* Name entry */}
        {(questionType === 115 || questionType === 116 || questionType === 117) && (
            <input
            className="form-input"
            type="text"
            value={localValue}
            placeholder={
              questionType === 115
                ? "Enter student or candidate name"
                : questionType === 116
                ? "Enter trainer name"
                : questionType === 117
                ? "Enter employer name"
                : ""
            }
            onChange={(e) => handleChange(e.target.value)}
            style={{ width: "100%", marginTop: "10px" }}
          />
        )}


        {/* Cash entry */}
        {questionType === 120 && (
          <input
            className="form-input"
            type="text"
            value={`$ ${value || ""}`}
            onChange={(e) => {
              const input = e.target.value.replace(/[^0-9.]/g, "");
              let numericValue = input.replace(/(\..*?)\..*/g, "$1");
              if (numericValue.includes(".")) {
                const [whole, decimal] = numericValue.split(".");
                numericValue = `${whole}.${decimal.slice(0, 2)}`;
              }
              handleResponseChange(questionId, numericValue);
            }}
            onBlur={(e) => {
              const input = e.target.value.replace(/[^0-9.]/g, "");
              if (!input) {
                handleResponseChange(questionId, "");
              }
            }}
            style={{ width: "100%", marginTop: "10px" }}
          />
        )}


        {/* Single choice questions */}
        {questionType === 201 && options && (
          <select
            onChange={(e) => handleResponseChange(questionId, e.target.value)}
            value={value || ""}
            style={{ width: "100%", marginTop: "10px" }}
          >
            <option value="" disabled>
              Select an option
            </option>
            {options.map((option) => (
              <option key={option.optionId} value={option.optionId.toString()}>
                {option.optionText}
              </option>
            ))}
          </select>
        )}

        {/* Date Entry */}
        {questionType >= 301 && questionType <= 303 && (
          <DatePickerComponent
            selectedDate={dateValue || (value ? new Date(value) : null)}
            onDateChange={(date) => {
              setDateValue(date);
              handleResponseChange(
                questionId,
                date ? date.toISOString().slice(0, 7) : ""
              );
            }}
            hideDays={questionType !== 303 } // Hide days for `YYYY`
            hideMonths={questionType === 301} // Hide months for `YYYY`
            placeholderText={
              questionType === 301
                ? "YYYY"
                : questionType === 302
                ? "MM/YYYY"
                : "DD/MM/YYYY"
            }
          />
        )}

        {/* Number Entry */}
        {questionType === 150 && (
          <input
            className="form-input"
            type="number"
            value={value || ""}
            maxLength={getMaxLength(questionType)}
            onInput={(e) => {
              const input = e.target as HTMLInputElement;
              if (input.value.length > getMaxLength(questionType)) {
                input.value = input.value.slice(0, getMaxLength(questionType));
              }
              handleResponseChange(questionId, input.value);
            }}
            style={{ width: "80px", marginTop: "10px" }}
          />
        )}
      </div>

      {/* Consent + T's and C's */}
      {questionType === 401 && (
        <div>
          <ul className='consent-list'>
            {options?.map((option) => (
              <li className='consent-list-item' key={option.optionId}>{option.optionText}</li>
            ))}
          </ul>
          <label>
            <input
              className="radio-as-checkbox"
              type="checkbox"
              checked={value === "1"}
              onChange={(e) =>
                handleResponseChange(questionId, e.target.checked ? "1" : "")
              }
            />
            <span>Yes, I agree</span>
          </label>
        </div>
      )}

      {/* Disclaimer / Fine print */}
      {questionType === 402 && (
        <div className='disclaimer'>
          {questionText}
        </div>
      )}

      {/* Signature */}
      {questionType === 410 && (
        <MobileSignature
          showCanvas={showSignature}
          onSave={(signature) => handleResponseChange(questionId, signature)}
        />
      )}

      {/* Scale questions */}
      {scaleOptions && (
        <div className="question-options">
          {/* Horizontal variants for Yes/No or Gender */}
          {questionType === 203 || questionType === 251 ? (
            <div className="scale-radio-options-horizontal">
              {scaleOptions.map((option) => (
                <label
                  htmlFor={`option-${questionId}-${option.optionId}`}
                  key={option.optionId}
                  className="scale-option-horizontal"
                >
                  <input
                    className="radio-as-checkbox"
                    type="checkbox"
                    id={`option-${questionId}-${option.optionId}`}
                    name={`question-${questionId}`}
                    value={option.optionId}
                    checked={value === option.optionId.toString()}
                    onChange={() => handleCheckboxChange(option.optionId.toString())}
                  />
                  <span>{option.optionText}</span>
                </label>
              ))}
            </div>
          ) : (
            <>
              {/* Only show labels if `showLabels` is true (first in the group) */}
              {showLabels && (
                <div className="scale-labels">
                  {scaleOptions.map((option) => (
                    <span
                      key={`label-${option.optionId}`}
                      className="scale-label"
                    >
                      {option.optionText}
                    </span>
                  ))}
                </div>
              )}
              <div className="scale-radio-options">
                {scaleOptions.map((option) => (
                  <label
                    htmlFor={`option-${questionId}-${option.optionId}`}
                    key={option.optionId}
                    className="scale-option"
                  >
                    <input
                      className="radio-as-checkbox"
                      type="checkbox"
                      id={`option-${questionId}-${option.optionId}`}
                      name={`question-${questionId}`}
                      value={option.optionId.toString()}
                      checked={value === option.optionId.toString()}
                      onChange={() => handleCheckboxChange(option.optionId.toString())}
                    />
                    <span></span> {/* Styling for checkbox appearance */}
                  </label>
                ))}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Question;
