import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CertCarousel from '../Components/CertCarousel';
import logError from "../Components/Logger";
import CustomAlert from "../Components/CustomAlert"; 
import { useUserField, useStudentHistoryDeets, useFetchApi, useWorkflow } from 'CustomHooks';

// For testing - replace with fetch when endpoint is ready
import workflowData from "../TestingDataObjects/workflow.json";

export default function Study() {
    const [msg, setMsg] = useState(window.sessionStorage.getItem("message"));
    const [msgCode, setMsgCode] = useState(0);
    const [loading, setLoading] = useState(true);
    const roleId = useUserField('id');

    const studentHistory = useStudentHistoryDeets();

    useEffect(() => {
        if (!studentHistory || studentHistory.length === 0) {
            console.log('Waiting on student history');
            // setLoading(false);
            // logError({
            //     page: window.location.pathname,
            //     userId: studentID,
            //     errorDesc: 'No student information to display.'
            // }).then(response => {
            //     console.log('Server responded with:', response);
            // }).catch(err => {
            //     console.error('Error logging failed:', err);
            //     setMsg('Failed to communicate with server');
            //     setMsgCode(4);
            // });
        } else {
            setLoading(false);
        }
    }, [studentHistory, roleId]);


    return (
        <div>
            <CustomAlert msg={msg} msgCode={msgCode} />
            <div className={"page-common"} style={{ marginTop: 100, zIndex: 1 }}>
                {loading ? (
                    <div className="spinner"></div>
                ) : (
                    studentHistory && studentHistory.length > 0 ? (
                        <CertCarousel items={[...studentHistory].reverse()} />
                    ) : (
                        <div>No student history could be found</div>
                    )
                )}
            </div>
        </div>

    );
}

// export default function Study() {
//     const [msg, setMsg] = useState(window.sessionStorage.getItem("message"));
//     const [msgCode, setMsgCode] = useState(0);
//     const [loading, setLoading] = useState(true);
//     const navigate = useNavigate();
//     const isLoggedIn = useUserField('loggedIn');
//     const studentID = useUserField('id');
//
//     // Get the student history directly from Redux state
//     const studentHistory = useStudentHistoryDeets();
//
//     useEffect(() => {
//         // Simulate loading to isolate rendering logic
//         if (!studentHistory || studentHistory.length === 0) {
//             logError({
//                 page: window.location.pathname,
//                 userId: studentID,
//                 errorDesc: 'No student information to display.',
//             })
//                 .then(response => {
//                     console.log('Server responded with:', response);
//                 })
//                 .catch(err => {
//                     console.error('Error logging failed:', err);
//                     setMsg('Failed to communicate with server');
//                     setMsgCode(4);
//                 })
//                 .finally(() => {
//                     setLoading(false); // Always stop loading after processing
//                 });
//         } else {
//             setLoading(false); // Student history exists
//         }
//     }, [studentHistory, studentID]);
//
//     return (
//         <div>
//             <CustomAlert msg={msg} msgCode={msgCode} />
//             <div className={"page-common"} style={{ marginTop: 100, zIndex: 1 }}>
//                 {/* Strict loading condition */}
//                 {loading ? (
//                     <div className="spinner"></div>
//                 ) : (
//                     // Render student history or fallback only after loading completes
//                     studentHistory && studentHistory.length > 0 ? (
//                         <CertCarousel items={[...studentHistory].reverse()} />
//                     ) : (
//                         <div>No student history could be found</div>
//                     )
//                 )}
//             </div>
//         </div>
//     );
// }
