import React from 'react';

import './Styles/contactDetails.scss';

interface ContactDetailsProps {
  mobile?: boolean;
}

const ContactDetails: React.FC<ContactDetailsProps> = ({ mobile = false }) => {
  switch (mobile) {
    case true:
      return (
        <div className='contact-details-container'>
          <div className='contact-details'>
            <p style={{fontWeight: 'bold'}}>ABN</p>
            <p style={{fontWeight: 'bold'}}>ACN</p>
            <p style={{fontWeight: 'bold'}}>RTO Number</p>
          </div>
          <div className='contact-details'>
            <p>89 135 298 510</p>
            <p>135 298 510</p>
            <p>31955</p>
          </div>
        </div>
      );
    default:
      return (
        <div className='contact-details-container'>
          <div className='contact-details'>
            <p>Training Professionals Pty Ltd</p>
            <p>ABN: 89 135 298 510</p>
            <p>ACN: 135 298 510</p>
            <p>RTO Number: 31955</p>
          </div>
        </div>
      );
  }
};

export default ContactDetails;