import React, { useCallback, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { FaCamera, FaTimesCircle, FaCircle } from 'react-icons/fa';

import './Styles/camera.css';
import { set } from 'date-fns';

interface CameraComponentProps {
  onCapture: (imageSrc: string) => void;
}

const CameraComponent: React.FC<CameraComponentProps> = ({ onCapture }) => {
  const webcamRef = useRef<Webcam>(null);
  const [isCameraAvailable, setIsCameraAvailable] = useState(false);

  const capture = useCallback(() => {
    if (isCameraAvailable && webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot(); 
      if (imageSrc) {
        onCapture(imageSrc);
      }
    } else {
      onCapture('https://via.placeholder.com/300');
    }
  }, [isCameraAvailable, onCapture]);

  return (
    <div className='camera-container'>
      {/* Conditionally display the camera or a placeholder */}
      <div className='camera-feed' style={{ display: isCameraAvailable ? 'block' : 'none' }}>
        {isCameraAvailable ? (
          // If testing and no camera is available, show a placeholder
          <div>
            <img src="https://via.placeholder.com/200" alt="Mock Camera Feed" />
          </div>
        ) : (
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={{
              facingMode: 'environment', // Rear camera
            }}
            style={{ width: '100%', height: 'auto', borderRadius: '6px' }}
          />
        )}
      </div>

      {/* Button controls */}
      <div className='camera-buttons'>
        <button style={{display: isCameraAvailable ? 'block' : 'none'}} className='btn-icon capture' onClick={capture}>
          <FaCircle />
        </button>
        <button className='btn-icon camera' onClick={() => setIsCameraAvailable(!isCameraAvailable)}>
          {!isCameraAvailable ? <FaCamera /> : <FaTimesCircle />}
        </button>
      </div>
      <p>{isCameraAvailable ? 'Click to capture' : 'Needs a photo?'}</p>
    </div>
  );
};

export default CameraComponent;
