import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../State/Reducers';
import { setName, setSignature, setDate } from '../State/Reducers/signatureSlice';

export const useSignature = () => {
  const dispatch = useDispatch();
  const { name, signature, date } = useSelector((state: RootState) => state.signature);

  const updateName = (newName: string) => {
    dispatch(setName(newName));
  };

  const updateSignature = (signatureDataUrl: string | null) => {
      if (signatureDataUrl !== null) {
        dispatch(setSignature(signatureDataUrl));
      }
  };

  const updateSelectedDate = (date: string | null) => {
    if (date !== null) {
      dispatch(setDate(date));
    }
  };

  const clearSignature = () => {
    dispatch(setName(''));
    dispatch(setSignature(''));
    dispatch(setDate(''));
  };

  return {
    name,
    signature,
    date,
    updateName,
    updateSignature,
    updateSelectedDate,
    clearSignature,
  };
};
