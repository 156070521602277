import { useEffect, useState} from "react";
import { BuilderComponent, builder, useIsPreviewing } from "@builder.io/react";
import { useNavigate } from 'react-router-dom';
// import MassUpdate from "../Components/MassUpdate";

// Redux
import { useUserField } from "CustomHooks";

// Put your API key here
builder.init('9992ee9d879a492ebbc6238da3ea846f');



// set whether you're using the Visual Editor,
// whether there are changes,
// and render the content if found
export default function CatchAllRoute() {
    const isLoggedIn = useUserField('loggedIn');
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/login');
        }
    }, [isLoggedIn, navigate]);

    const isPreviewingInBuilder = useIsPreviewing();
    const [notFound, setNotFound] = useState(false);
    const [content, setContent] = useState(null);

    // get the page content from Builder
    useEffect(() => {
        async function fetchContent() {
            const content = await builder
                .get("landing-page", {
                    url: window.location.pathname
                })
                .promise();

            setContent(content);
            setNotFound(!content);

            // if the page title is found,
            // set the document title
            if (content?.data.title) {
                document.title = content.data.title
            }
        }
        fetchContent();
    }, []);

    // If no page is found, return
    // a 404 page from your code.
    // The following hypothetical
    // <FourOhFour> is placeholder.
    if (notFound && !isPreviewingInBuilder) {
        return(
            <>
                Page not found
            </>
        )
    }

    // return the page when found
    return (
        <>
            {/* Render the Builder page */}
            <BuilderComponent model="landing-page" content={content} />

            {/*<MassUpdate />*/}

        </>
    );
}

// =============================== To Do =================================== //

// 9992ee9d879a492ebbc6238da3ea846f

// =============================== Bugs =================================== //




