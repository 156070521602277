import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stamp, Tooltip } from "./";
import { useUserField } from 'CustomHooks';

import './Styles/toDoCard.scss';

interface ToDoCardProps {
  content: string;
  link: string;
  priority: number;
  status: 'NOT_STARTED' | 'IN_PROGRESS' | 'COMPLETE' | null;
  complete: 0 | 1;
  allPriorities?: number[];
}

const ToDoCard: React.FC<ToDoCardProps> = ({ content, link, priority, status, complete, allPriorities = [] }) => {
  const navigate = useNavigate(); 
  const isLoggedIn = useUserField('loggedIn');
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const tooltipContent = [
    <div>
      <div style={{ color: '#955cce', fontWeight: '600' }}>"{content}"</div>
       <div>is currently waiting for approval by TP ... </div>
       <div>we will contact you if anything further is required!</div>
    </div>
  ]

  const handleClick = (e: any) => {
    e.preventDefault();
  
    if (status === 'IN_PROGRESS') {
      console.log(`The task ${content} currently waiting for approval by TP ... we will contact you if anything further is required!`);
      setIsOpen(true);
    } else if (status === 'COMPLETE' || complete === 1) {
      console.error('uh oh we should not be seeing this in the to do list anymore');
    } else {
      // Check if the link is an absolute URL (external)
      if (link.startsWith("http")) {
        window.location.href = link; // Open external links in a new page
      } else if (link.toLowerCase().includes('data') && !isLoggedIn) {
        console.warn('I need to build this out so that when the user is !loggedIn the login modal is triggered requesting a login before any form is rendered ... Do nothing for now we will come back and fix this!')
      } else {
        navigate(link); // Internal link → Use React Router
      }
    }
  };
  

  if (priority <= 0 || (status === 'COMPLETE' && complete === 1)) {
    return null;  
  }

  // Sort priorities (highest first) and find critical/high/medium thresholds
  const sortedPriorities = [...new Set(allPriorities)].sort((a, b) => b - a);
  const criticalThreshold = sortedPriorities[0]; // Highest priority
  const midThreshold = sortedPriorities[Math.floor(sortedPriorities.length / 2)] || criticalThreshold;
  
  let priorityClass = 'priority-low';

  if (priority > 100) {
    if (priority >= criticalThreshold) {
      priorityClass = 'priority-critical';
    } else if (priority >= midThreshold) {
      priorityClass = 'priority-high';
    } else {
      priorityClass = 'priority-medium';
    }
  }

  return ( 
    <div className={`to-do-container ${priorityClass} ${status === 'IN_PROGRESS' ? 'in-progress' : ''}`}>
      {status === 'IN_PROGRESS' && 
        <Stamp content='In Progress'/>
      }
      <div className={`to-do-card ${priorityClass}  ${status === 'IN_PROGRESS' ? 'in-progress' : ''}`} onClick={handleClick}>
        {content}
      </div>
        {status === 'IN_PROGRESS' &&
          <Tooltip
            setIsOpen={setIsOpen} 
            isOpen={isOpen} 
            content={tooltipContent}
          />
        }
    </div>
  );
};

export default ToDoCard;


