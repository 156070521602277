import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../State/store";
import { setWorkflowDataAsync, clearWorkflowData } from "../State/Reducers/workflowSlice"; 
import { RootState } from "../State/Reducers";

export const useWorkflow = (initialWorkflows?: any[]) => {
  const dispatch = useDispatch<AppDispatch>();
  const workflows = useSelector((state: RootState) => state.workflow.workflows);
  const isLoading = useSelector((state: RootState) => state.workflow.isLoading);

  const setWorkflowData = (data: any[]) => {
    dispatch(setWorkflowDataAsync(data));
  };

  const clearWorkflow = () => {
    dispatch(clearWorkflowData());
  };

  useEffect(() => {
    if (initialWorkflows && initialWorkflows.length > 0) {
      setWorkflowData(initialWorkflows);
    }
  }, [dispatch, initialWorkflows?.map(workflow => JSON.stringify(workflow)).join(',')]);
  

  return { workflows, isLoading, setWorkflowData, clearWorkflow };
};
