// IMPORTANT: Use this component to embed audio in the app.
// It is setup to track user activity for audio playback.
// This allows us to prevent the user from being logged out while listening to audio.

import React, { useRef } from 'react';
import { useActivityLogger } from '../CustomHooks';

interface AudioPlayerProps {
  src: string;
  controls?: boolean;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ src, controls = true }) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useActivityLogger({ audioRef });

  return (
    <div>
      <audio ref={audioRef} controls={controls}>
        <source src={src} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

export default AudioPlayer;
