import React from 'react';
import Question from './Question';
import { GroupData, QuestionData } from "../../Types/FeedbackTypes";
import SpacerDiv from 'Components/SpacerDiv';
import { useForm } from "CustomHooks";

interface GroupProps {
  groupData: GroupData;
  // eslint-disable-next-line no-unused-vars
  handleResponseChange: (questionId: number, value: string) => void;
  totalQuestions: number;
  answeredQuestions: number;
  allCompulsoryQuestionsAnswered?: boolean;
  currentGroupIndex?: number; 
}

const Group: React.FC<GroupProps> = ({ 
  groupData, 
  handleResponseChange, 
  totalQuestions, 
  answeredQuestions, 
}) => {
  const { getGroupData } = useForm();
  const groupId = groupData.groupId;
  const responses = getGroupData(groupId) || {};
  const progress = Math.round((answeredQuestions / (totalQuestions - 1)) * 100);

  const groupName = (groupData.groupName || '')
    .replace(/_/g, ' ')
    .replace(/\b\w/g, (char: string) => char.toUpperCase())
    .replace(/\d+/g, '');

  // Declare `previousQuestionType` outside the map function
  let previousQuestionType: number | null = null;

  return (
    <div className="module">
      <SpacerDiv rem={0.5} />

      <div className='module-header'>{groupName}</div>

      <div className="progress-bar-container">
        {progress === 0 ? (
          <div className='progress-zero'>% COMPLETE</div>
        ) : (
          <div
            className="progress-bar"
            style={{ width: `${progress}%` }}
          >
            {progress === 100 ? 'COMPLETED' : (progress >= 8 ? `${progress}%` : '')}
          </div>
        )}
      </div>

      <SpacerDiv rem={1.5} />
      {groupData.questions.map((question: QuestionData, index: number) => {
        // Determine if this question starts a new question type group
        const showLabels = index === 0 || question.questionType !== previousQuestionType;

        // Update previousQuestionType to the current question's type for comparison in next iteration
        previousQuestionType = question.questionType;

        // Check if this is the first question in the group for specific logic (optional condition)
        const isFirstInGroup = index === 0 && question.questionType >= 210 && question.questionType <= 220;


        return (
          <div key={question.questionId}>
            <Question
              {...question}
              handleResponseChange={handleResponseChange}
              showLabels={showLabels}
              isFirstInGroup={isFirstInGroup}
              value={responses[question.questionId]?.response || ''} 
             />
            {!(question.questionType === 113 || question.questionType === 402) && <div className='horizontal-divider' style={isFirstInGroup ? { marginTop: '-30px' } : { marginTop: '20px' }}/>}
          </div>
        );
      })}
    </div>
  );
};

export default Group;
