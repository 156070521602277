import React from "react";

interface SpacerDivProps {
  rem?: number;
}

const SpacerDiv: React.FC<SpacerDivProps> = ({ rem: height = 2 }) => (
  <div style={{ height: `${height}rem` }}></div>
);

export default SpacerDiv;