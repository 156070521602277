import React, { ReactNode, useEffect } from 'react';
import './Styles/tooltip.scss';

interface TooltipProps {
  content: string | ReactNode;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;  // Accept setIsOpen function
  styles?: React.CSSProperties;
  className?: string;
}

const Tooltip: React.FC<TooltipProps> = ({ 
  content = 'Did you forget to put something here?', 
  isOpen, 
  setIsOpen,  // Now we can call this to close tooltip
  styles, 
  className 
}) => {

  useEffect(() => {
    if (isOpen) {
      const timeout = setTimeout(() => {
        setIsOpen(false);  // Properly close the tooltip via state
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [isOpen, setIsOpen]);  // Depend on setIsOpen

  return (
    <div>
      {isOpen && (
        <div className={`tooltip-container ${className}`} style={{ ...styles }}>
          <div>
            {content}
          </div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
