// NOTE: For this to work correctly with the form workflow we need another fetch here to get the next workflow step after a form is submitted and the next form is available so that when we get to the last form we can redirect to the next workflow step.

// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Alert, Group, MobileModal } from "Components";
import { GroupData, QuestionType } from "Types/FeedbackTypes";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, useFetchApi, useUserField } from "CustomHooks";
import { useDispatch, useSelector } from "react-redux";
import { generateFormRoute } from "Utils/generateRoute";
import { setOldWorkflowData } from "State/Reducers/oldWorkflowSlice";

import '../../Pages/Styles/feedback.scss';

interface FormProps {
  formData: any;
}

const Form: React.FC<FormProps> = ({ formData }) => {
  const navigate = useNavigate();
  const location = useLocation(); 
  const dispatch = useDispatch();
  const workflowData = useSelector((state: any) => state.workflow.workflowData);
  const user_id = useUserField('user_id');
  const accessLevel = useSelector((state: RootState) => state.user.accessLevel);
  // const fetchApi = useFetchApi();
  const Data = formData;
  const { getGroupData, updateGroupData, resetForm } = useForm();
  const [optOut, setOptOut] = useState<boolean>(false);
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);
  const [msg, setMsg] = useState<string>('');
  const [msgCode, setMsgCode] = useState<0 | 1 | 2 | 3 | 4>(0);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [showCompletionPopup, setShowCompletionPopup] = useState<boolean>(false);
  const isTrainerOrAbove = (accessLevel ?? 0) >= 4;

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const groupsData: GroupData[] = Data.groups.map((group: GroupData) => ({
    groupId: group.groupId,       
    groupName: group.groupName,  
    target: group.target,  
    questions: group.questions.map(question => ({
      questionId: question.id,
      questionText: question.question,
      compulsoryFlag: question.compulsoryFlag,
      questionType: question.questionType as QuestionType,
      links: question.links || null,
      options: question.options || null
    }))
  }));
  

  
  // This needs to be qType 400 (this is the LeadIn component) not hardcoded here
  const title = "IMPORTANT INSTRUCTIONS";
  const instructions = [
    <div key="instruction-1">
      <p>Please tell us about your training. Your feedback plays an important role in developing the quality of your education.</p>
      <p>In this questionnaire, the term 'training' refers to learning experiences with your training organisation. The term 'trainer' refers to trainers, teachers, lecturers, or instructors from your training organisation.</p>
      <p>Provide one response to each item on the form. Leave the box blank if the statement does not apply.</p>
    </div>
  ];

  const handleResponseChange = (questionId: number, value: string) => {
    const currentGroupId = groupsData[currentGroupIndex]?.groupId;
  
    if (!currentGroupId) {
      console.error('Error: Group ID is undefined');
      return;
    }
  
    const currentGroup = groupsData.find(group => group.groupId === currentGroupId);
  
    if (!currentGroup) {
      console.error(`Error: Group with ID ${currentGroupId} not found.`);
      return;
    }
  
    // Find the index of the question in the current group
    const questionIndex = currentGroup.questions.findIndex(q => q.questionId === questionId);
  
    if (questionIndex !== -1) {
      const question = currentGroup.questions[questionIndex];
      // Determine if the response should be stored as an optionId or the value itself
      let responseValue = value;
  
      if (question.options) {
        const selectedOption = question.options.find(option => option.optionText === value);
        if (selectedOption) {
          responseValue = selectedOption.optionId.toString();
        }
      }
  
      updateGroupData(currentGroupId.toString(), {
        questionId: question.questionId.toString(),
        qIndex: questionIndex,
        qType: question.questionType as number,
        response: responseValue,
        compulsory: question.compulsoryFlag,
      });
    } else {
      console.error(`Question with ID ${questionId} not found in group ${currentGroupId}.`);
    }
  };
  
  // Determines if all compulsory questions in the current group have been answered and allows the user to proceed
  // Determines if all compulsory questions in the current group have been answered
const [allCompulsoryQuestionsAnswered, setAllCompulsoryQuestionsAnswered] = useState<boolean>(false);

// Get the current group ID and responses using the group index
const currentGroupId = groupsData[currentGroupIndex]?.groupId;
const responses = getGroupData(currentGroupId) || {};

const checkAllCompulsoryQuestionsAnswered = () => {
  const currentGroup = groupsData[currentGroupIndex];

  // Exit early if the group doesn't exist
  if (!currentGroup) return false;

  // Filter compulsory questions and check their responses
  return currentGroup.questions
    .filter((q) => q.compulsoryFlag)
    .every((q) => responses[q.questionId]?.response !== undefined && responses[q.questionId]?.response !== '');
};

// Effect to re-check whenever responses or currentGroupIndex change
useEffect(() => {
  setAllCompulsoryQuestionsAnswered(checkAllCompulsoryQuestionsAnswered());
}, [responses, currentGroupIndex]); // Watch for changes in `responses` or `currentGroupIndex`


  const handleNextWorkflowStep = async () => {
    // Update the workflow data with the next form
    const updatedWorkflowData = {
      ...workflowData,
      currentForm: workflowData.nextForm, // Set currentForm to the nextForm
      nextFormAvailable: false, // Reset nextFormAvailable until new data is fetched
    };
  
    // Dispatch the updated workflow data
    await dispatch(setOldWorkflowData(updatedWorkflowData));
  
    // Navigate to the route of the new form
    const route = generateFormRoute(
      updatedWorkflowData.workflowName,
      updatedWorkflowData.currentForm
    );
    navigate(route);  
    // TODO: Fetch updated workflow data here and dispatch it to the store
    // That way we are ready for the next step when the user completes the current form
  };

  const handleSubmitCurrentGroup = async () => { 
    const currentGroupId = groupsData[currentGroupIndex]?.groupId; // Current Group ID
    const currentGroupVersion = groupsData[currentGroupIndex]?.version || "0";
    const responses = getGroupData(currentGroupId) || {}; // Fetch responses for current Group ID
  
    // Map responses to the expected format
    const responsesArray = Object.entries(responses).map(([questionId, data]) => ({
      questionIndex: data.qIndex,
      questionId: Number(questionId),
      questionType: data.qType,
      response: data.response
    }));
  
    // Prepare data for API submission
    const jsonData = {
      user_id: user_id.toString(),
      total: totalQuestions,
      groupId: currentGroupId,
      version: currentGroupVersion,
      responses: responsesArray
    };
  
    try {
      // Simulate API call
      setMsg("Your feedback has been submitted successfully.");
      setMsgCode(0);
    } catch (error) {
      console.error("Submission failed:", error);
      setMsg("An error occurred. Please try again.");
      setMsgCode(4);
      return; // Exit early on failure
    }
  
    // Navigate to the next group using the correct groupId
    if (currentGroupIndex < groupsData.length - 1) {
      const nextGroupId = groupsData[currentGroupIndex + 1]?.groupId;
      console.log('Navigating to Next Group ID:', nextGroupId);
      setCurrentGroupIndex(prev => prev + 1); // Safely update the index
    } else {
      completeWorkflow();
    }
  };
  
  
  // Helper function to complete the workflow
  const completeWorkflow = () => {
    resetForm(); // Clear the form state
  
    // If there's a next form in the workflow, show the completion popup
    if (workflowData.nextForm && workflowData.nextFormAvailable) {
      setShowCompletionPopup(true);
    } else {
      setMsg("Success! Step completed. Returning to the previous page.");
      navigate(-1); // Go back to the previous page
    }
  };
  

  const isLastGroup = currentGroupIndex === groupsData.length - 1;
  const isFirstGroup = currentGroupIndex === 0;

  const totalAnsweredQuestions = groupsData.reduce((acc, group, index) => {
    const groupResponses = getGroupData(index + 1) || {};
    const answeredInGroup = Object.values(groupResponses).filter(response => response?.response && response.response !== '').length;
    return acc + answeredInGroup;
  }, 0);
  
  const totalQuestions = groupsData.reduce(
    (acc, group) => acc + group.questions.length,
    0
  );

  const handleOptOutChange = () => {
    const newOptOutValue = !optOut; 
    setOptOut(newOptOutValue);
    
    if (newOptOutValue) {
      resetForm();
      navigate('/');
    }
  };

  useEffect(() => {
    return () => {
      resetForm();
    };
  }, [location]);

  return (
    <div className="feedback-container">
      <Alert msg={msg} msgCode={msgCode}/>

      <MobileModal 
        isOpen={showPopup} 
        withButton={false} 
        heading='Assessment Complete'
        children={
          <>
            <p>Please take a few minutes to provide us with some feedback of your experiences.</p>
            <p>This is your chance to help improve the quality of your education.</p>
          </>
        }
      />

      <MobileModal 
        isOpen={showCompletionPopup} 
        withButton={false} 
        heading='Success! Your have completed this step.'
        children={
          <>
            <p>Click below to start the next step now ...</p>
            <div style={{ display: 'flex', gap: '10px'}}>
              <button
                className='btn-mobile'
                onClick={() => navigate(-1)}
              >
                Go Back
              </button>
              <button
                className='btn-mobile'
                onClick={handleNextWorkflowStep}
              >
                Next
              </button>
            </div>
          </>
        }
      />

      {/* {isFirstGroup &&
        <LeadIn
          title={title}
          description={instructions}
        />
      } */}

      <h3 className='form-name-heading'>{Data.formName}</h3>

      <Group
        groupData={groupsData[currentGroupIndex]}
        handleResponseChange={handleResponseChange}
        totalQuestions={totalQuestions}
        answeredQuestions={totalAnsweredQuestions}
        allCompulsoryQuestionsAnswered={allCompulsoryQuestionsAnswered}
        currentGroupIndex={currentGroupIndex}
      />

      <div className='feedback-buttons-container'>
        {!isFirstGroup && 
          <button
            className='btn-primary'
            onClick={() => setCurrentGroupIndex(currentGroupIndex - 1)}
          >
            Previous
          </button>}
          <button
            className='btn-primary'
            onClick={handleSubmitCurrentGroup}
            disabled={!allCompulsoryQuestionsAnswered}
          >
            {isLastGroup ? 'Finish' : 'Next'}
          </button>
      </div>
      {!allCompulsoryQuestionsAnswered &&
        <p className='important-text'>Questions in this colour are required</p>
      }
    {isTrainerOrAbove &&   
      <div className="form-details" style={{ justifyContent: 'space-between'}}>
        <span>{`Form Number: ${Data.formCode}-${String(Data.version).padStart(3, '0')}`}</span>
        <span>{`Approved: ${Data.authorisationDate.split('T')[0]}`}</span>
      </div>}
    {/* This should also be a qType it will be a new one  */}
      {/* <div style={{ height: '20px'}}>
        <label
          htmlFor='opt-out-checkbox'
        >
          <input
            className="radio-as-checkbox subtle"
            type="checkbox"
            id='opt-out-checkbox'
            name='opt-out'
            value='opt-out'
            checked={optOut}
            onChange={() => handleOptOutChange()}
          />
          <span className="subtle-text">Skip providing feedback</span>
        </label>
      </div> */}
    </div>
  );
};

export default Form;
