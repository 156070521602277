import React from 'react';

interface HistoryProps {}

const History: React.FC<HistoryProps> = () => {
  return (
    <div className='-container'>
     History component
    </div>
  );
};

export default History;