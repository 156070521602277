import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { MessageBox, MessageList, Input, Button } from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';
import Bluey from "Components/Bluey";
import useFetchApi from "Services/FetchApi";
import { useUserDeets } from "CustomHooks";
import { Alert as CustomAlert } from "Components";
import './ClassroomLive.css';
import { useDropzone } from 'react-dropzone';
import { FiUpload, FiSend } from 'react-icons/fi';
import io from 'socket.io-client';

// Redux
// import { useUserField } from 'CustomHooks';

export default function ClassroomLive() {

    const navigate = useNavigate();
    const location = useLocation();
    const fetchApi = useFetchApi();
    const user = useUserDeets();
    const socketRef = useRef(null);
    const stateData = location.state ? location.state : null;
    const environment = process.env.REACT_APP_ENV;
    const [sharedFiles, setSharedFiles] = useState([]);
    const [alertMessage, setAlertMessage] = useState("");

    const [classId] = useState(stateData.classId);
    const [certName] = useState(stateData.certName);
    const [studentId] = useState(user.roleId);
    const [showPopup, setShowPopup] = useState(false);
    const [fileToUpload, setFileToUpload] = useState(null);
    const [viewMode, setViewMode] = useState('list'); // Default to list view
    const [studentList, setStudentList] = useState([]);
    const [classTitle, setClassTitle] = useState('');
    const [time, setTime] = useState('');
    const [msg, setMsg] = useState(window.sessionStorage.getItem("message"));
    const [msgCode, setMsgCode] = useState(0);
    const [isWhiteboardExpanded, setWhiteboardExpanded] = useState(false);

    const [messages, setMessages] = useState([]); // Store all messages
    const [newMessage, setNewMessage] = useState(''); // Input message
    const [isPrivate, setIsPrivate] = useState(false); // Toggle private messaging

    const toggleWhiteboard = () => {
        setWhiteboardExpanded(!isWhiteboardExpanded);
    };

    const server = () => {
        let api;

        switch (environment) {
            case 'production':
                api = 'https://api.trainingprofessionals.com.au';
                break;
            case 'development':
                api = 'https://10.1.10.49:5001';
                break;
            default:
                api = 'https://localhost:3001';
        }

        return api;
    }

    // Establish WebSocket connection
    useEffect(() => {
        const socketServerUrl = server();
        socketRef.current = io(socketServerUrl, {
            withCredentials: true,
            transports: ['websocket'],
        });

        socketRef.current.on('connect', () => console.log('WebSocket connected'));

        socketRef.current.on('receive-message', (message) => {
            setMessages((prev) => [...prev, message]);
        });

        socketRef.current.on('error', (error) => console.error('WebSocket error:', error));

        return () => socketRef.current.disconnect();
    }, []);

    useEffect(() => {
        if (classId) {
            fetchClassDetails(classId); // Only runs when classId changes
        }
    }, [classId]);

    useEffect(() => {

        updateTime(); // Initial time update

        const timeInterval = setInterval(updateTime, 15000); // Update time every 15 seconds
        const filesInterval = setInterval(fetchFiles, 60000); // Refresh files every 60 seconds

        return () => {
            clearInterval(timeInterval);
            clearInterval(filesInterval);
        };
    }, []); // Runs only on mount

    // Send a message (either group or private)
    const sendMessage = () => {
        if (!newMessage.trim()) return;

        const messageData = {
            text: newMessage,
            date: new Date(),
            position: isPrivate ? 'right' : 'left',
            sender: isPrivate ? 'trainerName' : 'Group',
        };

        socketRef.current.emit('send-message', {
            ...messageData,
            isPrivate,
        });

        setMessages((prev) => [...prev, messageData]);
        setNewMessage('');
    };

    // Function to format and update the time
    const updateTime = () => {
        const now = new Date();
        let hours = now.getHours();
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM'; // Determine AM/PM

        // Convert hours to 12-hour format
        hours = hours % 12 || 12;

        setTime(`${hours}:${minutes} ${ampm}`);
    };

    const getCustomIconForFileType = (fileName) => {
        const extension = fileName.split(".").pop().toLowerCase(); // Extract file extension

        const iconMap = {
            pdf: "/icons/pdf.png",
            doc: "/icons/doc.png",
            docx: "/icons/doc.png",
            xls: "/icons/excel.png",
            xlsx: "/icons/excel.png",
            ppt: "/icons/ppt.png",
            pptx: "/icons/ppt.png",
            jpg: "/icons/jpg.png",
            jpeg: "/icons/jpeg.png",
            png: "/icons/png.png",
            gif: "/icons/gif.png",
            html: "/icons/html.png",
            css: "/icons/css.png",
            js: "/icons/unknown.png",
            zip: "/icons/zip.png",
            rar: "/icons/rar.png",
            exe: "/icons/exe.png",
            avi: "/icons/avi.png",
            mp3: "/icons/mp3.png",
            mp4: "/icons/mp4.png",
            psd: "/icons/psd.png",
            txt: "/icons/txt.png",
            default: "/icons/unknown.png",
        };

        return iconMap[extension] || iconMap["default"];
    };

    const onDrop = useCallback(async (acceptedFiles, rejectedFiles) => {
        try {
            // Alert for unsupported files
            setAlertMessage("")

            if (rejectedFiles.length > 0) {
                const unsupportedFiles = rejectedFiles.map((file) => file.file.name).join(", ");
                setAlertMessage(`Unsupported files: ${unsupportedFiles}`);
            }

            if (acceptedFiles.length > 0) {
                setFileToUpload(acceptedFiles[0]); // Save the first file to upload
                setShowPopup(true); // Show the popup
            }

        } catch (err) {
            console.error("Error uploading files:", err);
        }
    }, []);


    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        multiple: true, // Allow multiple file uploads
        accept: {
            'image/*': [], // Allow all image types
            'application/pdf': [], // Allow PDF files
            'text/plain': [], // Allow plain text files
            'video/*': [], // Allow all video types (e.g., mp4, avi, mov)
            'application/msword': [], // Allow .doc
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [], // Allow .docx
            'application/vnd.ms-excel': [], // Allow .xls
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [], // Allow .xlsx
            'application/vnd.ms-powerpoint': [], // Allow .ppt
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': [], // Allow .pptx
            'application/zip': [], // Allow .zip
            'application/x-rar-compressed': [], // Allow .rar
        },
    });

    const fetchClassDetails = async (classNum) => {
        fetchApi('classroom/class', {"classId": classNum})
            .then(response => {
                const students = response.responseData.students;
                // console.log('Student List: ', students);
                if (response.status >= 200 && response.status < 300) {
                    // console.log(response.responseData)
                    setStudentList(students);
                    setClassTitle(response.responseData.class);
                    setMsgCode(0);
                    fetchFiles()
                } else {
                    setMsg(response.msg);
                    setMsgCode(4);
                }
            });
    };

    const uploadFile = (file, isPrivate) => {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append("file", file, file.name);
            formData.append("studentId", studentId);
            formData.append("classId", classId);
            formData.append("isPrivate", isPrivate); // Append the isPrivate flag

            const req = new XMLHttpRequest();
            req.open("POST", `${server()}/classroom/share_file`);
            req.withCredentials = true; // Include credentials

            req.onload = () => {
                if (req.status >= 200 && req.status < 300) {
                    resolve(true);
                } else {
                    reject(`Failed to upload file. Status: ${req.status}`);
                }
            };

            req.onerror = () => {
                reject("Failed to upload file.");
            };

            req.send(formData);
        });
    };

    const handleUserChoice = async (isPrivateValue) => {
        setShowPopup(false); // Hide the popup
        await handleUpload(fileToUpload, isPrivateValue); // Pass the isPrivate value directly
    };

    const handleUpload = async (file, isPrivateValue) => {
        if (!file) return; // Ensure there is a file to upload

        try {
            await uploadFile(file, isPrivateValue); // Upload the file with the passed isPrivate value

            console.log(`${file.name} uploaded successfully as ${isPrivateValue ? "private" : "public"}`);
        } catch (err) {
            console.error("Error uploading file:", err);
        } finally {
            setFileToUpload(null); // Clear the file state
            fetchFiles(); // Refresh the shared directory
        }
    };

    const downloadFile = (fileName, isPrivate) => {
        const formData = new FormData();
        formData.append("classId", classId);
        formData.append("studentId", user.roleId);
        formData.append("fileName", fileName);
        formData.append("isPrivate", isPrivate); // Or true, depending on privacy settings

        fetch(`${server()}/classroom/download_file`, {
            method: "POST",
            body: formData,
            credentials: "include", // Include cookies for authentication
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to download file");
                }
                return response.blob();
            })
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = fileName; // Set file name for download
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch((err) => console.error("Error downloading file:", err));
    };


    const fetchFiles = async () => {
        try {
            const response = await fetchApi("classroom/fetch_files", {
                studentId: user.roleId,
                classId
            });
            if (response.status >= 200 && response.status < 300) {
                setSharedFiles(response.responseData.files); // Store file list
            } else {
                console.error("Failed to fetch shared files:", response.message);
            }
        } catch (err) {
            console.error("Error fetching shared files:", err);
        }
    };

    const formatFileSize = (size) => {
        if (size < 1024) return `${size} B`;
        if (size < 1024 * 1024) return `${(size / 1024).toFixed(1)} KB`;
        if (size < 1024 * 1024 * 1024) return `${(size / (1024 * 1024)).toFixed(1)} MB`;
        return `${(size / (1024 * 1024 * 1024)).toFixed(1)} GB`;
    };

    return (

        <>

            {showPopup && (
                <div className="popup-overlay">
                    <div className="popup">
                        <h3>Set File Visibility</h3>
                        <p>Would you like this file to be public or private?</p>
                        <p>If set to private, only you and your trainer will be able to see it.</p>

                        <div className="popup-actions">
                            <button onClick={() => handleUserChoice(false)}>Public</button>
                            <button onClick={() => handleUserChoice(true)}>Private</button>
                        </div>
                    </div>
                </div>
            )}

            <div className="classroom-container">

                <header className="classroom-header">
                    <div className="classroom-header-content">
                        <h1>{classTitle}</h1>
                        <p>{certName}</p>
                    </div>
                    <div className="classroom-clock">{time}</div>
                </header>

                {/*<div className="classroom-player"></div>*/}

                {/*=== DO NOT DELETE ===*/}
                {/* This is the left toolbar */}
                <nav className="classroom-button-menu">
                    <button className="classroom-button">Calendar</button>
                    <button className="classroom-button">To Do</button>
                    <button className="classroom-button">Scream Internally</button>
                    <button className="classroom-button">Notepad</button>
                    <button className="classroom-button">Calculator</button>
                    <button className="classroom-button">Do not push</button>
                </nav>
                {/*=== DO NOT DELETE ===*/}

                <main className="classroom-main">

                    <CustomAlert msg={msg} msgCode={msgCode}/>

                    <div className="classroom-top-row">
                        <div className="classroom-trainer">Trainer</div>
                        <div
                            className={`classroom-whiteboard ${
                                isWhiteboardExpanded ? "expanded" : ""
                            }`}
                            onClick={toggleWhiteboard}
                        >
                            {isWhiteboardExpanded ? "Media Player" : "Whiteboard"}
                        </div>
                    </div>

                    {studentList.map((learner) => (
                        <div key={learner.id} className="classroom-learner">
                            {learner.name}
                        </div>
                    ))}
                </main>

                <footer className="classroom-footer">
                    {alertMessage && (
                        <div className="alert">
                            {alertMessage}
                            <button onClick={() => setAlertMessage("")}></button>
                        </div>
                    )}
                    <div className="classroom-upload-zone" {...getRootProps()}>
                        <input {...getInputProps()} />
                        <FiUpload className="upload-icon"/>
                        {isDragActive ? (
                            <p className="upload-text">Drop the items here...</p>
                        ) : (
                            <>
                                <p className="upload-text">
                                    Drag & drop items here or <span className="browse-files">Browse files</span>
                                </p>
                                <p className="upload-info">Up to 25 MB • File names without special characters</p>
                            </>
                        )}
                    </div>


                    <div className="classroom-shared-files">
                        <div className="shared-files-header">
                            <h3>Shared Files</h3>
                            <div className="header-actions">
                                <button className="refresh-button" onClick={fetchFiles}>
                                    <span className="refresh-icon">🔄</span>
                                </button>
                                <div className="view-toggle">
                                    <button
                                        className={`toggle-button ${viewMode === 'list' ? 'active' : ''}`}
                                        onClick={() => setViewMode('list')}
                                    >
                                        List View
                                    </button>
                                    <button
                                        className={`toggle-button ${viewMode === 'icon' ? 'active' : ''}`}
                                        onClick={() => setViewMode('icon')}
                                    >
                                        Icon View
                                    </button>
                                </div>
                            </div>
                        </div>
                        {viewMode === 'list' ? (
                            <ul className="shared-file-list">
                                {sharedFiles.map((file, index) => (
                                    <li key={index} className="shared-file-item">
                                        <img
                                            src={getCustomIconForFileType(file.name)}
                                            alt={`${file.name} icon`}
                                            className="file-icon"
                                        />
                                        <span className="file-name" onDoubleClick={() => downloadFile(file.name)}>
                                            {file.name}
                                        </span>
                                        {file.isPrivate && <span className="file-tag">Private</span>}
                                        <span
                                            className="file-size">{formatFileSize(file.size)}</span> {/* File size here */}
                                        <button
                                            className="download-button"
                                            onClick={() => downloadFile(file.name)}
                                        >
                                            Download
                                        </button>

                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div className="learner-resources">
                                {sharedFiles.map((file, index) => (
                                    <div className="resource-item" key={index}>
                                        <a
                                            onDoubleClick={() => downloadFile(file.name, file.isPrivate)}
                                            href="#"
                                        >
                                            <img
                                                src={getCustomIconForFileType(file.name)}
                                                alt={file.name}
                                                className="resource-icon"
                                            />
                                            <p className="resource-name">{file.name}</p>
                                            {file.isPrivate && <span className="file-tag">Private</span>}
                                        </a>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>


                </footer>

                <aside className="classroom-chat-bar">
                    <div className="chat-header">
                        <h3>Classroom Chat</h3>
                        <button
                            className={`toggle-private ${isPrivate ? 'active' : ''}`}
                            onClick={() => setIsPrivate(!isPrivate)}
                        >
                            {isPrivate ? 'Trainer' : 'Group Chat'}
                        </button>
                    </div>
                    <div className="chat-messages">
                        <MessageList
                            className="message-list"
                            dataSource={messages.map((msg) => ({
                                position: msg.position,
                                type: 'text',
                                text: msg.text,
                                title: msg.sender,
                                date: msg.date,
                            }))}
                        />
                    </div>
                    <div className="chat-input">
                        <Input
                            className="chat-input-box"
                            placeholder="Type your message..."
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            rightButtons={<Button text="Send" onClick={sendMessage} className="send-button"/>}
                        />
                    </div>
                </aside>

            </div>


        </>
    )
        ;

}