export { default as sign } from './signBoard.png';
export { default as spud } from './spud.png';
export { default as construction} from './construction.png'
export { default as banner1 } from './banner1.png'
export { default as banner2 } from './banner2.png'
export { default as banner3 } from './banner3.png'
export { default as banner4 } from './banner4.png'
export { default as banner5 } from './banner5.png'
export { default as banner1Small } from './banner1 mobile.png'
export { default as banner2Small } from './banner2 mobile.png'
export { default as banner3Small } from './banner3 mobile.png'
export { default as banner4Small } from './banner4 mobile.png'
export { default as banner5Small } from './banner5 mobile.png'