import React, { useState} from "react";
import {Form, Modal} from "react-bootstrap";
import {MDBCol, MDBContainer, MDBRow} from "mdb-react-ui-kit";
import CustomAlert from "./CustomAlert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
// import useFetchApi from "../Services/FetchApi";
// import { useUserField } from "CustomHooks";

const PasswordVerificationModal = ({ show, onHide, socialMediaName }) => {

    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const socialMediaLogo = `${process.env.PUBLIC_URL}/Images/${socialMediaName}.png`;
    const siteLogo = `${process.env.PUBLIC_URL}/Images/logo.png`;
    const linkingIcon = `${process.env.PUBLIC_URL}/Images/link.png`;
    // const location = useLocation();
    const navigate = useNavigate();
    // const fetchApi = useFetchApi();
    // const [inputs, setInputs] = useState({"longExpiry":false});
    const [msg, setMsg] = useState(window.sessionStorage.getItem("message"));
    const [msgCode, setMsgCode] = useState(0)
    // const isLoggedIn = useUserField('loggedIn');
    const [accountLinkInfo, _setAccountLinkInfo] = useState({user_id: '', email: '', fb_user_id: '' });
    // const [socialMediaName, setSocialMediaName] = useState('');
    // const [socialMediaBlurb, setSocialMediaBlurb] = useState('');
    // const [showPasswordSetupModal, setShowPasswordSetupModal] = useState(false);
    const [_showPasswordModal, setShowPasswordModal] = useState(false);
    // const [showPassword, setShowPassword] = useState(false);

    const handleRetry = (e) => {
        handleVerifyPassword(e)
    }

    const handleVerifyPassword = (e) => {

        // Call the API and checks the users password to the user's account before updating.
        fetch('', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                // Removed the Authorization header since the token should be automatically sent with the request via HttpOnly cookies
            },
            credentials: 'include',
            body: JSON.stringify({
                // Adjusted to remove the username from the request payload if it's not needed for identification
                // The server can identify the user based on the token
                "password": password,
                "updates": {
                    "fb_user_id": accountLinkInfo.fb_user_id,
                }
            })
        })
            /* eslint-disable-next-line no-unused-vars */
            .then(response => response.json().then(data => {

                switch(response.status) {
                    case 200:
                        setMsgCode(4);
                        navigate('/profile')
                        setShowPasswordModal(false);
                        break;

                    case 400:
                        setMsg('Incorrect password. Please try again.')
                        setMsgCode(1);
                        break;
                    case 401:
                        console.log('Invalid Token.');
                        setMsg('Token authorization failed. Please login again.')
                        setMsgCode(1);
                        break;
                    case 402:
                        setMsg('Password cannot be blank. Please enter password and try again')
                        setMsgCode(1);
                        break;
                    case 403:
                        console.log('Bearer token expired. Attempting to refresh.');
                        fetch('', {
                            method: 'POST',
                            credentials: 'include', // Necessary to include cookies if the refresh token is stored in an HttpOnly cookie
                        })
                            .then(refreshResponse => {
                                if (refreshResponse.ok) {
                                    // Assuming the server responds with the new bearer token in the response body or a cookie
                                    console.log('Token refreshed successfully.');
                                    setMsg('Authorization token has been refreshed.');
                                    setMsgCode(1);
                                    // Retry the original request with the new token
                                    handleRetry(e); // Call the original function again
                                } else {
                                    console.log('Failed to refresh token.');
                                    setMsg('Session expired. Please log in again.');
                                    setMsgCode(1);
                                    navigate('/login')
                                }
                            })
                            .catch(refreshError => {
                                console.error('Error attempting to refresh token:', refreshError);
                                setMsg('Session expired. Please log in again.');
                                setMsgCode(1);
                                // Redirect to login or show a login modal
                                navigate('/login')
                            });
                        break;
                    case 404:
                        setMsg('Your account could not be found. Please try again or contact support.')
                        setMsgCode(1);
                        break;
                    case 429:
                        console.log('Too many login attempts');
                        alert('Too many login attempts - account has been locked!')
                        setMsg('Account locked due to too many failed attempts, please try again later.')
                        setMsgCode(2);
                        break;
                    case 500:
                        console.log('Internal Error... Probably a server or MySQL issue');
                        setMsg('Something broke real bad. Try again later or contact support.')
                        setMsgCode(1);
                        break;
                    default:
                        console.log('O_o    Sumfin broke real bad Boss!');
                }

            }))

            .catch(err => err);

    }

    // Assuming handleChange handles both password and confirmPassword inputs
    const handleChange = (e) => {
        if (e.target.name === "password") {
            setPassword(e.target.value);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };


    return (

        <Modal show={show} onHide={onHide} centered backdrop="static" keyboard={false}>

            {/*<Modal.Header closeButton>*/}
            {/*    <Modal.Title>Set Up Password</Modal.Title>*/}
            {/*</Modal.Header>*/}

            <Modal.Body style={{}}>
                <Form onSubmit={handleVerifyPassword}>
                    <MDBContainer style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'transparent'
                    }}>
                        <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%', padding: '20px 0', }}>
                            {/* Site logo */}
                            <img src={siteLogo} alt="Site Logo" style={{ width: 'auto', height: 200 }} />
                            {/* Linking icon */}
                            <img src={linkingIcon} alt="Linking" style={{ width: 'auto', height: 100, marginTop: 50, marginLeft: -20 }} />
                            {/* Social media logo */}
                            <img src={socialMediaLogo} alt="Social Media Logo" style={{ maxWidth: '200px', padding: 10 }} />
                        </div>

                        <div className="align-items-end justify-content-end bg-transparent mb-12" style={{ }}>
                            <h3 style={{textAlign: 'center'}}>PLEASE ENTER YOUR TP ACCOUNT PASSWORD</h3>
                            {/* Blurb explaining why they need to set up a password */}
                            <p style={{textAlign: 'center', marginBottom: 25, color: '#000'}}>
                                It looks like there is already an existing TP account with the same name and email.
                                To link your {socialMediaName} account to TP, please enter the password associated
                                with your TP.com profile. This is a one-time process and you will not need to enter
                                this password again if you login with {socialMediaName} again in the future.
                            </p>

                            <CustomAlert msg={msg} msgCode={msgCode} />

                            <div style={{ position: 'relative'}}>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    className="inputBox"
                                    placeholder={"ENTER A PASSWORD"}
                                    onChange={handleChange}
                                    style={{ paddingRight: '40px' }} // Make room for the icon
                                />
                                <FontAwesomeIcon
                                    icon={showPassword ? faEyeSlash : faEye}
                                    onClick={togglePasswordVisibility}
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        right: '15px',
                                        transform: 'translateY(-50%)',
                                        cursor: 'pointer',
                                        color: '#6c757d'
                                    }}
                                />
                            </div>

                            <MDBRow>
                                <MDBCol center>
                                    {/* Change the type to "button" and add an onClick handler */}
                                    <button
                                        style={{ marginTop: 15, marginBottom: 30 }}
                                        className="btn-primary"
                                        type="button" // Changed from "submit" to "button"
                                        onClick={handleVerifyPassword} // Add the click event handler here
                                    >
                                        UPDATE PASSWORD
                                    </button>
                                </MDBCol>
                            </MDBRow>

                        </div>
                    </MDBContainer>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default PasswordVerificationModal;