import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface StudentHistoryState {
    studentHistory: any;  // Using 'any' to ensure it can hold the exact JSON structure
}

const initialState: StudentHistoryState = {
    studentHistory: null,
};

const studentHistorySlice = createSlice({
    name: 'studentHistory',
    initialState,
    reducers: {
        setHistory(state, action: PayloadAction<any>) {
            state.studentHistory = action.payload;
        },
        clearHistory(state) {
            state.studentHistory = initialState;
        },
    },
});

export const { setHistory, clearHistory } = studentHistorySlice.actions;
export default studentHistorySlice.reducer;