import useFetchApi from '../Services/FetchApi';

export { useUserDeets } from './useUserDeets';
export { useUserField } from './useUserDeets';
export { useStudentHistoryDeets } from './useStudentHistoryDeets';
export { useUserData } from './useUserData';
export { useUpdateUserField } from './useUpdateUserField';
export { useStudentHistoryData } from './useStudentHistoryData';
export { useForm } from './useForm';
export { useViewport } from './useViewport';
export { useActivityLogger } from './useActivityLogger';
export { useHeartbeat } from './useHeartbeat';
export { useLogout } from './useLogout';
export { useFetchApi };
export { useSignature } from './useSignature';
export { usePreventScroll } from './usePreventScroll';
export { useHasRole } from './useHasRole';
export { useAssessmentAnswers } from './useAssessmentAnswers';
export { useWorkflow } from './useWorkflow';