///////////////////////////// PAGES UNDER CONSTRUCTION /////////////////////////////
export const underConstructionPages = [
  '/contact',
  '/study/my-profile',
  '/enrol',
  // '/study/classroom(LIVE)',
  // '/study/pre-enrolment',
  // '/study/enrolment',
  // '/study/diary',
  '/password-recovery',
  '/portal/administration/setup',
  '/portal/history',
  '/files',
  '/cloud-storage',
  '/chat',
  '/calendar',
];


///////////////////////////// HEARTBEAT CONSTANTS /////////////////////////////
export const MAX_FAILURES = 3; // Maximum number of failures before showing the refresh login modal
export const HEARTBEAT_INTERVAL = 15000; // 15 seconds for testing
export const KEY_REFRESH_INTERVAL = 15000; // 15 seconds for testing


/////////////////////////////  ACTIVITY LOGGER /////////////////////////////
export const ACTIVITY_TIMEOUT = 1200000; // 20 minutes in milliseconds (1200000)
export const COUNTDOWN = 30; // 30 seconds

//////////////////////////// FORM QUESTION TYPES /////////////////////////////
export const VALID_QUESTION_TYPES = [
  100, // Text 100 chars
  101, // Text 250 chars
  102, // Text 500 chars
  103, // Text 1000 chars
  110, // Phone #
  111, // Mobile # only
  112, // Email
  113, // Address line 1
  114, // Address line 2
  115, // Name (student/candidate)
  116, // Name (trainer/RTO rep)
  117, // Name (employer)
  118, // Name (parent/guardian)
  119, // Name (school rep)
  120, // $ amount
  200, // Skip the next question if the answer is No
  160, // Number any length
  161, // Postcode 4 digits
  201, // Single choice, dropdown
  202, // Multiple choice (checkbox)
  203, // Yes/No
  210, // Strongly Disagree to Strongly Agree (4 options)
  251, // Gender - Female(0), Male (1), Other (2)
  301, // Date YYYY
  302, // Date mmYYYY
  303, // Date DDmmYYYY
  400, // LeadIn
  401, // Consent - name must be valid in state or will render an error
  402, // Disclaimer/Fine print
  410, // Signature
  500, // File upload any type
  501, // File upload image only
  502, // File upload PDF only
  503, // File upload video only
  504, // File upload audio only
  510, // Upload url link with a dynamic url 
  520, // Dropdown, single choice, image upload only, maybe shared with other 520s
  600, // If no/0/false, show the following questions until a qType 699 is hit
  601, // If yes/1/true, show the following questions until a qType 699 is hit
  699, // End of conditional questions - questions after this will be shown regardless of the previous answer
] as const;


/////////////////////////////  QUESTION TARGET GROUPS /////////////////////////////
export const VALID_TARGET_GROUPS = [
  1, // Parent / Guardian
  2, // Student / Candidate
  3, // Employer
  4, // School Rep
  5, // Trainer / RTO Rep
  10, // Admin / God
] as const;

/////////////////////////////  TP ADDRESS (used for USI search) /////////////////////////////
export const TP_ADDRESS = {"addressSuburb": "Toowong", "addressState": "QLD", "addressPostcode": "4066", "addressStreetNumber": "54", "addressStreetName": "Jephson St"};