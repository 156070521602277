import { useDispatch } from 'react-redux';
import { setUser, clearUser } from '../State/Reducers/userSlice';
import UserInterface from '../Types/userInterface';

export const useUserData = () => {
    const dispatch = useDispatch();

    const setUserData = (data: any) => {
        const userData: UserInterface = {
            // dob: formatDate(data.dob),
            dob: data.dob,
            usi: data.usi,
            email: data.email,
            ipAddress: data.ipAddress,
            username: data.username,
            fullName: data.user_name,
            phoneNumber: data.phone_number,
            accessLevel: data.access_level,
            user_id: data.user_id,
            roleId: data.role_id,
            roles: data.roles,
            loggedIn: true,
        };

        dispatch(setUser(userData));
    };

    const clearUserData = () => {
        dispatch(clearUser());
    };

    return { setUserData, clearUserData };
};
