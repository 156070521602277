/* eslint-disable no-unused-vars */

import React, {useContext, useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import logError from "../Components/Logger";  //  error handler





const Template = () => {

//     Functions here

    return (
        <div>

        </div>
    );
};

export default Template;

// =============================== To Do =================================== //



// =============================== Bugs =================================== //

