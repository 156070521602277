import { combineReducers } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import studentHistoryReducer from './studentHistorySlice';
import modalReducer from './refreshLoginSlice';
import activityReducer from './activitySlice';
import keyReducer from './keySlice';
import formReducer from './formSlice';
import signatureReducer from './signatureSlice';
import assessmentReducer from './assessmentSlice';
import workflowReducer from './workflowSlice';
import oldWorkflowReducer from './oldWorkflowSlice';

const rootReducer = combineReducers({
  user: userReducer,
  studentHistory: studentHistoryReducer,
  refreshLogin: modalReducer,
  activityMonitor: activityReducer,
  encryptionKey: keyReducer,
  form: formReducer,
  signature: signatureReducer,
  assessment: assessmentReducer,
  workflow: workflowReducer,
  oldWorkflow: oldWorkflowReducer,
});

// Define RootState without allowing undefined
export type RootState = {
  user: NonNullable<ReturnType<typeof userReducer>>;
  studentHistory: NonNullable<ReturnType<typeof studentHistoryReducer>>;
  refreshLogin: NonNullable<ReturnType<typeof modalReducer>>;
  activityMonitor: NonNullable<ReturnType<typeof activityReducer>>;
  encryptionKey: NonNullable<ReturnType<typeof keyReducer>>;
  form: NonNullable<ReturnType<typeof formReducer>>;
  signature: NonNullable<ReturnType<typeof signatureReducer>>;
  assessment: NonNullable<ReturnType<typeof assessmentReducer>>;
  workflow: NonNullable<ReturnType<typeof workflowReducer>>;
  oldWorkflow: NonNullable<ReturnType<typeof oldWorkflowReducer>>;
};

export default rootReducer;
