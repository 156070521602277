import { createSlice } from '@reduxjs/toolkit';

interface KeyState {
  encryptionKey: string | null;
}

const initialState: KeyState = {
  encryptionKey: "this is a random key to initialize the state and gets replaced by the key from the server via the heartbeat",
};

const keySlice = createSlice({
  name: 'encryptionKey',
  initialState,
  reducers: {
    setEncryptionKey: (state, action) => {
      state.encryptionKey = action.payload;
    },
    clearEncryptionKey: (state) => {
      state.encryptionKey = initialState.encryptionKey;
    },
  },
});

export const { setEncryptionKey, clearEncryptionKey } = keySlice.actions;
export default keySlice.reducer;