import React, { useEffect, useState } from "react";
import { LeadIn, Alert, Group, MobileModal } from "Components";
import { GroupData, QuestionType } from "Types/FeedbackTypes";
import { useNavigate } from "react-router-dom";
import { useForm, useFetchApi, useUserField } from "CustomHooks";

// Hardcoded data for testing
import Data from "TestingDataObjects/new-learner-questionnare-data.json";

import './Styles/feedback.scss';

const Feedback: React.FC = () => {
  const navigate = useNavigate();
  const { resetForm } = useForm();
  const user_id = useUserField('user_id');
  const fetchApi = useFetchApi();
  const { getGroupData, updateGroupData } = useForm();
  const [optOut, setOptOut] = useState<boolean>(false);
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);
  const [msg, setMsg] = useState<string>('');
  const [msgCode, setMsgCode] = useState<0 | 1 | 2 | 3 | 4>(0);
  const [showPopup, setShowPopup] = useState<boolean>(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const groupsData: GroupData[] = Data.groups.map(group => ({
    groupId: group.groupId,       
    groupName: group.name,    
    questions: group.questions.map(question => ({
      questionId: question.questionId,
      questionText: question.questionText,
      questionType: question.questionType as QuestionType,
      expectedResponse: question.expectedResponse || null,
      options: question.options || null
    })),
    version: group.version
  }));

  const title = "IMPORTANT INSTRUCTIONS";
  const instructions = [
    <div key="instruction-1">
      <p>Please tell us about your training. Your feedback plays an important role in developing the quality of your education.</p>
      <p>In this questionnaire, the term 'training' refers to learning experiences with your training organisation. The term 'trainer' refers to trainers, teachers, lecturers, or instructors from your training organisation.</p>
      <p>Provide one response to each item on the form. Leave the box blank if the statement does not apply.</p>
    </div>
  ];

  const handleResponseChange = (questionId: number, value: string) => {
    const currentGroupId = currentGroupIndex + 1;
    const currentGroup = groupsData[currentGroupIndex];
  
    // Find the index of the question in the current group
    const questionIndex = currentGroup.questions.findIndex(q => q.questionId === questionId);
  
    if (questionIndex !== -1) {
      const question = currentGroup.questions[questionIndex];
  
      // Determine if the response should be stored as an optionId or the value itself
      let responseValue = value;
  
      if (question.options) {
        // Find the matching optionId if options exist
        const selectedOption = question.options.find(option => option.optionText === value);
        if (selectedOption) {
          responseValue = selectedOption.optionId.toString();
        }
      }
  
      updateGroupData(currentGroupId.toString(), {
        questionId: question.questionId.toString(),
        qIndex: questionIndex,
        qType: question.questionType as number,
        response: responseValue
      });
    } else {
      console.error(`Question with ID ${questionId} not found in current group.`);
    }
  };
  

  const handleSubmitCurrentGroup = async () => { 
    const responses = getGroupData(currentGroupIndex + 1) || {};
    const currentGroupId = currentGroupIndex + 1;
    const currentGroupVersion = groupsData[currentGroupIndex]?.version || "0";
    
    const responsesArray = Object.entries(responses).map(([questionId, data]) => ({
      questionIndex: data.qIndex,
      questionId: Number(questionId),
      questionType: data.qType,
      response: data.response
    }));

    // If there is nothing to submit, skip it so we don't annoy the server
    if (!responsesArray.length) {
      if (currentGroupIndex < groupsData.length - 1) {
        setCurrentGroupIndex(currentGroupIndex + 1);
      } else {
        resetForm();
        navigate('/');
      }
      return;
    }

    const jsonData = {
      user_id: user_id.toString(),
      total: totalQuestions,
      groupId: currentGroupId,
      version: currentGroupVersion,
      responses: responsesArray
    };

    try {
      const response = await fetchApi('student/survey', jsonData);
      if (response.msgCode === 1) {
        setMsg("Your feedback has been submitted successfully.");
        setMsgCode(0);
      } else {
        setMsg(response.msg || "An unexpected error occurred.");
        setMsgCode(response.msgCode || 4);
      }
    } catch {
      setMsg("An error occurred. Please try again.");
      setMsgCode(4);
    }
  
    if (currentGroupIndex < groupsData.length - 1) {
      setCurrentGroupIndex(currentGroupIndex + 1);
    } else {
      resetForm();
      navigate('/');
    }
  }; 

  const isLastGroup = currentGroupIndex === groupsData.length - 1;
  const isFirstGroup = currentGroupIndex === 0;

  const totalAnsweredQuestions = groupsData.reduce((acc, group, index) => {
    const groupResponses = getGroupData(index + 1) || {};
    const answeredInGroup = Object.values(groupResponses).filter(response => response?.response && response.response !== '').length;
    return acc + answeredInGroup;
  }, 0);
  
  const totalQuestions = groupsData.reduce(
    (acc, group) => acc + group.questions.length,
    0
  );

  const handleOptOutChange = () => {
    const newOptOutValue = !optOut; 
    setOptOut(newOptOutValue);
    
    if (newOptOutValue) {
      resetForm();
      navigate('/');
    }
  };

  return (
    <div className="feedback-container">
      <Alert msg={msg} msgCode={msgCode}/>

      <MobileModal 
        isOpen={showPopup} 
        onClose={() => console.log('closed')} 
        withButton={false} 
        heading='Assessment Complete'
        children={
          <>
            <p>Please take a few minutes to provide us with some feedback of your experiences.</p>
            <p>This is your chance to help improve the quality of your education.</p>
          </>
        }
      />

      {isFirstGroup &&
        <LeadIn
          title={title}
          description={instructions}
        />
      }

      <Group
        groupData={groupsData[currentGroupIndex]}
        handleResponseChange={handleResponseChange}
        totalQuestions={totalQuestions}
        answeredQuestions={totalAnsweredQuestions}
      />

      <div className='feedback-buttons-container'>
        {!isFirstGroup && <button
          className='btn-primary'
          onClick={() => setCurrentGroupIndex(currentGroupIndex - 1)}
        >
          Previous
        </button>}
        <button
          className='btn-primary'
          onClick={handleSubmitCurrentGroup}
        >
          {isLastGroup ? 'Finish' : 'Next'}
        </button>
      </div>
   
      <div style={{ height: '20px'}}>
        <label
          htmlFor='opt-out-checkbox'
        >
          <input
            className="radio-as-checkbox subtle"
            type="checkbox"
            id='opt-out-checkbox'
            name='opt-out'
            value='opt-out'
            checked={optOut}
            onChange={() => handleOptOutChange()}
          />
          <span className="subtle-text">Skip providing feedback</span>
        </label>
      </div>
    </div>
  );
};

export default Feedback;
