// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inactivity-modal {
  height: auto;
  top: 50%;
  transform: translate(0, -50%);
}

.inactivity-modal-header {
  background-color: #f8d7da;
  color: #721c24;
  padding: 15px;
  border-bottom: none;
}

.inactivity-modal-body {
  display: flex;
  flex-direction: column;
  align-content: center;
  flex-wrap: wrap;
  background: #ffffff !important;
  border-radius: 0px 0px 8px 8px !important;
}
.inactivity-modal-body p {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #393939;
}
.inactivity-modal-body p span {
  color: #721c24;
  font-size: 24px;
}
.inactivity-modal-body button {
  align-self: center;
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Styles/InactivityCheck.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,QAAA;EACA,6BAAA;AACF;;AACA;EACE,yBAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;AAEF;;AACA;EACE,aAAA;EACA,sBAAA;EACA,qBAAA;EACA,eAAA;EACA,8BAAA;EACA,yCAAA;AAEF;AADE;EACE,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAGJ;AADI;EACE,cAAA;EACA,eAAA;AAGN;AACE;EACE,kBAAA;EACA,gBAAA;AACJ","sourcesContent":[".inactivity-modal {\n  height: auto;\n  top: 50%;\n  transform: translate(0, -50%);\n}\n.inactivity-modal-header {\n  background-color: #f8d7da;\n  color: #721c24;\n  padding: 15px;\n  border-bottom: none;\n}\n\n.inactivity-modal-body {\n  display: flex; \n  flex-direction: column;\n  align-content: center;\n  flex-wrap: wrap;\n  background: #ffffff !important;\n  border-radius:0px 0px 8px 8px !important;\n  & p {\n    text-align: center;\n    font-size: 20px;\n    font-weight: bold;\n    color: #393939;\n\n    & span {\n      color: #721c24;\n      font-size: 24px;\n    }\n  }\n\n  & button {\n    align-self: center;\n    margin-top: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
