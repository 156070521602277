import React from 'react';
import { Form } from 'Components';

// Hardcoded data for testing
import Data from "TestingDataObjects/new-enrolment-form-data.json";

interface EnrolmentProps {}

const Enrolment: React.FC<EnrolmentProps> = () => {
  return (
    <div className='enrolment-container'>
     <Form formData={Data} />
    </div>
  );
};

export default Enrolment;