import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../State/Reducers";
import { updateFormData, resetFormData } from '../State/Reducers/feedbackSlice';
import { useCallback } from 'react';

interface UseFeedbackFormReturn {
  formData: Record<string, any>;
  getGroupData: (groupId: string | number) => Record<string, any> | undefined;
  updateGroupData: (
    groupId: string,
    questionData: { questionId: string; qIndex: number; qType: number; response: string }
  ) => void;
  resetForm: () => void;
}

export const useFeedbackForm = (): UseFeedbackFormReturn => {
  const dispatch = useDispatch();
  const formData = useSelector((state: RootState) => state.feedbackForm.formData);

  const getGroupData = useCallback(
    (groupId: string | number) => formData[groupId] || {},
    [formData]
  );

  const updateGroupData = useCallback(
    (groupId: string, questionData: { questionId: string; qIndex: number; qType: number; response: string }) => {
      const { questionId, qIndex, qType, response } = questionData;
  
      // Construct the state update object
      const data = {
        [questionId]: { qIndex, qType, response },
      };
  
      // Dispatch with the correct payload structure
      dispatch(updateFormData({ moduleId: groupId, data })); // Ensure `updateFormData` is accepting this structure
    },
    [dispatch]
  );
  

  const resetForm = useCallback(() => {
    dispatch(resetFormData());
  }, [dispatch]);

  return {
    formData,
    getGroupData,
    updateGroupData,
    resetForm,
  };
};
