import React, { ReactNode } from "react";


interface LeadInProps {
  title: string | ReactNode;
  description: string | ReactNode;
}

const LeadIn: React.FC<LeadInProps> = ({ title, description }) => {
  return (
    <div className="lead-in">
      <h3 className="lead-in-title">{title}</h3>
      <div className='lead-in-text'>{description}</div>
    </div>
  );
};

export default LeadIn;