export { default as Assessment } from "./Study/Assessment";
export { default as Classroom } from "./Study/Classroom";
export { default as ClassroomLive } from "./Study/ClassroomLive";
export { default as Competencies } from "./Competencies";
export { default as Contact } from "./Contact";
export { default as CoursesTraining } from "./CoursesTraining";
export { default as Employment } from "./Employment";
// export { default as Enrol } from "./Enrol";
export { default as EventViewer } from "./EventViewer";
export { default as Home } from "./Home";
export { default as Login } from "./Login";
export { default as Register } from "./Register";
export { default as Study } from "./Study";
export { default as Template } from "./Template";
export { default as Feedback } from './Feedback';
export { default as DynamicForm } from './DynamicForm';
export { default as StudentEnrol } from './StudentEnrol';
export { default as PasswordRecovery } from './PasswordRecovery';

// Study Pages
export { default as Diary } from "./Study/Diary";
export { default as PersonalDetails } from "./Study/PersonalDetails";

// Dev Pages
export {default as Error} from "./Dev/Error";
export { default as Construction } from "./Dev/Construction";

// Landing Pages
export { default as FormLanding } from './LandingPages/FormLanding';
export { default as FormsLanding } from './LandingPages/FormsLanding';
export { default as UploadLanding } from './LandingPages/UploadLanding';
export {default as Landing} from "./Landing";

// Under Construction Pages
export { default as Setup } from "./UnderConstruction/Setup";
export { default as History } from "./UnderConstruction/History";
export { default as Files } from "./UnderConstruction/Files";
export { default as Cloud } from "./UnderConstruction/Cloud";
export { default as Chat } from "./UnderConstruction/Chat";
export { default as Calendar } from "./UnderConstruction/Calendar";

