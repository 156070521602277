import { combineReducers } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import studentHistoryReducer from './studentHistorySlice';
import modalReducer from './refreshLoginSlice';
import activityReducer from './activitySlice';
import keyReducer from './keySlice';
import feedbackFormReducer from './feedbackSlice';
import signatureReducer from './signatureSlice';
import assessmentReducer from './assessmentSlice';

const rootReducer = combineReducers({
  user: userReducer,
  studentHistory: studentHistoryReducer,
  refreshLogin: modalReducer,
  activityMonitor: activityReducer,
  encryptionKey: keyReducer,
  feedbackForm: feedbackFormReducer,
  signature: signatureReducer,
  assessment: assessmentReducer,
});

// Define RootState without allowing undefined
export type RootState = {
  user: NonNullable<ReturnType<typeof userReducer>>;
  studentHistory: NonNullable<ReturnType<typeof studentHistoryReducer>>;
  refreshLogin: NonNullable<ReturnType<typeof modalReducer>>;
  activityMonitor: NonNullable<ReturnType<typeof activityReducer>>;
  encryptionKey: NonNullable<ReturnType<typeof keyReducer>>;
  feedbackForm: NonNullable<ReturnType<typeof feedbackFormReducer>>;
  signature: NonNullable<ReturnType<typeof signatureReducer>>;
  assessment: NonNullable<ReturnType<typeof assessmentReducer>>;
};

export default rootReducer;
