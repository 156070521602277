import React, { useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { pdfjs } from "react-pdf";
import Bluey from "../Components/Bluey"

// Redux
import { useUserField } from 'CustomHooks';
export default function Template() {
    const isLoggedIn = useUserField('loggedIn');
    const navigate = useNavigate();
    const [pageName, setPageName] = useState('');

    // setOptions({
    //     cMapUrl: 'cmaps/',
    //     cMapPacked: true
    // })


    if ( !isLoggedIn ) {
        navigate('/login')
    }
    useEffect(() => {
        // Run the updateQuickMenu1 function on page load
        // updateQuickMenu();
        pdfjs.GlobalWorkerOptions.workerSrc =`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
        const pathArray = window.location.pathname.split('/');
        const pageParam = pathArray[pathArray.length - 1];
        setPageName(pageParam || 'Default Page');
    }, []);

    // const updateQuickMenu = () => {
    //     setQuickMenu(
    //         "Item 1",
    //         "Item 2",
    //         "Item 3",
    //         "Item 4",
    //         "Item 5",
    //         "Item 6" );
    // };
    //
    // const updateStepButtons = () => {
    //
    //     setStepButtons([
    //         'Add More Buttons', null, null, null, null,
    //         null, null, null, null, null,
    //         null, null, null, null, null,
    //         null, null, null, null, null
    //     ]);
    //
    // }

    return (
        <div>

            <Bluey />

            <div className={"page-common"} style={{ marginTop: 100, zIndex: 1 }}>

                {pageName}

            </div>

        </div>
    );
}

// =============================== To Do =================================== //



// =============================== Bugs =================================== //