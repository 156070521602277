import { createSlice, PayloadAction } from '@reduxjs/toolkit';


interface AssessmentState {
  answers: Record<string, Record<string, string>>; 
}

const initialState: AssessmentState = {
  answers: {},
};

const assessmentSlice = createSlice({
  name: 'assessment',
  initialState,
  reducers: {
    saveAnswers: (
      state,
      action: PayloadAction<{
        qNum: string;
        answers: Record<string, string>;
      }>
    ) => {
      const { qNum, answers } = action.payload;

      state.answers[qNum] = {
        ...state.answers[qNum],
        ...answers,
      };
    },
    clearAnswers: () => initialState,
  },
});

export const { saveAnswers, clearAnswers } = assessmentSlice.actions;

export default assessmentSlice.reducer;