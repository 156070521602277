// TO DO: Update these to suitable n values when testing is finished 

///////////////////////////// HEARTBEAT CONSTANTS /////////////////////////////
export const MAX_FAILURES = 3; // Maximum number of failures before showing the refresh login modal
export const HEARTBEAT_INTERVAL = 15000; // 15 seconds for testing
export const KEY_REFRESH_INTERVAL = 15000; // 15 seconds for testing


/////////////////////////////  ACTIVITY LOGGER /////////////////////////////
export const ACTIVITY_TIMEOUT = 1200000; // 20 minutes in milliseconds (1200000)
export const COUNTDOWN = 30; // 30 seconds


///////////////////////////// PAGES UNDER CONSTRUCTION /////////////////////////////
export const underConstructionPages = [
  '/contact',
  '/study/my-profile',
  '/enrol',
  '/study/pre-enrolment',
  '/study/enrolment',
  '/study/diary',
];