import { useState, useEffect } from 'react';

function debounce(func: Function, delay: number) {
  let timeout: NodeJS.Timeout;
  return (...args: any[]) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

export const useViewport = (breakpoint = 900, debounceDelay = 20) => {
  const [showMobile, setShowMobile] = useState<boolean>(
    window.innerWidth < breakpoint || 'ontouchstart' in window
  );

  useEffect(() => {
    const handleResize = debounce(() => {
      const isMobileDevice = window.innerWidth < breakpoint;
      const isTouchDevice = 'ontouchstart' in window;
      setShowMobile(isMobileDevice || isTouchDevice);
    }, debounceDelay);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [breakpoint, debounceDelay]);

  return { showMobile };
};
