import { createSlice } from '@reduxjs/toolkit';

interface ActivityState {
  isUserActive: boolean;
  eventId: number | null;
}

const initialState: ActivityState = {
  isUserActive: true,
  eventId: null,
};

const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    setUserActive: (state) => {
      state.isUserActive = true;
    },
    setUserInactive: (state) => {
      state.isUserActive = false;
    },
    setEventId: (state, action) => {
      state.eventId = action.payload;
    },
  },
});

export const { setUserActive, setUserInactive, setEventId } = activitySlice.actions;
export default activitySlice.reducer;
