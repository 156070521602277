import React, { ReactNode } from "react";

interface LeadInProps {
  title: string | ReactNode;
  description: string | ReactNode;
}

const parseDescriptionWithList = (text: string): ReactNode => {
  const parts = text.split(/\r?\n/); // Split text into lines or sentences
  const elements: ReactNode[] = [];

  parts.forEach((part, index) => {
    const match = part.match(/^\s*(\d+)\.\s+(.*)/); // Match lines starting with a number followed by a dot
    if (match) {
      // If it's a numbered item, wrap it in a <li> tag
      elements.push(
        <li key={`list-item-${index}`}>{match[2]}</li>
      );
    } else {
      // Otherwise, treat it as plain text
      elements.push(<p key={`text-part-${index}`}>{part}</p>);
    }
  });

  // Wrap all detected list items in <ol> if there are any
  const hasListItems = elements.some((element) => React.isValidElement(element) && element.type === "li");
  if (hasListItems) {
    const listItems = elements.filter((element) => React.isValidElement(element) && element.type === "li");
    const otherText = elements.filter((element) => React.isValidElement(element) && element.type !== "li");

    return (
      <>
        {otherText}
        <ol className='lead-in-ol'>{listItems}</ol>
      </>
    );
  }

  return elements; // Return everything as-is if no list is found
};

const LeadIn: React.FC<LeadInProps> = ({ title, description }) => {
  const renderDescription =
    typeof description === "string" ? parseDescriptionWithList(description) : description;

  return (
    <div className="lead-in">
      <h3 className="lead-in-title">{title}</h3>
      <div className="lead-in-text">{renderDescription}</div>
    </div>
  );
};

export default LeadIn;
