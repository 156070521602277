import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { getDocument, GlobalWorkerOptions } from "pdfjs-dist/legacy/build/pdf";
import dayjs from "dayjs";
import { jsPDF } from "jspdf";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pdfWorker from "pdfjs-dist/build/pdf.worker.entry";
import useFetchApi from "Services/FetchApi";
import coverSheet from "Documents/CoverSheet.pdf";
import useFetchPdf from "Services/FetchPdf";
import { useUserDeets, useViewport, useSignature } from "../../CustomHooks";

import "../Styles/mobileSignature.scss";

GlobalWorkerOptions.workerSrc = pdfWorker;

const MobilePDFHandler = forwardRef(
  (
    {
      annotations,
      studentId,
      modId,
      modCode,
      certCode,
      modName,
      apprenticeModsId,
      onSignButtonClick,
    },
    ref
  ) => {
    const user = useUserDeets();
    const fetchPdf = useFetchPdf();
    const fetchApi = useFetchApi();
    const pdfCanvasRef = useRef(null);
    const renderTaskRef = useRef(null);
    const inputRefs = useRef([]);
    const [initialPdfCanvasDimensions, setInitialPdfCanvasDimensions] =
      useState({ width: 0, height: 0 });
    const pdfCanvasHeight = pdfCanvasRef.current
      ? pdfCanvasRef.current.height
      : 1;
    const pdfCanvasWidth = pdfCanvasRef.current
      ? pdfCanvasRef.current.width
      : 1;
    const { showMobile } = useViewport();
    const { name, signature: mobileSignature, date } = useSignature();

    const [dates, setDates] = useState(() =>
      annotations.reduce((acc, annotation) => {
        if (
          annotation.input === "date" &&
          annotation.date != null &&
          annotation.name
        ) {
          try {
            // Parse date and ensure it's a valid Date object
            const parsedDate = new Date(annotation.date);
            if (!isNaN(parsedDate)) {
              acc[annotation.name] = parsedDate; // Use the `name` as the key
            } else {
              console.error(`Invalid date for annotation: ${annotation.date}`);
            }
          } catch (error) {
            console.error(`Error parsing date: ${annotation.date}`, error);
          }
        }
        return acc;
      }, {})
    );
    

    const calculatePaperSize = (format = 'A4', dpi = 300) => {
      // In inches
      const standardFormats = {
        A4: { width: 8.27, height: 11.69 },
        A3: { width: 11.69, height: 16.54 },
        Legal: { width: 8.5, height: 14 },
        Letter: { width: 8.5, height: 11 },
      }

      const dimensions = standardFormats[format];
      if (!dimensions) {
        throw new Error(`Invalid paper format: ${format}`);
      }

      const width = dimensions.width * dpi;
      const height = dimensions.height * dpi;

      return { width, height };
    };

    const [inputValues, setInputValues] = useState(
      annotations.reduce((acc, annotation) => {
        if (annotation.input) {
          acc[annotation.name] = annotation.value || ""; // Initialize with the current value or empty string
        }
        if (annotation.name === "Name") {
          annotation.value = user.fullName || "Assessor Name"; // Set the user full name if available
        }
        if (annotation.name === "ModCode") {
          annotation.label = modCode || ""; // Set the user full name if available
        }
        if (annotation.name === "ModName") {
          annotation.label = modName || ""; // Set the user full name if available
        }
        if (annotation.name === "DOB") {
          annotation.date = user.dob || "Select Date"; // Set the user full name if available
        }
        return acc;
      }, {})
    );

    const calculateFontSize = (canvasHeight, dpr, annotationLabel = "", annotationInput = "", annotationScale = 1) => {
      // Base font size calculation
      const baseFontSize = (canvasHeight * 0.015) / dpr;
    
      // Adjust scale factor if the annotation label is long
      const adjustedScale = annotationLabel.length > 20 ? annotationScale * 0.9 : annotationScale;
    
      // Decrease the minimum font size dynamically based on label length > 50 and input type
      const dynamicMinSize = Math.max(
        (annotationInput === 'date' ?  8 : 9) - 0.5 * Math.floor((annotationLabel.length - 50) / 10),
        6
      );
      
      // Calculate final font size
      return Math.max(baseFontSize * adjustedScale, dynamicMinSize);
    };
    

    const loadPdf = async () => {
      // Cancel any ongoing render task before starting a new one
      if (renderTaskRef.current) {
          try {
              await renderTaskRef.current.cancel();
              renderTaskRef.current = null;
          } catch (error) {
              console.error("Error cancelling previous render task:", error);
              return; // Exit if cancel fails
          }
      }
  
      const postData = {
          studentNumber: studentId,
          moduleCode: modCode,
          certCode: certCode,
          fileName: "CoverSheet.pdf",
      };
  
      const fallbackPdf = coverSheet; // Local fallback PDF file
  
      let pdf;
      try {
          // Fetch the PDF using the hook
          const { pdf: fetchedPdf, msg, msgCode } = await fetchPdf(
              "assessment/coverSheet",
              postData,
              fallbackPdf
          );
  
          if (msgCode === 1) {
              // Success - PDF fetched from API
              pdf = fetchedPdf;
          } else {
              // If there's an error or fallback, log the message
              console.warn("API response:", msg);
              pdf = await getDocument(fallbackPdf).promise; // Load local fallback PDF if needed
          }
      } catch (error) {
          // If any errors occur during PDF fetching, fallback to local PDF
          console.error("Error fetching PDF, loading local fallback:", error);
          pdf = await getDocument(fallbackPdf).promise;
      }
  
      // Render the PDF (assuming you already have `pdf` object from above)
      try {
          const page = await pdf.getPage(1); // Get the first page of the PDF
          const A4_ASPECT_RATIO = 1.414; // A4 aspect ratio (height/width)
          const container = pdfCanvasRef.current.parentNode;
  
          const containerWidth = container.offsetWidth;
          const containerHeight = containerWidth * A4_ASPECT_RATIO;
  
          const devicePixelRatio = window.devicePixelRatio || 1;
          const scale = containerWidth / page.getViewport({ scale: 2 }).width;
  
          const viewport = page.getViewport({ scale });
  
          // Set up the canvas for rendering
          const canvas = pdfCanvasRef.current;
          const context = canvas.getContext("2d");
  
          canvas.width = viewport.width * devicePixelRatio;
          canvas.height = viewport.height * devicePixelRatio;
          canvas.style.width = `${containerWidth}px`;
          canvas.style.height = `${containerHeight}px`;
  
          // Set scaling for the context
          context.scale(devicePixelRatio, devicePixelRatio);
  
          // Render the PDF page onto the canvas
          const renderContext = {
              canvasContext: context,
              viewport: page.getViewport({ scale }),
          };
  
          renderTaskRef.current = page.render(renderContext);
          await renderTaskRef.current.promise; // Wait for the rendering to finish
          renderTaskRef.current = null; // Clear the render task once done
  
          // If it's the initial render, store the dimensions of the canvas
          if (initialPdfCanvasDimensions.width === 0) {
              setInitialPdfCanvasDimensions({
                  width: viewport.width * scale,
                  height: viewport.height * scale,
              });
          }
      } catch (error) {
          if (error.name !== "RenderingCancelledException") {
              console.error("Error rendering page:", error);
          } else {
              console.log("Rendering was cancelled:", error);
          }
      }
    };
  

    const debounce = (func, wait) => {
      let timeout;
      return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), wait);
      };
    };

    const updateCanvasSize = debounce(async () => {
      if (pdfCanvasRef.current) {
        const containerWidth = pdfCanvasRef.current.parentNode.offsetWidth;
        const A4_ASPECT_RATIO = 1.414;
        const containerHeight = containerWidth * A4_ASPECT_RATIO;
    
        pdfCanvasRef.current.style.width = `${containerWidth}px`;
        pdfCanvasRef.current.style.height = `${containerHeight}px`;
    
        await loadPdf(); // Reload the PDF to match new dimensions
      }
    }, 200);
    

    useEffect(() => {
      // Initial update
      updateCanvasSize();

      // Add event listener to resize
      window.addEventListener("resize", updateCanvasSize);

      // Clean up function to remove the event listener
      return () => {
        if (renderTaskRef.current) {
          renderTaskRef.current.cancel();
        }
        window.removeEventListener("resize", updateCanvasSize);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coverSheet]);

    const handleDateChange = (date, name) => {
      setDates({ ...dates, [name]: date });
    };

    const handleInputChange = (e, name) => {
      setInputValues({
        ...inputValues,
        [name]: e.target.value,
      });
    };

    const uploadFile = (file) => {
      return new Promise((resolve, reject) => {
        const formData = new FormData(); // Correctly instantiate FormData
        formData.append("file", file, file.name); // Append the file
        formData.append("studentNumber", studentId); // Append the student number
        formData.append("moduleCode", modCode); // Append the module code
        formData.append("certCode", certCode); // Append the certificate code

        const req = new XMLHttpRequest();

        req.onload = () => {
          if (req.status >= 200 && req.status < 300) {
            resolve(true);
          } else {
            reject(`Failed to upload file. Status: ${req.status}`);
          }
        };

        req.onerror = () => {
          reject("Failed to upload file.");
        };

        req.open(
          "POST",
          "https://api.trainingprofessionals.com.au/assessment/upload"
        );
        req.send(formData);
      });
    };

    const generatePDFForStorage = async (pdf, canvas, format = 'A4', dpi = '300', scale = 1) => {
      try {
        const { width, height } = calculatePaperSize(format, dpi);
        const page = await pdf.getPage(1);
        const viewport = page.getViewport({ scale });
        const renderScale = Math.min(width / viewport.width, height / viewport.height);
        const scaledViewport = page.getViewport({ scale: renderScale });

        const context = canvas.getContext("2d");
        context.clearRect(0, 0, canvas.width, canvas.height);

        const renderContext = {
          canvasContext: context,
          viewport: scaledViewport,
        };
        await page.render(renderContext).promise;
        console.log("PDF rendered successfully for storage!");
      } catch (error) {
        console.error("Error generating PDF for storage:", error);
        throw error;
      }
    };

    useImperativeHandle(ref, () => ({
      saveSignature,
    }));

    const saveSignature = async () => {
      const dpr = window.devicePixelRatio || 2;
      const scaleFactor = 4;
    
      // Create a new canvas for the combined signature and PDF
      const combinedCanvas = document.createElement("canvas");
      const combinedContext = combinedCanvas.getContext("2d");
    
      if (showMobile) {
        console.log("Rendering high-res PDF for mobile (A4 dimensions)...");
    
        // A4 dimensions at 300 DPI
        const A4_WIDTH = 2480; // pixels
        const A4_HEIGHT = 3508; // pixels
    
        // Set canvas to A4 size
        combinedCanvas.width = A4_WIDTH;
        combinedCanvas.height = A4_HEIGHT;
    
        // Re-render the PDF at high resolution matching A4 size
        const pdf = await getDocument(coverSheet).promise;
        const page = await pdf.getPage(1);
        const viewport = page.getViewport({ scale: 1 }); // Base scale
    
        // Scale to fit the A4 dimensions
        const scale = Math.min(
          A4_WIDTH / viewport.width,
          A4_HEIGHT / viewport.height
        );
    
        await page.render({
          canvasContext: combinedContext,
          viewport: page.getViewport({ scale }),
        }).promise;
    
        // Define signature placement percentages
        const signatureWidthPercent = 0.2; // Width as a percentage of A4 width
        const signatureHeightPercent = 0.1; // Height as a percentage of A4 height
        const signatureXPercent = 0.57; // Horizontal position as percentage
        const signatureYPercent = 0.35; // Vertical position as percentage
    
        // Calculate signature dimensions and position
        const signatureWidth = A4_WIDTH * signatureWidthPercent;
        const signatureHeight = A4_HEIGHT * signatureHeightPercent;
        const signatureX = A4_WIDTH * signatureXPercent;
        const signatureY = A4_HEIGHT * signatureYPercent;
    
        // Draw the signature image from state on the canvas
        if (mobileSignature) {
          const img = new Image();
          img.src = mobileSignature;
    
          await new Promise((resolve) => {
            img.onload = () => {
              combinedContext.drawImage(
                img,
                signatureX,
                signatureY,
                signatureWidth,
                signatureHeight
              );
              resolve();
            };
          });
        } else {
          console.error("No signature image found in state!");
        }
      } else {
        console.error("We should not be here!");
      }
    
      // Add annotations (input values, checkboxes, etc.)
      annotations.forEach((annotation, index) => {
        if (
          annotation.name === "SignHere" ||
          annotation.name === "SignatureBox"
        )
          return;
    
        // A4 dimensions at 300 DPI
        const A4_WIDTH = 2480; // pixels
        const A4_HEIGHT = 3508; // pixels
    
        const fontSize = `${calculateFontSize(pdfCanvasHeight, dpr, annotation.label, annotation.input) * scaleFactor}px`;

        // Calculate the position of the annotation as a % of the canvas size
        const annotationXPercent = annotation.x / 100;
        const annotationYPercent = annotation.y / 100; 
        const yOffset = -0.005;
  
        const x = A4_WIDTH * annotationXPercent;
        const y = A4_HEIGHT * (annotationYPercent + yOffset);
    
        let inputValue = "";
    
        if (annotation.input === "checkbox") {
          const isChecked = inputRefs.current[index]?.checked;
          inputValue = isChecked ? "✓" : "";
        } else if (annotation.input === "date") {
          inputValue = dates[annotation.name]
            ? dayjs(dates[annotation.name]).format("DD/MM/YYYY")
            : "";
        } else if (!annotation.input) {
          inputValue = annotation.label;
        } else {
          inputValue = inputRefs.current[index]?.value || "";
        }
    
        combinedContext.font = `${annotation.fontWeight} ${fontSize} sans-serif`;
        combinedContext.fillStyle = annotation.color;
        combinedContext.fillText(inputValue, x, y);
      });
    
      // Create the final PDF
      const imgData = combinedCanvas.toDataURL("image/png", 1.0);
      const pdf = new jsPDF("portrait", "pt", [
        combinedCanvas.width,
        combinedCanvas.height,
      ]);
      pdf.addImage(
        imgData,
        "PNG",
        0,
        0,
        combinedCanvas.width,
        combinedCanvas.height
      );
    
      // Save the PDF as a Blob and upload
      const pdfBlob = pdf.output("blob");
      const file = new File([pdfBlob], "completed_coversheet.pdf", {
        type: "application/pdf",
      });
    
      try {
        const response = await uploadFile(file);
        console.log("File uploaded successfully:", response);
    
        if (response) {
          const endpoint = "assessment/updateStatus";
          const postData = {
            studentId: studentId,
            apprenticeModId: apprenticeModsId,
            moduleId: modId,
            isSigned_Student: true,
            status: "Started",
          };
    
          await fetchApi(endpoint, postData);
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    };


    return (
      <div>
        <div>
          <div className="mobile-canvas-container" >
            <canvas className='mobile-pdf-canvas' ref={pdfCanvasRef} />

            {annotations.map((annotation, index) => {
              const dpr = window.devicePixelRatio || 1;
              const fontSize = `${calculateFontSize(pdfCanvasHeight, dpr, annotation.label, annotation.input)}px`;
              return (
                <div
                  key={index}
                  style={{
                    position: "absolute",
                    top: `${annotation.y + 0.5}%`,
                    left: `${annotation.x - 0.8}%`,
                    transform: "translateY(-100%)",
                    zIndex: annotation.input ? 3 : 1,
                    display: "flex",
                    alignItems: "center",
                    alignContent: "start",
                    userSelect: "none",
                    fontSize,
                    fontWeight: annotation.fontWeight,
                    color: annotation.color,
                    width: `${annotation.width}%`,
                    maxWidth: annotation.name === 'ModName' ? 'none' : '100px',
                  }}
                >
                  {annotation.input ? (
                    annotation.input === "checkbox" ? (
                      <input
                        type="checkbox"
                        defaultChecked={false} // Temporarily checked for testing
                        className="custom-form-checkbox"
                        ref={(el) => (inputRefs.current[index] = el)} // Maintain reference like other inputs
                      />
                    ) : annotation.input === "date" ? (
                      <DatePicker
                        disabled
                        style={{}}
                        selected={dates[annotation.name]}
                        onChange={(date) =>
                          handleDateChange(date, annotation.name)
                        }
                        dateFormat="dd/MM/yyyy"
                        dropdownMode="select"
                        popperPlacement="top-center"
                        placeholderText="Select Date"
                        showYearDropdown // Enables year dropdown
                        scrollableYearDropdown // Makes year dropdown scrollable (optional)
                        yearDropdownItemNumber={15} // Number of years shown in the dropdown, default is 5
                        customInput={
                          <input
                            disabled
                            type="text"
                            style={{
                              position: "relative",
                              zIndex: 3,
                              border: "none",
                              backgroundColor: "transparent",
                              fontSize,
                              alignContent: "start",
                              alignSelf: "start",
                              alignItems: "start",
                              fontWeight: annotation.fontWeight,
                              color: annotation.color,
                              outline: "none",
                              boxShadow: "none",
                              cursor: "pointer",
                              maxWidth: "100px",
                            }}
                            ref={(el) => (inputRefs.current[index] = el)}
                          />
                        }
                        wrapperClassName="custom-datepicker-wrapper"
                        popperClassName="custom-datepicker-popper"
                      />
                    ) : (
                      <input
                        disabled
                        type="text"
                        placeholder={annotation.label}
                        value={inputValues[annotation.name]} // Controlled input value
                        onChange={(e) => handleInputChange(e, annotation.name)} // Handle change to update state
                        style={{
                          postition: "relative",
                          zIndex: 3,
                          border: "none",
                          backgroundColor: "transparent",
                          fontSize, // Apply dynamic font size
                          fontWeight: annotation.fontWeight,
                          color: annotation.color,
                          boxShadow: "none",
                          outline: "none",
                        }}
                        ref={(el) => (inputRefs.current[index] = el)} // Update ref
                      />
                    )
                  ) : annotation.name === "SignatureBox" ? (
                    <div
                      style={{
                        width: `${
                          ((annotation.width / 100) * pdfCanvasWidth) / dpr
                        }px`,
                        height: `${
                          ((annotation.height / 100) * pdfCanvasHeight) / dpr
                        }px`,
                        zIndex: 4,
                        position: "relative",
                      }}
                    >
                      <button
                        className='signature-button'
                        onClick={() => {
                          onSignButtonClick();
                        }}
                      >
                        Click to Sign
                      </button>
                    </div>
                  ) : (
                    <span
                      style={{
                        color: annotation.color,
                        fontSize: fontSize,
                        fontWeight: annotation.fontWeight,
                        zIndex: 1,
                      }}
                    >
                      {annotation.label}
                    </span>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
);

export default MobilePDFHandler;
