import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import UserInterface from '../../Types/userInterface';
import CryptoJS from 'crypto-js';

const SECRET_KEY = 'your_secret_key'; // TO DO: Replace with a real key and move to .env

export const initialState: UserInterface = {
  dob: '',
  usi: '',
  email: '',
  ipAddress: '',
  username: 'Guest',
  fullName: '',
  phoneNumber: '',
  user_id: '',
  roleId: null,
  accessLevel: null, 
  roles: [],
  loggedIn: false,
};

// Encrypt the full user data object
const encryptData = (data: any) => {
  try {
    return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
  } catch (error) {
    console.error('Encryption error:', error);
    return '';
  }
};

// Decrypt the full user data object
const decryptData = (cipherText: string) => {
  try {
    const bytes = CryptoJS.AES.decrypt(cipherText, SECRET_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error('Decryption error:', error);
    return null;
  }
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserInterface>) => {
      const userData = action.payload;
      // Set the full user data in the Redux Store
      state.dob = userData.dob;
      state.usi = userData.usi;
      state.email = userData.email;
      state.ipAddress = userData.ipAddress;
      state.username = userData.username;
      state.fullName = userData.fullName;
      state.user_id = userData.user_id;
      state.roleId = userData.roleId;
      state.phoneNumber = userData.phoneNumber;
      state.accessLevel = userData.accessLevel;
      state.roles = userData.roles;
      state.loggedIn = true;

      // Encrypt and store the entire user data object in sessionStorage
      sessionStorage.setItem('user', encryptData(userData));
    },
    restoreUser: (state) => {
      const encryptedUser = sessionStorage.getItem('user');

      if (encryptedUser) {
        const decryptedUser = decryptData(encryptedUser);

        if (decryptedUser) {
          state.dob = decryptedUser.dob;
          state.usi = decryptedUser.usi;
          state.email = decryptedUser.email;
          state.ipAddress = decryptedUser.ipAddress;
          state.username = decryptedUser.username;
          state.fullName = decryptedUser.fullName;
          state.phoneNumber = decryptedUser.phoneNumber;
          state.accessLevel = decryptedUser.accessLevel;
          state.user_id = decryptedUser.user_id;
          state.roleId = decryptedUser.roleId;
          state.roles = decryptedUser.roles;
          state.loggedIn = true;
        }
      }
    },
    clearUser: () => {
      sessionStorage.removeItem('user');

      return {
        ...initialState,
        loggedIn: false,
      };
    },
    updateUserField: <K extends keyof UserInterface>(
      state: UserInterface,
      action: PayloadAction<{ field: K; value: UserInterface[K] }>
    ) => {
      state[action.payload.field] = action.payload.value;
    },
  },
});

export const { setUser, restoreUser, clearUser, updateUserField } = userSlice.actions;
export default userSlice.reducer;
