import { useSelector } from "react-redux";
import { RootState } from "../State/Reducers";
import UserInterface from "../Types/userInterface";

export const useUserDeets = () => {
   return useSelector((state: RootState) => state.user as UserInterface);
};

export const useUserField = <T extends keyof UserInterface>(field: T): UserInterface[T] => {
  return useSelector((state: RootState) => state.user[field]);
};