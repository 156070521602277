import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FormData {
  [key :string]: any;
}

interface FeedbackFormState {
  formData: Record<string, FormData>;
}

const initialState: FeedbackFormState = {
  formData: {},
};

const feedbackFormSlice = createSlice({
  name: 'feedbackForm',
  initialState,
  reducers: {
    updateFormData: (state, action: PayloadAction<{ moduleId: string; data: Record<string, any> }>) => {
      const { moduleId, data } = action.payload;
      state.formData[moduleId] = {
        ...state.formData[moduleId],
        ...data,
      };
    },
    resetFormData: (state) => {
      state.formData = {};
    },
  },
});



export const { updateFormData, resetFormData } = feedbackFormSlice.actions;
export default feedbackFormSlice.reducer;