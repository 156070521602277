import React, { useRef, useState, useEffect } from 'react';
import { SpacerDiv, MobileModal } from 'Components';
import { usePreventScroll } from 'CustomHooks';

import '../Styles/mobileSignature.scss';

interface SignatureProps {
  showCanvas: boolean,
  /* eslint-disable-next-line no-unused-vars */
  onSave: (signatureDataUrl: string) => void;
}

const Signature: React.FC<SignatureProps> = ({ onSave, showCanvas }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [modalOpen, setModalOpen] = useState(showCanvas);
  const [signature, setSignature] = useState<string>('');
  const [hasDrawn, setHasDrawn] = useState(false);
  const [canvasDimensions, setCanvasDimensions] = useState({ width: 0, height: 0 });

  const updateCanvasDimensions = () => {
    if (canvasRef.current?.parentNode) {
      const parent = canvasRef.current.parentNode as HTMLElement;
      const parentWidth = parent.clientWidth;
      const parentHeight = parent.clientHeight;
      const width = parentWidth * 0.95;
      const height = parentHeight * 0.65;
  
      if (canvasRef.current) {
        canvasRef.current.width = width;
        canvasRef.current.height = height;
      }
  
      if (canvasDimensions.width !== width || canvasDimensions.height !== height) {
        setCanvasDimensions({ width, height });
      }
    } else {
      console.warn('Parent node not found');
    }
  };
  
  usePreventScroll(modalOpen);

  useEffect(() => {
    setModalOpen(showCanvas);

    if (showCanvas) {
      setTimeout(() => {
        updateCanvasDimensions();
      }, 50);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCanvas]);

  useEffect(() => {
    if (modalOpen) {
  
      if (canvasRef.current && canvasDimensions.width > 0 && canvasDimensions.height > 0) {
        const context = canvasRef.current.getContext('2d');
  
        if (context) {
          // Clear the canvas
          context.clearRect(0, 0, canvasDimensions.width, canvasDimensions.height);
  
          context.strokeStyle = '#d0d0d0';
          context.lineWidth = 4;
          context.beginPath();
          context.moveTo(
            canvasDimensions.width * 0.15,
            canvasDimensions.height * 0.75
          );
          context.bezierCurveTo(
            canvasDimensions.width * 0.3,
            canvasDimensions.height * 0.9,
            canvasDimensions.width * 0.7,
            canvasDimensions.height * 0.7,
            canvasDimensions.width * 0.8,
            canvasDimensions.height * 0.8 
          );

          context.stroke();
          context.closePath();

          document.fonts.load('32px "Pacifico"').then(() => {
            context.save();
            context.translate(
              canvasDimensions.width * 0.16,
              canvasDimensions.height * 0.7
            );
            context.font = 'italic 60px "Pacifico", cursive';
            context.fillStyle = '#d0d0d0';
            context.fillText('Sign here', 0, 0);
            context.restore();
          });
  
          setHasDrawn(false); // Reset the hasDrawn state
        }
      }
    }
  }, [modalOpen, canvasDimensions]);
  

  const startDrawing = (event: React.MouseEvent | React.TouchEvent) => {
    if (canvasRef.current && !hasDrawn) {
      const context = canvasRef.current.getContext('2d');
      if (context) {
        context.clearRect(0, 0, canvasDimensions.width, canvasDimensions.height); // Clear predefined content
        context.beginPath(); // Reset the path ready for the user
      }
    }
    setIsDrawing(true);
    setHasDrawn(true); // User has started drawing
    draw(event); // Start drawing immediately
  };

  const endDrawing = () => {
    setIsDrawing(false);
    if (canvasRef.current) {
      canvasRef.current.getContext('2d')?.beginPath(); // Reset path for future drawing
    }
  };

  const draw = (e: React.MouseEvent | React.TouchEvent) => {
    if (!isDrawing || !canvasRef.current) return;

    const context = canvasRef.current.getContext('2d');
    if (!context) return;

    const rect = canvasRef.current.getBoundingClientRect();
    const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
    const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;

    context.lineWidth = 4;
    context.lineCap = 'round';
    context.strokeStyle = '#000000';

    context.lineTo(clientX - rect.left, clientY - rect.top);
    context.stroke();
    context.beginPath();
    context.moveTo(clientX - rect.left, clientY - rect.top);
  };

  const clearCanvas = () => {
    if (canvasRef.current) {
      const context = canvasRef.current.getContext('2d', { willReadFrequently: true });
      if (!context) return;
  
      context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      setHasDrawn(false); // Reset the drawing state
  
      // Redraw the "Sign here" text and flourish
      context.strokeStyle = '#d0d0d0';
      context.lineWidth = 4;
      context.beginPath();
      context.moveTo(
        canvasDimensions.width * 0.15,
        canvasDimensions.height * 0.75
      );
      context.bezierCurveTo(
        canvasDimensions.width * 0.3,
        canvasDimensions.height * 0.9,
        canvasDimensions.width * 0.7,
        canvasDimensions.height * 0.7,
        canvasDimensions.width * 0.8,
        canvasDimensions.height * 0.8 
      );

      context.stroke();
      context.closePath();

      document.fonts.load('32px "Pacifico"').then(() => {
        context.save();
        context.translate(
          canvasDimensions.width * 0.16,
          canvasDimensions.height * 0.7
        );
        context.font = 'italic 60px "Pacifico", cursive';
        context.fillStyle = '#d0d0d0';
        context.fillText('Sign here', 0, 0);
        context.restore();
      });
    }
  };
  

  const handleSaveClick = () => {
    if (canvasRef.current) {
      const signatureDataUrl = canvasRef.current.toDataURL();
      setSignature(signatureDataUrl);
      setModalOpen(false);
      onSave(signatureDataUrl);
    }
  };

  return (
    <div>
      <div onClick={() => setModalOpen(true)}>
        {signature ? (
          <img 
            className='signature-preview'
            src={signature} 
            alt="User's signature" 
          />
        ) : (
          <textarea 
            className='signature-textbox'
            value='Touch here to sign'
            onChange={() => {}}  
          />
        )}
      </div>
      
      <MobileModal 
        className='mobile-signature-modal'
        isOpen={modalOpen} 
        onClose={() => setModalOpen(false)}
        withButton={false}
      >
        
          <canvas
            ref={canvasRef}
            width={canvasDimensions.width}
            height={canvasDimensions.height}
            className='mobile-signature-canvas'
            onMouseDown={startDrawing}
            onMouseUp={endDrawing}
            onMouseMove={draw}
            onTouchStart={startDrawing}
            onTouchEnd={endDrawing}
            onTouchMove={draw}
          />

          <div className='flex-container'>
            <button 
              className='btn-primary' 
              style={{ width: '150px', alignSelf: 'flex-start' }} 
              onClick={clearCanvas}>
              CLEAR
            </button>
            <button className='btn-primary' style={{ width: '150px', marginBottom: '5px' }} onClick={handleSaveClick} disabled={!hasDrawn}>
              SAVE
            </button>
          </div>

          <SpacerDiv rem={1} />
          <p style={{ visibility: hasDrawn ? 'hidden' : 'visible' }}>
            Please sign
          </p>



      </MobileModal>
    </div>
  );
};

export default Signature;


