import React from 'react';
import { Form } from 'Components';

// Hardcoded data for testing
import Data from "TestingDataObjects/new-preenrolment-form-data.json";

interface PreEnrolmentProps {}

const PreEnrolment: React.FC<PreEnrolmentProps> = () => {
  return (
    <div className='preenrolment-container'>
     <Form formData={Data}/>
    </div>
  );
};

export default PreEnrolment;