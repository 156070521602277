import { useEffect, useState } from "react";

export const usePreventScroll = (preventScroll: boolean) => {
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  useEffect(() => {
    // Detect touch capabilities
    const hasTouchSupport = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    setIsTouchDevice(hasTouchSupport);
  }, []);

  useEffect(() => {
    if (!isTouchDevice) return; // Only apply for touch devices

    const preventScrollFunc = (e: Event) => e.preventDefault();

    if (preventScroll) {
      document.addEventListener("wheel", preventScrollFunc, { passive: false });
      document.addEventListener("touchmove", preventScrollFunc, { passive: false });
    } else {
      document.removeEventListener("wheel", preventScrollFunc);
      document.removeEventListener("touchmove", preventScrollFunc);
    }

    return () => {
      document.removeEventListener("wheel", preventScrollFunc);
      document.removeEventListener("touchmove", preventScrollFunc);
    };
  }, [preventScroll, isTouchDevice]);
};
