import { useDispatch } from 'react-redux';
import { setHistory, clearHistory } from '../State/Reducers/studentHistorySlice';

export const useStudentHistoryData = () => {
    const dispatch = useDispatch();

    const setStudentHistoryData = (data: any) => {
        dispatch(setHistory(data));
    };

    const clearStudentHistoryData = () => {
        dispatch(clearHistory());
    }

    return { setStudentHistoryData, clearStudentHistoryData };
};
