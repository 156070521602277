import React from 'react';
import { matchPath, Outlet, useLocation  } from 'react-router-dom';
import * as Pages from '../../Pages';
// List of pages that are under construction
import { underConstructionPages } from 'Constants/constants';

const UnderConstructionWrapper: React.FC = () => {
  const location = useLocation();

  const isUnderConstruction = underConstructionPages.some((path) =>
    matchPath(path, location.pathname) 
  );

  if (isUnderConstruction) {
    return <Pages.Construction route={location.pathname} />;
  }

  return <Outlet />;
};

export default UnderConstructionWrapper;
