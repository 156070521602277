import React from 'react';

interface FormsLandingProps {}

const FormsLanding: React.FC<FormsLandingProps> = () => {
  return (
    <div className='-container'>
     FormsLanding component
    </div>
  );
};

export default FormsLanding;