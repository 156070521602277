import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import LoginComponent from '../Components/LoginComponent';
import Bluey  from '../Components/Bluey';


export default function LoginPage() {

    useEffect(() => {
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => window.sessionStorage.setItem("ipAddress", data.ip))
            .catch(error => console.error('Error fetching IP:', error));
    }, []);


    return (

        <div

            style={{
                height: '100vh',
                width: '100%',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                position: 'relative',
                
            }}
        >
            < Bluey />

            <div
                style={{
                    position: 'relative',
                    height: '100vh',
                    width: '100%',

                }}
            >

                <div>
                    <LoginComponent />
                </div>
            </div>
        </div>
    )
}
