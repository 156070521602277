import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SignatureState {
  name: string;
  signature: string | null;
  date: string | null;
};

const initialState: SignatureState = {
  name: '',
  signature: null,
  date: new Date().toISOString(),
};

const signatureSlice = createSlice({
  name: 'signature',
  initialState,
  reducers: {
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setSignature: (state, action: PayloadAction<string>) => {
      state.signature = action.payload;
    },
    setDate: (state, action: PayloadAction<string>) => {
      state.date = action.payload;
    },
    clearSignatureState: () => initialState,
  },
});

export const { setDate, setSignature, setName, clearSignatureState } = signatureSlice.actions;
export default signatureSlice.reducer;