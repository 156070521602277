import React from 'react';
import { Alert } from '@mui/material';

const CustomAlert = ({ msg, msgCode }) => {
    const alertStyles = {
        display: "flex",
        // maxWidth: "600px",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        textAlign: "center",
        margin: "25px 0",
    };

    return (
        <div>
            {msgCode === 4 && (
                <Alert severity="error" style={alertStyles}>
                    <strong style={{ fontSize: "18px" }}>{msg}</strong>
                </Alert>
            )}
            {msgCode === 3 && (
                <Alert severity="warning" style={alertStyles}>
                    <strong style={{ fontSize: "18px" }}>{msg}</strong>
                </Alert>
            )}
            {msgCode === 2 && (
                <Alert severity="info" style={alertStyles}>
                    <strong style={{ fontSize: "18px" }}>{msg}</strong>
                </Alert>
            )}
            {msgCode === 1 && (
                <Alert severity="success" style={alertStyles}>
                    <strong style={{ fontSize: "18px" }}>{msg}</strong>
                </Alert>
            )}
        </div>
    );
};

export default CustomAlert;