import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { MDBContainer } from "mdb-react-ui-kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { SpacerDiv, Alert as CustomAlert } from "Components";
import useFetchApi from "../Services/FetchApi";
import PasswordVerificationModal from "./PasswordVerification";
import PasswordSetupModal from "./PasswordSetupModal";
import { useUserData, useStudentHistoryData, useLogout } from "CustomHooks";

import "bootstrap/dist/css/bootstrap.css";
import "../Styles/index.css";
import "../Components/Styles/LoginComponent.scss";

const LoginComponent = ({ isModal = false }) => {
  const navigate = useNavigate();
  const fetchApi = useFetchApi();
  const { setUserData } = useUserData();
  const { setStudentHistoryData } = useStudentHistoryData();
  const { logout } = useLogout();

  const [inputs, setInputs] = useState({ longExpiry: false });
  const [msg, setMsg] = useState(window.sessionStorage.getItem("message"));
  const [msgCode, setMsgCode] = useState(0);
  const [socialMediaName, _setSocialMediaName] = useState("");
  const [socialMediaBlurb, _setSocialMediaBlurb] = useState("");
  const [showPasswordSetupModal, setShowPasswordSetupModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const passwordRef = useRef(null);

  const handleLogout = () => {
    logout();
  };

  const handleLogin = (data) => {
    setUserData(data); // This also sets logged in to TRUE
    fetchStudentHistory(data.role_id);
    navigate("/study");
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  // const handleOpenModal = (mediaName, modal) => {

  //     // Set the social media name and any other dynamic info
  //     setSocialMediaName(mediaName);

  //     if (modal === 'verify') {
  //         setShowPasswordModal(true);
  //     } else if (modal === 'setup') {
  //         setShowPasswordSetupModal(true);
  //     }

  // };

  function togglePasswordVisibility() {
    const passwordInput = document.querySelector('[name="password"]');
    if (!showPassword) {
      passwordInput.setAttribute("type", "text");
    } else {
      passwordInput.setAttribute("type", "password");
    }
    setShowPassword(!showPassword);
  }

  const fetchStudentHistory = (data) => {
    fetchApi("student/student_history", { roleId: data })
      .then((response) => {
        switch (true) {
          case response.status >= 200 && response.status < 300:
            setStudentHistoryData(response.responseData.student_history);
            setMsgCode(4);
            break;
          default:
            setMsg(response.msg);
            setMsgCode(1);
            break;
        }
      })
      .catch((error) => {
        console.error("Fetch failed or fetch function is unreachable:", error);
        setMsg(
          "An unexpected error occurred. Contact your administrator" + error
        );
        setMsgCode(1);
      });
  };

  //    const handleGoogleLogin = () => {
  //         // const GoogleAuth = window.gapi.auth2.getAuthInstance();
  //         //
  //         // GoogleAuth.signIn().then((googleUser) => {
  //         //     const profile = googleUser.getBasicProfile();
  //         //     const id_token = googleUser.getAuthResponse().id_token;
  //         //
  //         //     console.log(profile)
  //         //
  //         //     // Similar to your Facebook logic, make a POST request with this token
  //         //     fetch('https://localhost:3001/oauth/google_login', {
  //         //         method: 'POST',
  //         //         headers: {
  //         //             'Content-Type': 'application/json',
  //         //         },
  //         //         body: JSON.stringify({ idToken: id_token }),
  //         //     })
  //         //         .then(response => response.json().then(data => {
  //         //             // Your logic for handling response goes here
  //         //             // Similar switch case as you did for Facebook
  //         //         }))
  //         //         .catch(error => console.error('Error posting Google login data:', error));
  //         // }, (error) => {
  //         //     console.log('Google sign in error', error);
  //         //     // Handle sign in errors here
  //         // });
  //     };

  // const handleMicrosoftLogin = () => {
  //     // Logic to handle Microsoft login
  // };

  // Function that makes a POST request to the TP API with the Access token for a more secure data retrieval from Facebook
  // const handleFacebookLogin = () => {

  //     // Initialization remains the same...
  //     window.FB.init({
  //         appId      : process.env.REACT_APP_FB_APP_ID,
  //         cookie     : true,  // Enable cookies to allow the server to access the session
  //         xfbml      : true,  // Parse social plugins on this webpage
  //         version    : 'v10.0' // Use version 10.0 of the Facebook API
  //     });

  //     window.FB.login((loginResponse) => {
  //         if (loginResponse.authResponse) {

  //             // Send the accessToken in the body of a POST request
  //             const accessToken = loginResponse.authResponse.accessToken;

  //             fetchApi('oauth/facebook_login', accessToken)
  //                 .then(response => {

  //                     console.log('Fetch Response:', response); // Log the response for debugging. === DELETE WHEN IMPLEMENTED ===

  //                     switch ( response.status ) {
  //                         case 200:
  //                             handleLogin(response.responseData);
  //                             setMsgCode(0);
  //                             break;
  //                         case 201:
  //                             handleLogin(response.responseData);
  //                             // setSocialMediaName(mediaName);
  //                             // setShowPasswordSetupModal(true); // Show the modal to ask for the user's password
  //                             handleOpenModal('Facebook', 'setup')
  //                             setMsgCode(0);
  //                             break;
  //                         default:
  //                             setMsg(response.msg);
  //                             setMsgCode(4);
  //                             break;
  //                     }
  //                 })
  //                 .catch(error => {
  //                         console.error('Fetch failed or fetch function is unreachable:', error);
  //                         setMsg('An unexpected error occurred. Contact your administrator' + error);
  //                         setMsgCode(4);
  //                     }
  //                 );

  //         } else {
  //             console.log('Failed to log in with facebook SDK.');
  //             logError({
  //                 page: location.pathname,
  //                 dataSubmitted: 'Uses Facebook SDK to log in',
  //                 errorDesc: 'Failed to login user with facebook SDK, // Detailed description of the error',
  //                 endpoint: 'Facebook SDK',
  //             }).then(response => {
  //                 console.log('Server responded with:', response);
  //                 setMsg(`Facebook authorisation failed. Event ID: ${response.insertedId}`); // Example of using the response
  //                 setMsgCode(4);
  //             }).catch(err => {
  //                 console.error('Error logging failed:', err);
  //                 setMsg('Failed to communicate with server');
  //                 setMsgCode(4);
  //             });
  //             setMsg('Facebook did not authorize. Please try again or use a different method to sign in.')
  //             setMsgCode(4);
  //         }
  //     }, {scope: 'email, user_link, public_profile, user_birthday'});
  // };

  const handleTPLogin = async (event) => {
    event.preventDefault();

    fetchApi("user/login", inputs)
      .then((response) => {
        switch (true) {
          case response.status >= 200 && response.status < 300:
            handleLogin(response.responseData);
            setMsgCode(0);
            break;
          default:
            setMsg(response.msg);
            setMsgCode(1);
            break;
        }
      })
      .catch((error) => {
        console.error("Fetch failed or fetch function is unreachable:", error);
        setMsg(
          "An unexpected error occurred. Contact your administrator" + error
        );
        setMsgCode(1);
      });
  };

  return (
    <div>
      <div
        style={{
          backgroundImage: `url("")`,
          height: "100vh",
          width: "100%",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <form onSubmit={handleTPLogin}>
          <div 
            className={isModal ? "login-box-modal" : "login-box"}>
            {!isModal && (
              <>
                <SpacerDiv height="10vh" />
                <h1 className="login-header">
                  PLEASE ENTER YOUR USERNAME AND PASSWORD
                </h1>
              </>
            )}

            <CustomAlert msg={msg} msgCode={msgCode} />

            <input
              className="inputBox login-input"
              type="text"
              name="identifier"
              placeholder="USERNAME"
              onChange={handleChange}
              title="Your UserName is the email Address or Phone number you signed up with."
              required
            />

            {/* Password Input */}
            <div style={{ width: "100%", position: "relative" }}>
              <input
                className="inputBox login-input"
                type="password"
                name="password"
                placeholder="PASSWORD"
                onChange={handleChange}
                ref={passwordRef}
                title="Ensure your password has at least 8 characters including 1 uppercase, 1 lowercase, 1 number, and 1 special character"
              />
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                onClick={togglePasswordVisibility}
                className="password-toggle"
              />
            </div>

            <input type="hidden" name="longExpiry" value="false" />

            {/* Button Group */}
            <div className="button-group">
              <button className="btn-primary" type="submit">
                SIGN IN
              </button>
              <button
                className="btn-primary"
                type="button"
                onClick={isModal ? handleLogout : () => navigate("/")}
              >
                CANCEL
              </button>
            </div>
          </div>

          <MDBContainer
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              backgroundColor: "transparent",
            }}
          >
            <div
              className="align-items-end justify-content-end bg-transparent mb-12"
              style={{ height: "1000px" }}
            >
              {/*========== Social media logins (DO NOT DELETE) ===========*/}

              {/*<div style={{ textAlign: 'center', marginTop: '20px' }}>*/}
              {/*    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>*/}
              {/*        <hr style={{ width: '30%', borderTop: '3px solid', margin: '0', paddingBottom: 10 }}/>*/}
              {/*        <span style={{ padding: '0 10px' }}>*/}
              {/*            <h3>OR SIGN IN WITH</h3>*/}
              {/*        </span>*/}
              {/*        <hr style={{ width: '30%', borderTop: '3px solid', margin: 0, paddingBottom: 10 }}/>*/}
              {/*    </div>                                */}

              {/*    <div style={{*/}
              {/*        display: 'flex',*/}
              {/*        justifyContent: 'center',*/}
              {/*        marginTop: '20px'*/}
              {/*    }}>*/}
              {/*        <button type="button" className="fb btn " onClick={handleFacebookLogin} style={{ width: '50px', height: '50px', padding: '0', margin: '5px' }}>*/}
              {/*            <FontAwesomeIcon icon={faFacebook} size="2x"/>*/}
              {/*        </button>*/}
              {/*    */}
              {/*        <button type="button" className="google btn" onClick={handleGoogleLogin} style={{ width: '50px', height: '50px', padding: '0', margin: '5px' }}>*/}
              {/*            <FontAwesomeIcon icon={faGoogle} size="2x"/>*/}
              {/*        </button>*/}
              {/*    */}
              {/*        <button type="button" className="microsoft btn" onClick={handleMicrosoftLogin} style={{ width: '50px', height: '50px', padding: '0', margin: '5px' }}>*/}
              {/*            <FontAwesomeIcon icon={faWindows} size="2x"/>*/}
              {/*        </button>*/}
              {/*   </div>                         */}

              {/*</div>*/}

              {/*========== Social media logins (DO NOT DELETE) ===========*/}

              {/* Password Verification Modal */}
              <PasswordVerificationModal
                show={showPasswordModal}
                onHide={() => setShowPasswordModal(false)}
                // onVerify={(password) => verifyPasswordAndLinkAccount(password)}
                socialMediaBlurb={socialMediaBlurb}
                socialMediaName={socialMediaName}
              />

              {/* Password Setup Modal */}
              {showPasswordSetupModal && (
                <PasswordSetupModal
                  show={showPasswordSetupModal}
                  onHide={() => setShowPasswordSetupModal(false)}
                  // onSubmitPassword={handleSetupPassword}
                  socialMediaBlurb={socialMediaBlurb}
                  socialMediaName={socialMediaName}
                />
              )}
            </div>
          </MDBContainer>
        </form>
      </div>
    </div>
  );
};

export default LoginComponent;

// =============================== To Do =================================== //

// 1. Add the error logging function to the social media login functions

// =============================== Bugs =================================== //
