import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const InterceptRoute = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const id = queryParams.get("id");
  const step_id = queryParams.get("step_id");

  useEffect(() => {
    console.log("=== InterceptRoute Debugging ===");
    console.log(`Full URL: ${location.pathname}${location.search}`);
    console.log(`Extracted ID: ${id}`);
    console.log(`Extracted Step ID: ${step_id}`);

    if (id && location.pathname.includes("/form/")) {
      console.log(`Intercepting Form - ID: ${id}`);
      navigate(`/form/${id}?step_id=${step_id || 1}`, { replace: true });
      // navigate(`/form/${id}`, { replace: true });
      return;
    }
  }, [id, step_id, location.pathname, navigate]);

  return null;
};

export default InterceptRoute;
