import React from 'react';

import "./Styles/stamp.scss"

interface StampProps {
  content?: string;
  colour?: string;
  borderColour?: string;
  backgroundColour?: string;
  className?: string
}

const Stamp: React.FC<StampProps> = ({ content = 'Did you forget something?', colour = '#393939', borderColour = 'transparent', backgroundColour = 'inherit', className}) => {
  return (
    <div className={`stamp-container ${className}`} style={{color: colour, backgroundColor: backgroundColour, borderColor: borderColour}}>
     {content}
    </div>
  );
};

export default Stamp;