import React from 'react';

interface ChatProps {}

const Chat: React.FC<ChatProps> = () => {
  return (
    <div className='-container'>
     Chat component
    </div>
  );
};

export default Chat;