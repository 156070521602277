import React, {useState} from "react";
import logError from "../Services/Logger";
import {useLocation, useNavigate} from "react-router-dom";
// import useFetchApi from "../Services/FetchApi";
import {Form, Modal} from "react-bootstrap";
import {MDBCol, MDBContainer, MDBRow} from "mdb-react-ui-kit";
import CustomAlert from "./CustomAlert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
// import { useUserField } from "CustomHooks";


const PasswordSetupModal = ({ show, onHide, socialMediaName }) => {
    // const isLoggedIn = useUserField("isLoggedIn");

    const location = useLocation();
    const navigate = useNavigate();
    // const fetchApi = useFetchApi();
    // const [inputs, setInputs] = useState({"longExpiry": false});
    const [msg, setMsg] = useState(window.sessionStorage.getItem("message"));
    const [msgCode, setMsgCode] = useState(0)
    // const [accountLinkInfo, setAccountLinkInfo] = useState({user_id: '', email: '', fb_user_id: ''});
    // const [socialMediaName, setSocialMediaName] = useState('');
    // const [socialMediaBlurb, setSocialMediaBlurb] = useState('');
    const [_showPasswordSetupModal, setShowPasswordSetupModal] = useState(false);
    // const [showPasswordModal, setShowPasswordModal] = useState(false);
    // const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [error, setError] = useState('');

    const socialMediaLogo = `${process.env.PUBLIC_URL}/Images/web.png`;
    const siteLogo = `${process.env.PUBLIC_URL}/Images/logo.png`;
    const linkingIcon = `${process.env.PUBLIC_URL}/Images/link.png`;

    const handleRetry = () => {
        handleSetupPassword()
    }

    const handleSetupPassword = () => {
        // e.preventDefault();

        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            setMsg('Passwords do not match.');
            setMsgCode(1);
            return;
        }

        // Call the API to add the password to the user's account.
        fetch('', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                // Removed the Authorization header since the token should be automatically sent with the request via HttpOnly cookies
            },
            credentials: 'include',
            body: JSON.stringify({
                // Adjusted to remove the username from the request payload if it's not needed for identification
                // The server can identify the user based on the token
                "updates": {
                    "password": password
                }
            })
        })
            /* eslint-disable-next-line no-unused-vars */
            .then(response => response.json().then(data => {

                switch (response.status) {
                    case 200:
                        setMsgCode(4);
                        navigate('/profile')
                        setShowPasswordSetupModal(false);
                        break;
                    case 401:
                        console.log('Invalid Token.');
                        setMsg('Token authorization failed. Please login again.')
                        setMsgCode(1);
                        break;
                    case 402:
                        setMsg('Passwords cannot be blank. Please enter a password and try again.')
                        setMsgCode(1);
                        break;
                    case 403:
                        console.log('Bearer token expired. Attempting to refresh.');
                        fetch('', {
                            method: 'POST',
                            credentials: 'include', // Necessary to include cookies if the refresh token is stored in an HttpOnly cookie
                        })
                            .then(refreshResponse => {
                                if (refreshResponse.ok) {
                                    // Assuming the server responds with the new bearer token in the response body or a cookie
                                    console.log('Token refreshed successfully.');
                                    setMsg('Authorization token has been refreshed.');
                                    setMsgCode(2);
                                    // Retry the original request with the new token
                                    handleRetry(); // Call the original function again
                                } else {
                                    console.log('Failed to refresh token.');
                                    setMsg('Session expired. Please log in again.');
                                    setMsgCode(1);
                                    navigate('/login')
                                }
                            })
                            .catch(error => {
                                console.error('Error attempting to refresh token:', error);
                                setMsg('Session expired. Please log in again.');
                                setMsgCode(1);
                                navigate('lLogin')

                                const stackLines = error.stack ? error.stack.split('\n') : [];
                                let errorOnLine = stackLines.length > 1 ? stackLines[1].trim() : ''; // Taking the first relevant line of the stack

                                logError({
                                    page: location.pathname,
                                    data: error.data,
                                    errorCode: error.errorCode,
                                    errorLine: errorOnLine, // Contains the line where the error was thrown if available
                                    error: error.message,
                                    errorDesc: 'Refresh Token not returned.', // Detailed description of the error
                                });
                            });
                        break;
                    case 404:
                        setMsg('Your account could not be found. Please try again or contact support.')
                        setMsgCode(1);
                        break;
                    case 405:
                        setMsg('Your password does not meet the required security level. Please ensure that it' +
                            ' contains at least 8 characters, including at least one uppercase letter, one lowercase ' +
                            'letter, one number, and one special character.')
                        setMsgCode(2);
                        break;
                    case 500:
                        console.log('Internal Error... Probably a server or MySQL issue');
                        setMsg('Something broke real bad. Try again later or contact support.')
                        setMsgCode(1);
                        break;
                    default:
                        console.log('O_o    Sumfin broke real bad Boss!');

                        logError({
                            page: location.pathname,
                            data: error.data,
                            error: error.message,
                            errorCode: error.message,
                            errorDesc: 'errorDesc', //Detailed description of the error
                        });
                }

            }))

            // .catch(err => err);

            .catch(err => {
                logError({

                    page: location.pathname,     // Replace with actual page/component name
                    data: 'Data involved',       // Describe data involved if applicable
                    errorCode: err.message,      // Error message
                    errorDesc: 'Detailed error description', // Detailed error context

                }).then(() => {
                    console.log('Error logged');
                }).catch(logError => {
                    console.error('Failed to log error:', logError);
                });

                return err; // You can still return the error if needed
            });
    };



    // Assuming handleChange handles both password and confirmPassword inputs
    const handleChange = (e) => {
        if (e.target.name === "password") {
            setPassword(e.target.value);
        } else if (e.target.name === "password2") {
            setConfirmPassword(e.target.value);
        }
    };

    const togglePasswordVisibility1 = () => {
        setShowPassword1(!showPassword1);
    };

    const togglePasswordVisibility2 = () => {
        setShowPassword2(!showPassword2);
    };

    return (

        <Modal show={show} onHide={onHide} centered backdrop="static" keyboard={false}>

            {/*<Modal.Header closeButton>*/}
            {/*    <Modal.Title>Set Up Password</Modal.Title>*/}
            {/*</Modal.Header>*/}

            <Modal.Body style={{}}>
                <Form onSubmit={handleSetupPassword}>
                    <MDBContainer style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'transparent'
                    }}>
                        <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%', padding: '20px 0', }}>
                            {/* Site logo */}
                            <img src={siteLogo} alt="Site Logo" style={{ width: 'auto', height: 200 }} />
                            {/* Linking icon */}
                            <img src={linkingIcon} alt="Linking" style={{ width: 'auto', height: 100, marginTop: 50, marginLeft: -20 }} />
                            {/* Social media logo */}
                            <img src={socialMediaLogo} alt="Social Media Logo" style={{ maxWidth: '200px', padding: 10 }} />
                        </div>

                        <div className="align-items-end justify-content-end bg-transparent mb-12" style={{ }}>
                            <h3 style={{textAlign: 'center' }}>PLEASE SET UP A PASSWORD</h3>
                            {/* Blurb explaining why they need to set up a password */}
                            <p style={{textAlign: 'center', marginBottom: 25, color: '#000'}}>
                                Although you will never need to enter your password to log in with {socialMediaName},
                                you will need an account password to link any other social media accounts to your
                                Training Professionals account. If you skip this step you will need to manually provide your social
                                media links for each account you want to link.
                            </p>

                            <CustomAlert msg={msg} msgCode={msgCode} />

                            <div style={{ position: 'relative'}}>
                                <input
                                    type={showPassword1 ? 'text' : 'password'}
                                    name="password"
                                    className="inputBox"
                                    placeholder={"ENTER A PASSWORD"}
                                    onChange={handleChange}
                                    style={{ paddingRight: '40px' }} // Make room for the icon
                                />
                                <FontAwesomeIcon
                                    icon={showPassword1 ? faEyeSlash : faEye}
                                    onClick={togglePasswordVisibility1}
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        right: '15px',
                                        transform: 'translateY(-50%)',
                                        cursor: 'pointer',
                                        color: '#6c757d' // Example color, adjust as needed
                                    }}
                                />
                            </div>

                            <div style={{ position: 'relative', paddingTop: 10 }}>
                                <input
                                    type={showPassword2 ? 'text' : 'password'}
                                    className="inputBox"
                                    placeholder={"CONFIRM YOUR PASSWORD"}
                                    style={{ paddingRight: '40px', fontColor: '#886b26' }} // Make room for the icon
                                    name="password2"
                                    onChange={handleChange}

                                />
                                <FontAwesomeIcon
                                    icon={showPassword2 ? faEyeSlash : faEye}
                                    onClick={togglePasswordVisibility2}
                                    style={{
                                        position: 'absolute',
                                        top: '56%',
                                        right: '15px',
                                        transform: 'translateY(-50%)',
                                        cursor: 'pointer',
                                        color: '#6c757d' // Example color, adjust as needed
                                    }}
                                />
                            </div>

                            <input type="hidden" name="longExpiry" value="false"/>

                            <MDBRow>
                                <MDBCol center>
                                    {/* Change the type to "button" and add an onClick handler */}
                                    <button
                                        style={{ marginTop: 15, marginBottom: 30, color: '#fff'}}
                                        className="btn-primary"
                                        type="button" // Changed from "submit" to "button"
                                        onClick={handleSetupPassword} // Add the click event handler here
                                    >
                                        UPDATE PASSWORD
                                    </button>
                                </MDBCol>
                            </MDBRow>

                        </div>
                    </MDBContainer>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default PasswordSetupModal;