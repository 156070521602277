import React from 'react';

interface UploadLandingProps {}

const UploadLanding: React.FC<UploadLandingProps> = () => {
  return (
    <div className='-container'>
     UploadLanding component
    </div>
  );
};

export default UploadLanding;