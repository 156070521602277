import { useSelector } from "react-redux";

// This a union type of all possible roles in the system
// NOTE: Roles must be added here exactly as they are in the db 
type Role = 
  | "Developer"
  | "QA"
  | "Internal Auditor"
  | "External Auditor"
  | "Moderator"
  | "Validator"
  | "Content Expert"
  | "Candidate"
  | "First Aid Officer"
  | "WHS Officer"
  | "Trainer"
  | "Super User"
  | "User"
  | "Active Trainer"
  | "IT Support"
  | "Admin"
  | "Accounts Officer"
  | "Compliance Officer";

export const useHasRole = (roles: Role[]) => {
  return useSelector((state: { user?: { roles?: Role[] } }) => {
    const userRoles = state.user?.roles || [];
    return roles.some((role) => userRoles.includes(role));
  });
};
