import React from 'react';

interface CalendarProps {}

const Calendar: React.FC<CalendarProps> = () => {
  return (
    <div className='-container'>
     Calendar component
    </div>
  );
};

export default Calendar;