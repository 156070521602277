import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

interface OldWorkflowData {
  workflowName: string;
  currentForm: number;
  currentFormVersion: number;
  nextForm: number;
  nextFormVersion: number;  
  nextFormAvailable: boolean;
}

interface OldWorkflowState {
  oldWorkflowData: OldWorkflowData | null;
}

const initialState: OldWorkflowState = {
  oldWorkflowData: null
};

// Define an async thunk
export const setOldWorkflowDataAsync = createAsyncThunk(
  'workflow/setOldWorkflowDataAsync',
  async (OldWorkflowData: OldWorkflowData, { dispatch }) => {
    // Simulate a delay or some async process if needed
    await new Promise((resolve) => setTimeout(resolve, 0)); // Simulating an async operation
    dispatch(setOldWorkflowData(OldWorkflowData)); // Dispatch the synchronous action
    return OldWorkflowData;
  }
);

const oldWorkflowSlice = createSlice({
  name: 'oldWorkflow',
  initialState,
  reducers: {
    setOldWorkflowData(state, action: PayloadAction<OldWorkflowData>) {
      state.oldWorkflowData = action.payload;
    },
    clearOldWorkflowData(state) {
      state.oldWorkflowData = null;
    }
  }
});

export const { setOldWorkflowData, clearOldWorkflowData } = oldWorkflowSlice.actions;
export default oldWorkflowSlice.reducer;
