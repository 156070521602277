import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../State/Reducers';
import { Alert } from 'Components';

interface ProtectedRouteProps {
  requiredLevel: number;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ requiredLevel }) => {
  const [showAlert, setShowAlert] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const accessLevel = useSelector((state: RootState) => state.user.accessLevel);
  const loggedIn = useSelector((state: RootState) => state.user.loggedIn);
  const navigate = useNavigate();

  const handleClose = () => {
    setShowAlert(false);
    navigate(-1);
  };

  useEffect(() => {
    if (loggedIn && accessLevel !== null) {
      setLoading(false);
    }
  }, [loggedIn, accessLevel]);

  useEffect(() => {
    if (!loading) {
      if (accessLevel === null) {
        navigate('/login');
      } else if (accessLevel < requiredLevel) {
        setShowAlert(true);
      }
    }
  }, [accessLevel, requiredLevel, navigate, loading]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (accessLevel === null || accessLevel < requiredLevel) {
    return (
      <>
        {showAlert && (
          <Alert
            msg="You do not have the required access level to view this page."
            msgCode={3}
            onClose={handleClose}
            timeout={2000}
          />
        )}
      </>
    );
  }

  // Render the protected content if the user has the required access level
  return <Outlet />;
};

export default ProtectedRoute;
