export const annotations = [
    {
        "name": "ModCode",
        "x": 10,
        "y": 31,
        "xOffset": 5,
        "yOffset": 19.5,
        "label": "MOD123",
        "color": "Black",
        // "fontSize": "20px",
        "fontWeight": "bold",
        "timestamp": "2024-09-12 12:34:56"
    },
    {
        "name": "ModName",
        "x": 35,
        "y": 31,
        "xOffset": 22,
        "yOffset": 19.5,
        "label": "Module Name",
        "color": "Black",
        // "fontSize": "20px",
        "fontWeight": "bold",
        "timestamp": "2024-09-12 12:34:56"
    },
    {
        "name": "Name",
        "x": 10,
        "y": 40.8,
        "xOffset": 10,
        "yOffset": 25.5,
        "label": "Enter your name",
        "color": "Black",
        // "fontSize": "14px",
        "fontWeight": "bold",
        "input": "text",
        "timestamp": "2024-09-12 12:34:56"
    },
    {
        name: "DOB",
        x: 33,
        y: 40.8,
        xOffset: 22,
        yOffset: 25.5,
        color: "Black",
        // fontSize: "14px",
        fontWeight: "bold",
        input: "date",
        timestamp: "2024-09-12 12:34:56",
        date: null,
    },
    {
        name: "SignatureBox",
        x: 54,
        y: 39.5,
        xOffset: 57,
        yOffset: 35,
        color: "rgba(255, 255, 0, 0.3)",
        width: 25,
        height: 3,
        timestamp: "2024-09-12 12:34:56",
    },
    {
        name: "Date",
        x: 79,
        y: 40.8,
        xOffset: 52.2,
        yOffset: 25.5,
        color: "Black",
        // fontSize: "14px",
        fontWeight: "bold",
        input: "date",
        timestamp: "2024-09-12 12:34:56",
        date: new Date(),
    },
    {
        name: "TrainerName",
        x: 12,
        y: 53.8,
        xOffset: 0,
        yOffset: 0,
        label: "Enter trainers name",
        color: "Black",
        // fontSize: "14px",
        fontWeight: "bold",
        input: "text",
        timestamp: "2024-09-12 12:34:56",
    },
    {
        name: "DateInput1",
        x: 77,
        y: 53.8,
        xOffset: 0,
        yOffset: 0,
        color: "Black",
        // fontSize: "14px",
        fontWeight: "bold",
        input: "date",
        timestamp: "2024-09-12 12:34:56",
        date: null,
    },
    {
        name: "AssessorName",
        x: 12,
        y: 58.5,
        xOffset: 0,
        yOffset: 0,
        label: "Enter assessors name",
        color: "Black",
        // fontSize: "14px",
        fontWeight: "bold",
        input: "text",
        timestamp: "2024-09-12 12:34:56",
    },
    {
        name: "DateInput2",
        x: 77,
        y: 58.5,
        xOffset: 0,
        yOffset: 0,
        color: "Black",
        // fontSize: "14px",
        fontWeight: "bold",
        input: "date",
        timestamp: "2024-09-12 12:34:56",
        date: null,
    },
    {
        name: "ContentExpert",
        x: 12,
        y: 63.2,
        xOffset: 0,
        yOffset: 0,
        label: "Enter content expert",
        color: "Black",
        // fontSize: "13px",
        fontWeight: "bold",
        input: "text",
        timestamp: "2024-09-12 12:34:56",
    },
    {
        name: "DateInput3",
        x: 77,
        y: 63.2,
        xOffset: 0,
        yOffset: 0,
        color: "Black",
        // fontSize: "14px",
        fontWeight: "bold",
        input: "date",
        timestamp: "2024-09-12 12:34:56",
        date: null,
    },
    {
        name: "EmployerName",
        x: 13,
        y: 79.5,
        xOffset: 0,
        yOffset: 0,
        label: "Enter employers name...",
        color: "Black",
        // fontSize: "14px",
        fontWeight: "bold",
        input: "text",
        timestamp: "2024-09-12 12:34:56",
    },
    {
        name: "DateInput4",
        x: 77,
        y: 79.5,
        xOffset: 0,
        yOffset: 0,
        color: "Black",
        // fontSize: "14px",
        fontWeight: "bold",
        input: "date",
        timestamp: "2024-09-12 12:34:56",
        date: null,
    },
];
