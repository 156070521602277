import { useNavigate } from 'react-router-dom';
import { persistor } from '../State/store';
import * as CH from 'CustomHooks';
import useFetchApi from '../Services/FetchApi';
import { useState } from 'react';
import { hideRefreshLogin } from '../State/Reducers/refreshLoginSlice';
import { useDispatch } from "react-redux";

interface UseLogoutResponse {
  logout: () => Promise<void>;
  msg: string;
  msgCode: number;
}

export const useLogout = (): UseLogoutResponse => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fetchApi = useFetchApi();
  const { clearUserData } = CH.useUserData();
  const { clearStudentHistoryData } = CH.useStudentHistoryData();
  const { resetForm } = CH.useForm();
  const workflow = CH.useWorkflow();
  const [msg, setMsg] = useState('');
  const [msgCode, setMsgCode] = useState(0);

  const logout = async () => {
    try {
      const response = await fetchApi('user/logout');

      if (response.status === 200) {
        // Perform logout tasks
        clearUserData();
        clearStudentHistoryData();
        resetForm();
        workflow.clearWorkflow();
        dispatch(hideRefreshLogin());
        // Ensure the session storage is cleared
        await persistor.purge();
        navigate('/login');

        setMsg('You have been logged out');
        setMsgCode(4);
      } else {
        // Handle error response
        setMsg(response.msg || 'An error occurred.');
        setMsgCode(1);
      }
    } catch (error) {
      // Handle any errors that occur during the request
      console.error('Error during logout:', error);
      setMsg('An error occurred during logout. Please try again.');
      setMsgCode(1);
    }
  };

  return { logout, msg, msgCode };
};
