import React from 'react';

interface CloudProps {}

const Cloud: React.FC<CloudProps> = () => {
  return (
    <div className='-container'>
     Cloud component
    </div>
  );
};

export default Cloud;