import logError from "../Components/Logger";  //  error handler
const UpdateStudentData = async (database, table, updateColumn, updateValue, studentId, whereColumn, whereValue) => {

    const endpoint = 'https://api.trainingprofessionals.com.au/student/updateStudentData';
    const userId = sessionStorage.getItem('user_id');
    const permissionLevel = sessionStorage.getItem('permission');
    const ipAddress = sessionStorage.getItem('ipAddress'); // Make sure this is stored during login
    const origin = 'Student Portal'
    const requestBody = JSON.stringify({

        userId,
        permissionLevel,
        origin,
        ipAddress,
        database,
        table,
        updateColumn,
        updateValue,
        studentId,
        whereColumn,
        whereValue

    });

    try {
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include', // Ensure credentials are handled correctly server-side
            body: requestBody
        });

        if (response.ok) {
            console.log('Data updated successfully');
            return await response.json();
        } else {
            throw new Error(`Failed to log error, server responded with status: ${response.status}`);
        }

    } catch (err) {

        await logError({
            page: 'Component: UpdateStudentData()',
            dataSubmitted: requestBody,
            errorData: err.data,
            errorCode: err.errorCode,
            errorStack: err.stack,
            errorMsg: err.message,
            errorDesc: 'Failed to update student data using UpdateStudentData().', // Detailed description of the error
            endpoint: endpoint,
        })
    }
};

export default UpdateStudentData;

// =============================== To Do =================================== //



// =============================== Bugs =================================== //