import React, { useState, useRef } from 'react';
import '../Styles/Information.css';

interface InformationProps {
  acknowledgementRequired?: boolean;
  content?: React.ReactNode;
  title?: string;
  /* eslint-disable-next-line no-unused-vars */
  onAcknowledgeChange?: (isAcknowledged: boolean) => void;
}

const Information: React.FC<InformationProps> = ({
  acknowledgementRequired = true,
  content = <div>Did you forget to add content?</div>,
  title = '',
  onAcknowledgeChange = () => {}  // Default to no-op if not provided
}) => {
  const [isAcknowledged, setIsAcknowledged] = useState(false);
  const infoContainerRef = useRef<HTMLDivElement | null>(null);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setIsAcknowledged(checked);
    onAcknowledgeChange(checked);

    if (!checked && infoContainerRef.current) {
      infoContainerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center', 
      });
    }
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <div style={{ ...overlayStyle, opacity: isAcknowledged ? 0 : 1, pointerEvents: isAcknowledged ? 'none' : 'auto' }}></div>
      <div className='info-container' ref={infoContainerRef}>
        <h3 className='info-title'>{title}</h3>
        <div className={`info-content ${acknowledgementRequired ? 'consent' : ''}`}>
          {content}
          <label className='custom-checkbox'>
            <input
              type='checkbox'
              onChange={handleCheckboxChange}
              checked={isAcknowledged}
            />
            <span></span>
          </label>
          <span 
            style={{...messageStyle, visibility: isAcknowledged ? 'hidden' : 'visible'}}>
              To proceed, please confirm that you've reviewed the information above.
          </span>
        </div>
      </div>
    </div>
  );
};

export default Information;

////////////////////////////////////////////////////////////////////////////////////////////
// Inline Styling for Overlay
const overlayStyle: React.CSSProperties = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 1,
  transition: 'opacity 0.5s ease-in-out',
};

const messageStyle: React.CSSProperties = {
  color: '#955CCE', 
  fontWeight: 'bold',
};
