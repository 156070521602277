import { SpacerDiv } from 'Components';
import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { ContactFooter, ContactDetails, Carousel } from 'Components';
import { useUserField } from 'CustomHooks';
import * as Img from 'Images';

interface LandingProps {}


const Landing: React.FC<LandingProps> = () => {
  const navigate = useNavigate();
  const isLoggedIn = useUserField('loggedIn'); 
  
  const handleLoginClick = () => {
    navigate('/login');
  };

  const carouselImages = [
    { src: Img.banner1, onClick: () => console.log('Image 1 clicked') },
    { src: Img.banner2, onClick: () => console.log('Image 2 clicked') },
    { src: Img.banner3, onClick: () => console.log('Image 3 clicked') },
    { src: Img.banner4, onClick: () => console.log('Image 4 clicked') },
    { src: Img.banner5, onClick: () => console.log('Image 5 clicked') },
  ];

  const mobileCarouselImages = [
    { src: Img.banner1Small, onClick: () => console.log('Image 1 clicked') },
    { src: Img.banner2Small, onClick: () => console.log('Image 2 clicked') },
    { src: Img.banner3Small, onClick: () => console.log('Image 3 clicked') },
    { src: Img.banner4Small, onClick: () => console.log('Image 4 clicked') },
    { src: Img.banner5Small, onClick: () => console.log('Image 5 clicked') },
  ];

  return (
    <div style={{ position: 'relative',  minHeight: '93.5vh' }}>
      <div className='desktop-only'>
        <ContactDetails />
      </div>

      <div className='mobile-only'>
        <ContactDetails mobile />
      </div>
      
      <SpacerDiv rem={4}/>

      <Carousel className='desktop-only' images={carouselImages} />
      <Carousel className='mobile-only' images={mobileCarouselImages} />

      <SpacerDiv rem={2}/>

      {!isLoggedIn &&
        <div className='flex-container column'>
          <button 
            className='btn-primary' 
            onClick={handleLoginClick}
            style={{ width: '250px' }}
          >
            SIGN IN
          </button>
          <Link to='/register' className='link glowing-underline'>No account? Register here</Link>
        </div>
      }

      <ContactFooter />
    </div>
  );
};

export default Landing;