import React, { useEffect } from "react";
import { MobileSignature, SpacerDiv } from "Components";
import DatePicker from "react-datepicker";
import { useUserDeets, useSignature } from "CustomHooks";

import "react-datepicker/dist/react-datepicker.css";
import "../Styles/mobileSignature.scss";

interface SignaturePanelProps {
  instructions?: React.ReactNode | string;
  btnText?: string;
  showPanel?: boolean;
  showCanvas?: boolean;
  handleSubmit?: () => void;
  onCancel?: () => void;
}

const SignaturePanel: React.FC<SignaturePanelProps> = ({ instructions, btnText, handleSubmit, showPanel = false, showCanvas = false, onCancel }) => {
  const user = useUserDeets();
  const { name, signature, date, updateName, updateSignature, updateSelectedDate } = useSignature();

  useEffect(() => {
    // Initialize name if not already set
    if (!name && user.fullName) {
      updateName(user.fullName);  
    }
  }, [name, user.fullName, updateName]);

  const handleSignatureSave = (signatureDataUrl: string) => {
    updateSignature(signatureDataUrl);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    updateName(e.target.value);
  };

  const isFormValid = !!name && !!signature && !!date;

  return (
    <div>
      <div className='mobile-overlay' style={{ display: showPanel ? "block" : "none" }}></div>
      
      <div
        className="mobile-signature-container"
        style={{ display: showPanel ? "block" : "none" }}
      >
        <div className="field-container">
          <label className="field-labels">Name:</label>
          <textarea
            className="name-textbox"
            value={name}
            onChange={handleNameChange}
          />
        </div>
        <div className="field-container">
          <label className="field-labels">Signature:</label>
          <MobileSignature showCanvas={showCanvas} onSave={handleSignatureSave} />
        </div>
        <div className="field-container">
          <label className="field-labels">Date:</label>
          <DatePicker
            className="signature-date"
            selected={date ? new Date(date) : null}
            onChange={(date) => updateSelectedDate(date ? date.toISOString() : null)}
            placeholderText="Select Date"
          />
        </div>
        <SpacerDiv rem={1} />
        <div className="flex-container">
          <button
            type='button'
            className="btn-primary signature-submit"
            onClick={onCancel} 
          >
            Cancel
          </button>
          <button
            type='button'
            className="btn-primary signature-submit"
            onClick={handleSubmit}
            // disabled={!isFormValid}
          >
            {btnText || "Submit"}
          </button>
        </div>
        {!isFormValid && (
          <span style={{ color: "#955cce" }}>Please sign to continue</span>
        )}
        {instructions && (
          <div className="">
            <p className="fine-print">{instructions}</p>
            <SpacerDiv rem={1} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SignaturePanel;
