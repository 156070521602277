import { useDispatch } from 'react-redux';
import { updateUserField } from 'State/Reducers/userSlice';
import UserInterface from 'Types/userInterface';

export const useUpdateUserField = () => {
  const dispatch = useDispatch();

  const updateField = <K extends keyof UserInterface>(field: K, value: UserInterface[K]) => {
    dispatch(updateUserField({ field, value }));
  };

  return updateField;
};