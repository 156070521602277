import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FormData {
  [key :string]: any;
}

interface FormState {
  formData: Record<string, FormData>;
  questionStatus: Record<string, boolean>;
}

const initialState: FormState = {
  formData: {},
  questionStatus: {},
};

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    // Update form data and update questionStatus for compulsory questions
    updateFormData: (
      state,
      action: PayloadAction<{ moduleId: string; data: Record<string, any>; compulsory?: boolean }>
    ) => {
      const { moduleId, data, compulsory } = action.payload;
    
      // Update formData
      state.formData[moduleId] = {
        ...state.formData[moduleId],
        ...data,
      };
    
      // Update questionStatus for compulsory questions
      Object.entries(data).forEach(([questionId, questionData]) => {
        const { qType, response } = questionData;
    
        // Handle date qTypes (301, 302, 303) with default value
        const isDateQuestion = [301, 302, 303].includes(qType);
        const defaultDate = new Date().toISOString().split('T')[0];
    
        if (isDateQuestion && response === defaultDate) {
          // Treat the default date as valid for compulsory questions
          state.questionStatus[questionId] = true;
        } else if (compulsory && response !== '' && response !== null && response !== undefined) {
          // Mark other non-empty responses as valid for compulsory questions
          state.questionStatus[questionId] = true;
        } else {
          // Mark as unanswered if empty or invalid
          state.questionStatus[questionId] = false;
        }
      });
    },
    

    // Reset both formData and questionStatus
    resetFormData: (state) => {
      state.formData = {};
      state.questionStatus = {};
    },
  },
});

export const { updateFormData, resetFormData } = formSlice.actions;
export default formSlice.reducer;