import React from 'react';
import backgroundImage from "../Images/TPLogoHighRes.png";

const Bluey = ({ className }) => {

    return (


        <div
            className={className}
            style={{
                position: 'absolute',
                top: 100,
                right: 0,
                width: '100%',
                height: '100%',
                backgroundImage: `url(${backgroundImage})`,
                opacity: 0.3,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right center',
                backgroundSize: 'auto 100%',
                // padding: 50,
                zIndex: 0
            }}
            ></div>
        // </div>
    );
};

export default Bluey;

// =============================== To Do =================================== //



// =============================== Bugs =================================== //
