import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useLogout, useUserData } from 'CustomHooks';
import { useNavigate } from 'react-router-dom';
import SpacerDiv from '../SpacerDiv';
import { COUNTDOWN } from 'Constants/constants';

import '../Styles/InactivityCheck.scss';

interface InactivityCheckProps {}

const InactivityCheck: React.FC<InactivityCheckProps> = () => {
  const isActive = useSelector((state: { activityMonitor: { isUserActive: boolean } }) => state.activityMonitor.isUserActive);
  const [show, setShow] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<number>(COUNTDOWN);
  const { clearUserData } = useUserData();
  const navigate = useNavigate();
  const { logout } = useLogout();

  // Handle user logout when countdown reaches zero
  const handleLogout = React.useCallback(() => {
    console.log("Logging out user due to inactivity...");
    setShow(false);
    logout();
    navigate("/");
  }, [logout, navigate]);

  // Countdown timer logic
  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (show && countdown > 0) {
      timer = setTimeout(() => setCountdown(prevCount => prevCount - 1), 1000);
    } else if (countdown === 0 && show) {
      handleLogout();
    }

    return () => clearTimeout(timer);
  }, [countdown, handleLogout, show]);

  useEffect(() => {
    if (!isActive && !show) {
      setShow(true);
    } else if (isActive && show) {
      setShow(false);
    }
  }, [isActive, show]);

  useEffect(() => {
    if (show) {
      setCountdown(COUNTDOWN);
    }
  }, [show]);

  return (
      <Modal 
        show={show} 
        onHide={() => setShow(false)} 
        centered backdrop="static"
        className='inactivity-modal'
      >
        <Modal.Header className='inactivity-modal-header'>
          <Modal.Title>Are you still there?</Modal.Title>
        </Modal.Header>
        <Modal.Body className='inactivity-modal-body'>
          <SpacerDiv />
          <p>You will be signed out in <span>{countdown}</span> seconds.</p>
          <p className='desktop-only'>Interact with the page to stay signed in.</p>
          <p className='mobile-only'>Click the button below or interact with the page to stay signed in.</p>
          <SpacerDiv />
          <button className='btn-primary mobile-only' onClick={() => setShow(false)}>
            Stay Signed In
          </button>
        </Modal.Body>
      </Modal>
  );
};

export default InactivityCheck;
